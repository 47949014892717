import { Component, createRef } from 'react'
import axios from 'axios'
import _ from 'lodash'

import Loading from '../shared/Loading'
import BackgroundPhotos from './BackgroundPhotos'
import CreateNewDistrict from './CreateNewDistrict'
import DeviceSelector from './DeviceSelector'
import Instructions from './Instructions'
import MapNavigator from './MapNavigator'
import Paging from './Paging'
import Playset from './Playset'
import Pricetag from './Pricetag'
import Sign from './Sign'
import TitleTranslator from './TitleTranslator'
import {
    DraggablePhotos,
    ManagePlaysets,
    Modal,
    SavePanel,
    Section,
    SpeechBubble,
} from '../shared'

import tracking from '../utils/changetracker'
import { arrayMove, getPackDisplayName, handleError } from '../utils/utils'
import withRouter from '../utils/withRouter'

import { mapDimensions } from '../settings'

import { PackType } from '../types/enums/packtype.enum'

import devices from './devices'
import { getEmptyMapDistrict, getEmptyMapPlayset } from './emptyMapParts'
import validate from './validate'

class Map extends Component {
    constructor(props) {
        super(props)
        this.myInput = createRef()
    }

    state = {
        data: null,
        device: 'default',
        packs: [],
        activeType: null, // playset or pricetag
        playsetIndex: 0,
        districtIndex: 0,
        districtWidth: 0, // calculated map width
        districtHeight: 0, // calculated map height
        districtOnSideWidth: 0, // how much screen space active district uses
        totalWidth: 0,
        totalHeight: 0,
        scale: 1,
        initialMapName: null, // we need this to delete the proper key if name is changed
        isLoading: true,
        playsetOpacity: 1,
        saveStatus: 'nochanges',
        hasChanged: false,
        playsetSearchString: '',
        view: '',
        page: 0,
        isNew: false,
        isGoogleSheetLoading: false,
        hasGoogleSheetLoadedSuccessfully: false,
        googleSheetErrors: [],
    }

    componentWillUnmount = () => {
        tracking.stop()
        window.removeEventListener('resize', this.updateDimensions)
    }

    async componentDidMount() {
        window.addEventListener('resize', this.updateDimensions)
        this.updateDimensions()

        let id = this.props.params.id
        try {
            let mapResponse = await axios.get('/api/maps')
            let data = _.find(mapResponse.data, { mapName: id })
            const isCreatingNew = data ? false : true
            if (data) {
                this.setState({ data, initialMapName: data.mapName })
            } else {
                this.setState({ isNew: true })
            }

            let packsResponse = await axios.get('/api/packs')
            let packs = packsResponse.data.packs
            let playsets = packs.filter(
                (p) =>
                    p.type === PackType.PLAYSET ||
                    p.type === PackType.HOME_DESIGNER ||
                    p.type === PackType.OUTFIT_MAKER ||
                    p.type === PackType.CHARACTER_CREATOR
            )
            let districts = _.filter(packs, { type: PackType.PACK })
            this.setState({ packs, playsets, districts })

            isCreatingNew && this.createNew(id)
        } catch (e) {
            handleError('Error loading packs', e)
        }

        this.setState({ isLoading: false }, () => {
            tracking.start(this)
        })
    }

    createNew = (id) => {
        let data = {}
        data.mapName = id
        data.active = false
        data.items = []
        this.setState({ data, view: 'addDistrict' })
    }

    addDistrict = async (packId, stringId) => {
        let data = _.cloneDeep(this.state.data)
        let pack = _.find(this.state.packs, { packId })
        let childPacks = pack ? pack.childPacks : []
        let locations = []
        for (let c of childPacks) {
            let p = getEmptyMapPlayset(c)
            locations.push(p)
        }
        let displayName = {}
        if (stringId) {
            try {
                this.setState({
                    isGoogleSheetLoading: true,
                    hasGoogleSheetLoadedSuccessfully: false,
                    googleSheetErrors: [],
                })
                displayName = await this.loadGoogleSheetData(stringId)
            } catch (error) {
                this.setState({
                    isGoogleSheetLoading: false,
                    googleSheetErrors: [
                        `Item ${data.items.length + 1} - ${error.message}`,
                    ],
                })
            }
        }

        let newDistrict = getEmptyMapDistrict({
            packId,
            locations,
            displayName,
            stringId,
        })
        data.items.push(newDistrict)
        localStorage.setItem('language', 'en')
        this.setState({
            data,
            view: 'uploadBackground',
            districtIndex: data.items.length - 1,
        })
    }

    updateDimensions = () => {
        let device = devices[this.state.device]
        let totalWidth = device.resolution.w || window.innerWidth
        let totalHeight =
            device.resolution.h ||
            totalWidth / (mapDimensions.w / mapDimensions.h)

        let districtHeight
        if (device.resolution.h)
            districtHeight = device.resolution.h * (device.mapHeight || 1)
        else districtHeight = totalWidth * (mapDimensions.h / mapDimensions.w)

        let districtWidth = districtHeight * (mapDimensions.w / mapDimensions.h)

        let districtOnSideWidth = (1 - districtWidth / totalWidth) / 2

        let drawScale = window.innerWidth / totalWidth
        drawScale *= device.drawScale

        let screenTransform =
            'translateX(-50%) scale(' + drawScale + ', ' + drawScale + ')'
        let screenLeft = '50%'

        let scale = districtWidth / mapDimensions.w
        this.setState({
            districtWidth,
            districtHeight,
            scale,
            totalWidth,
            totalHeight,
            districtOnSideWidth,
            screenTransform,
            screenLeft,
            drawScale,
        })
    }

    updatePlayset = (playset, playsetIndex, type) => {
        let state = this.state
        let data = _.cloneDeep(state.data)
        data.items[state.districtIndex].locations[playsetIndex] = playset
        this.setState({ data, playsetIndex, activeType: type || 'playset' })
    }

    setActivePlaysetIndex = (e, playsetIndex, activeType) => {
        e.stopPropagation()
        this.setState({ playsetIndex, activeType })
    }

    setActivePlaysetId = (e, packId, activeType) => {
        e.stopPropagation()
        let state = this.state
        let districtIndex = state.districtIndex
        let districts = this.state.data.items
        let district = districts[districtIndex]
        let locations = district.locations
        let playsetIndex = _.findIndex(locations, { packId })
        this.setState({ playsetIndex, activeType })
    }

    save = async () => {
        tracking.stop()
        this.setState({ saveStatus: 'saving' })
        let data = this.state.data
        try {
            if (this.state.isNew) {
                console.log('Saving a new map...')
                await axios.post('/api/maps', { data })
            } else {
                console.log('Updating map...')
                await axios.put('/api/maps', { data })
            }

            let initialMapName = this.state.initialMapName
            if (initialMapName && data.mapName !== initialMapName) {
                // delete old map if we changed key (map name)
                await axios.delete('/api/maps/' + initialMapName)
                console.log('deleted map with id', initialMapName)
            }
            tracking.restart(this)
            console.log('Saved!')
            if (this.state.isNew) {
                this.setState({ isNew: false })
            }
        } catch (e) {
            handleError('Error saving map', e)
            this.setState({ saveStatus: 'hasChanged' })
        }
    }

    rotate = (i) => {
        let state = this.state
        let playsetIndex = state.playsetIndex
        if (Number.isInteger(i)) playsetIndex = i

        if (!Number.isInteger(playsetIndex)) return

        let data = _.cloneDeep(state.data)
        let district = data.items[state.districtIndex]
        let item = district.locations[playsetIndex]
        if (!item) return

        let r = item.pricetag.rotation + 90
        if (r >= 360) r -= 360
        item.pricetag.rotation = r
        this.setState({ data, playsetIndex, activeType: 'pricetag' })
    }

    onEditPlaysetOrder = (e, dir) => {
        e && e.stopPropagation()
        let { playsetIndex, districtIndex } = this.state
        let data = _.cloneDeep(this.state.data)
        let district = data.items[districtIndex]
        let locations = district.locations
        let to = playsetIndex + dir
        if (to < 0) to = 0
        if (to > locations.length - 1) to = locations.length - 1
        arrayMove(locations, playsetIndex, to) // utility in utils.js
        this.setState({ data, playsetIndex: to })
    }

    onKeyDown = (e) => {
        let k = e.keyCode
        let state = this.state
        let { playsetIndex, activeType, districtWidth, scale } = state

        e.preventDefault()

        if (e.key === 'r') return this.rotate()

        if (e.key === ' ' || e.key === 't') {
            // toggle playset/pricetag as active
            activeType = activeType === 'playset' ? 'pricetag' : 'playset'
            return this.setState({ activeType })
        }

        if (e.keyCode >= 48 && e.keyCode <= 57) {
            // tweak playset opacity
            let o = (e.keyCode - 48) / 10
            if (e.keyCode === 48) o = 1
            this.setState({ playsetOpacity: o })
            return
        }

        if (e.altKey && k === 38)
            // send up
            return this.onEditPlaysetOrder(null, 1)
        if (e.altKey && k === 40)
            // send down
            return this.onEditPlaysetOrder(null, -1)

        if (e.altKey && k === 39) return this.changeDistrict(null, true)
        if (e.altKey && k === 37) return this.changeDistrict(null, false)

        let modifier = e.shiftKey ? 10 : 1
        let data = _.cloneDeep(state.data)
        if (!Number.isInteger(playsetIndex)) return

        let district = data.items[state.districtIndex]
        let item = district.locations[playsetIndex]

        if (!item) return

        item = activeType === 'playset' ? item : item.pricetag
        let v = (1 * modifier) / (districtWidth / scale)
        if (k === 37) item.x -= v
        if (k === 38) item.y -= v
        if (k === 39) item.x += v
        if (k === 40) item.y += v
        this.setState({ data })
    }

    deleteMap = async (i) => {
        let isConfirmed = await Modal.confirm({
            heading: 'Delete district',
            text: 'Really delete this district? All uploaded playset assets will be lost.',
            okLabel: 'Yes do it!',
        })
        if (!isConfirmed) return
        let data = _.cloneDeep(this.state.data)
        data.items.splice(i, 1)
        let view = ''
        if (data.items.length === 0) view = 'addDistrict'
        this.setState({ data, districtIndex: 0, view })
    }

    changeDistrict = (districtIndex, forward) => {
        if (!districtIndex) {
            const items = this.state.data.items
            let di = this.state.districtIndex
            if (forward) districtIndex = items[di + 1] ? di + 1 : 0
            else districtIndex = items[di - 1] ? di - 1 : items.length - 1
        }

        this.setState({ districtIndex })
    }

    onBackgroundSelected = (type, filesList) => {
        let state = this.state
        let url = filesList[0].url
        let data = _.cloneDeep(state.data)
        const { background } = data.items[state.districtIndex]
        background.url = url
        background.uploading = true
        this.setState({ data })
    }

    onBackgroundUploaded = (type, file, res) => {
        let state = this.state
        let mediaId = res.data.uploaded.id
        let data = _.cloneDeep(state.data)
        const { background } = data.items[state.districtIndex]
        background.mediaId = mediaId
        background.uploading = false
        this.setState({ data })
    }

    onPlaysetImageSelected = (type, filesList, optionalData) => {
        let packId = optionalData.packId
        let state = this.state
        let url = filesList[0].url
        let data = _.cloneDeep(state.data)
        let district = data.items[state.districtIndex]
        let playsets = district.locations
        let p = _.find(playsets, { packId })
        if (!p) {
            p = getEmptyMapPlayset(packId)
            district.locations.push(p)
        }
        if (!optionalData.isOwned) {
            p.img.url = url
        } else {
            if (!p.imgOwned) p.imgOwned = {}
            p.imgOwned.url = url
        }

        p.uploading = true
        this.setState({ data })
    }

    onPlaysetImageUploaded = (type, file, res, optionalData) => {
        let packId = optionalData.packId
        let state = this.state
        let mediaId = res.data.uploaded.id
        let data = _.cloneDeep(state.data)
        let district = data.items[state.districtIndex]
        let playsets = district.locations
        let p = _.find(playsets, { packId })
        if (!optionalData.isOwned) {
            p.img.mediaId = mediaId
        } else {
            p.imgOwned.mediaId = mediaId
        }
        p.uploading = false
        this.setState({ data })
    }

    onChangeMapName = (e) => {
        let data = _.cloneDeep(this.state.data)
        data.mapName = e.target.value
        this.setState({ data })
    }

    togglePlayset = (pack) => {
        const packId = pack.packId
        let data = _.cloneDeep(this.state.data)
        let district = data.items[this.state.districtIndex]

        let newLocation = getEmptyMapPlayset(packId)
        let locations = district.locations

        if (_.remove(locations, { packId }).length === 0)
            // lodash returns deleted item
            locations.push(newLocation)

        let playsetIndex = district.locations.length - 1
        this.setState({ data, playsetIndex, activeType: 'playset' })
    }

    activate = () => {
        let data = _.cloneDeep(this.state.data)
        data.active = true
        this.setState({ data })
    }

    onNameChange = (e) => {
        let data = _.cloneDeep(this.state.data)
        let district = data.items[this.state.districtIndex]
        if (!district.displayName) return
        district.displayName[this.props.language] = e.target.value
        this.setState({ data })
    }

    onStringIdChange = (e) => {
        const data = _.cloneDeep(this.state.data)
        data.items[this.state.districtIndex].stringId = e.target.value
        this.setState({ data })
    }

    onDragPhotoEnd = (result) => {
        if (!result.destination) return

        let data = _.cloneDeep(this.state.data)
        let items = data.items
        // reorder results
        let [removed] = items.splice(result.source.index, 1)
        items.splice(result.destination.index, 0, removed)
        this.setState({ data })
    }

    disconnectDistrict = async () => {
        let data = _.cloneDeep(this.state.data)
        const { districtIndex } = this.state
        data.items[districtIndex].packId = null
        this.setState({ data })
    }

    connectToDistrict = async (e) => {
        e.preventDefault()

        const { districts, districtIndex } = this.state
        let items = []
        for (let d of districts) {
            let item = {
                label: getPackDisplayName(d),
                value: d.packId,
                disabled: _.find(this.state.data.items, { packId: d.packId }),
            }
            items.push(item)
        }

        let packId = await Modal.select({
            items,
            heading: 'Available districts:',
            okLabel: 'Go go',
            hint: 'You can only select unused districts.',
        })
        if (!packId) return
        if (typeof packId !== 'string') return

        let district = _.find(this.state.packs, { packId })
        if (!district) return alert('Pack does not exist')
        if (district.type !== PackType.PACK)
            return alert('Pack is not a district')

        let data = _.cloneDeep(this.state.data)
        data.items[districtIndex].packId = packId
        data.items[districtIndex].displayName = {}
        this.setState({ data })
    }

    cancelNewDistrict = () => {
        let data = _.cloneDeep(this.state.data)
        data.items.pop()
        this.setState({ data, view: '', districtIndex: 0 })
    }

    onChangeDevice = (device) => {
        this.setState({ device }, this.updateDimensions)
    }

    loadGoogleSheetData = async (stringId) => {
        try {
            const {
                data: { name },
            } = await axios.get(`/api/v2/getpackfromsource/${stringId}`)
            if (_.isEmpty(name)) {
                throw new Error('There are no translations for this String ID!')
            }

            return name
        } catch (err) {
            throw new Error(
                `That didn't work. Sorry! Server says: ${err.response?.data.message}`
            )
        }
    }

    loadTranslations = async () => {
        this.setState({
            isGoogleSheetLoading: true,
            hasGoogleSheetLoadedSuccessfully: false,
            googleSheetErrors: [],
        })

        const stringIds = this.state.data.items.map((item) => item.stringId)
        const loadGoogleSheetDataRes = await Promise.allSettled(
            stringIds.map(this.loadGoogleSheetData)
        )

        const data = _.cloneDeep(this.state.data)
        data.items = data.items.map((item, i) => ({
            ...item,
            displayName: loadGoogleSheetDataRes[i].value ?? item.displayName,
        }))
        this.setState({ data, isGoogleSheetLoading: false })

        const errors = loadGoogleSheetDataRes
            .map((res, i) => res.reason && `Item ${i + 1} - ${res.reason}`)
            .filter(Boolean)
        if (errors.length) {
            return this.setState({ googleSheetErrors: errors })
        }

        this.setState({ hasGoogleSheetLoadedSuccessfully: true }, () =>
            setTimeout(
                () =>
                    this.setState({ hasGoogleSheetLoadedSuccessfully: false }),
                5000
            )
        )
    }

    render() {
        const state = this.state

        if (state.isLoading) return <Loading />

        const {
            data,
            packs,
            saveStatus,
            activeType,
            playsetIndex,
            isGoogleSheetLoading,
            hasGoogleSheetLoadedSuccessfully,
            googleSheetErrors,
        } = state
        const items = data.items
        const districtIndex = state.districtIndex
        const district = items[districtIndex]
        const locations = (district && district.locations) || []

        const {
            districtWidth,
            districtHeight,
            districtOnSideWidth,
            totalWidth,
            totalHeight,
            scale,
            playsetOpacity,
            playsets,
            view,
            screenTransform,
            screenLeft,
            drawScale,
        } = this.state
        const device = devices[this.state.device]
        const deviceName = this.state.device
        const previousDistrictIndex = items[districtIndex - 1]
            ? districtIndex - 1
            : items.length - 1
        const nextDistrictIndex = items[districtIndex + 1]
            ? districtIndex + 1
            : 0
        const previousDistrict = items[previousDistrictIndex]
        const nextDistrict = items[nextDistrictIndex]

        let currentBackgroundUrl = ''
        if (district) {
            currentBackgroundUrl = district.background.url
        }

        let packData = district && _.find(packs, { packId: district.packId }) // current pack data

        const pageCount = Math.max(...locations.map((l) => l.page)) + 1

        const validationResults = validate(data)

        if (view === 'addDistrict')
            return (
                <CreateNewDistrict
                    packs={packs}
                    existing={items}
                    onCancel={() => this.setState({ view: '' })}
                    onClick={this.addDistrict}
                    isLoading={isGoogleSheetLoading}
                />
            )

        if (view === 'managePlaysets')
            return (
                <Section>
                    <ManagePlaysets
                        hint={true}
                        title="Manage playsets, home designer, and character creator packs"
                        packData={packData}
                        items={district.locations}
                        playsets={playsets}
                        style={{ width: '90%', margin: 'auto' }}
                        onCancel={() => this.setState({ view: '' })}
                        togglePlayset={this.togglePlayset}
                    />
                </Section>
            )
        if (view === 'uploadBackground')
            return (
                <BackgroundPhotos
                    isCreating={true}
                    onCancel={items.length > 1 ? this.cancelNewDistrict : null}
                    district={district}
                    packData={packData}
                    onBackgroundSelected={this.onBackgroundSelected}
                    onDone={() => this.setState({ view: '' })}
                    onBackgroundUploaded={this.onBackgroundUploaded}
                />
            )

        return (
            <div id="map">
                <Section>
                    <MapNavigator
                        items={items}
                        i={districtIndex}
                        name={getPackDisplayName(packData || district)}
                        onNext={() => this.changeDistrict(nextDistrictIndex)}
                        onPrevious={() =>
                            this.changeDistrict(previousDistrictIndex)
                        }
                    />
                    <button
                        className="medium positive"
                        onClick={() => this.setState({ view: 'addDistrict' })}
                        style={{ position: 'absolute', top: 20, right: 20 }}
                    >
                        New map district
                    </button>
                    <TitleTranslator
                        district={district}
                        parent={this}
                        {...this.props}
                        packData={packData}
                        connectToDistrict={this.connectToDistrict}
                        disconnectDistrict={this.disconnectDistrict}
                        onNameChange={this.onNameChange}
                        onStringIdChange={this.onStringIdChange}
                        onLoadTranslations={this.loadTranslations}
                        isGoogleSheetLoading={isGoogleSheetLoading}
                        hasGoogleSheetLoadedSuccessfully={
                            hasGoogleSheetLoadedSuccessfully
                        }
                        googleSheetErrors={googleSheetErrors}
                    />
                    <div
                        id="screen"
                        ref={this.myInput}
                        style={{
                            ...{
                                width: totalWidth,
                                height: totalHeight,
                                transform: screenTransform,
                                transformOrigin: 'top',
                                left: screenLeft,
                            },
                            ...device.style,
                        }}
                    >
                        <div
                            style={{
                                marginLeft:
                                    -districtWidth +
                                    totalWidth * districtOnSideWidth,
                                marginTop: -device.cropTop,
                                paddingBottom: device.cropTop,
                            }}
                        >
                            {/* Previous district */}
                            <img
                                alt="prev"
                                className="district inactive"
                                onClick={() =>
                                    this.changeDistrict(previousDistrictIndex)
                                }
                                src={previousDistrict.background.url.replace(
                                    '{0}',
                                    '@3x'
                                )}
                                style={{
                                    width: districtWidth,
                                    height: districtHeight,
                                }}
                            />

                            {/* District we’re editing */}
                            <div
                                tabIndex="0"
                                onKeyDown={this.onKeyDown}
                                className="district"
                                style={{
                                    width: districtWidth,
                                    height: districtHeight,
                                    backgroundImage:
                                        'url(' +
                                        currentBackgroundUrl.replace(
                                            '{0}',
                                            '@3x'
                                        ) +
                                        ')',
                                }}
                                onClick={() =>
                                    this.setState({ playsetIndex: -1 })
                                }
                            >
                                <Paging
                                    pageCount={pageCount}
                                    page={this.state.page}
                                    setPage={(p) => {
                                        let page = Math.max(0, p)
                                        page = Math.min(pageCount - 1, page)
                                        this.setState({ page })
                                    }}
                                    items={locations}
                                />
                                {/* tabIndex="0" enables key events for a div */}
                                {
                                    // Playsets
                                    locations.map((item, i) => {
                                        if (this.state.page !== item.page)
                                            return <></>
                                        return (
                                            <Playset
                                                setMapPage={(p) =>
                                                    this.setState({ page: p })
                                                }
                                                data={item}
                                                i={i}
                                                key={'playset' + i}
                                                isActive={
                                                    playsetIndex === i &&
                                                    activeType === 'playset'
                                                }
                                                setActive={
                                                    this.setActivePlaysetIndex
                                                }
                                                updatePlayset={
                                                    this.updatePlayset
                                                }
                                                width={districtWidth}
                                                playsets={playsets}
                                                playsetData={_.find(playsets, {
                                                    packId: item.packId,
                                                })}
                                                height={districtHeight}
                                                scale={scale}
                                                drawScale={drawScale}
                                                opacity={playsetOpacity}
                                                rotate={this.rotate}
                                                onPlaysetImageSelected={
                                                    this.onPlaysetImageSelected
                                                }
                                                onPlaysetImageUploaded={
                                                    this.onPlaysetImageUploaded
                                                }
                                                onEditPlaysetOrder={
                                                    this.onEditPlaysetOrder
                                                }
                                            />
                                        )
                                    })
                                }
                                {
                                    // Pricetags
                                    locations.map((item, i) => {
                                        if (this.state.page !== item.page)
                                            return <></>
                                        return (
                                            <Pricetag
                                                data={item}
                                                i={i}
                                                key={'pricetag' + i}
                                                isActive={
                                                    playsetIndex === i &&
                                                    activeType === 'pricetag'
                                                }
                                                setActive={
                                                    this.setActivePlaysetIndex
                                                }
                                                updatePlayset={
                                                    this.updatePlayset
                                                }
                                                width={districtWidth}
                                                height={districtHeight}
                                                drawScale={drawScale}
                                            />
                                        )
                                    })
                                }
                                <Sign
                                    height={districtHeight}
                                    name={getPackDisplayName(
                                        packData || district
                                    )}
                                    img={currentBackgroundUrl}
                                />
                            </div>

                            {/* Next district */}
                            <img
                                alt="next"
                                className="district inactive"
                                onClick={() =>
                                    this.changeDistrict(nextDistrictIndex)
                                }
                                src={nextDistrict.background.url.replace(
                                    '{0}',
                                    '@3x'
                                )}
                                style={{
                                    width: districtWidth,
                                    height: districtHeight,
                                }}
                            />
                        </div>
                    </div>
                    <div
                        style={{ marginTop: totalHeight * drawScale + 95 }}
                    ></div>{' '}
                    {/* whitespace hack because center positioning a scaled element (device) is a mess with css */}
                    <DeviceSelector
                        device={deviceName}
                        onChange={this.onChangeDevice}
                    />
                </Section>

                {
                    <div>
                        <BackgroundPhotos
                            isCreating={false}
                            district={district}
                            onBackgroundSelected={this.onBackgroundSelected}
                            onBackgroundUploaded={this.onBackgroundUploaded}
                        />
                        <Section title="Rearrange districts">
                            <DraggablePhotos
                                type="screenshot"
                                onDelete={this.deleteMap}
                                items={items}
                                object="background"
                                onDragEnd={this.onDragPhotoEnd}
                            />
                            <SpeechBubble
                                width="500"
                                style={{ marginTop: 30, marginBottom: 30 }}
                            >
                                Drag and drop backgrounds to rearrange :)
                            </SpeechBubble>
                        </Section>
                        <Instructions />
                        <SavePanel
                            title={
                                data.mapName +
                                ': ' +
                                getPackDisplayName(packData || district)
                            }
                            validationResults={validationResults}
                            item={data}
                            save={this.save}
                            showDelete={false}
                            saveStatus={saveStatus}
                            onDelete={this.deletePack}
                        />
                    </div>
                }
            </div>
        )
    }
}

export default withRouter(Map)
