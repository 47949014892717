export function getFileNameFromUrl(url: string) {
    let pathname: string
    try {
        pathname = decodeURIComponent(new URL(url).pathname)
    } catch (err) {
        return ''
    }
    const lastSlug = pathname.split('/').at(-1) || ''
    const sizePlaceholderIdx = lastSlug.lastIndexOf('{0}')
    if (sizePlaceholderIdx !== -1) {
        return lastSlug.substring(0, sizePlaceholderIdx)
    }
    const extensionIdx = lastSlug.lastIndexOf('.')
    if (extensionIdx !== -1) {
        return lastSlug.substring(0, extensionIdx)
    }
    return lastSlug
}
