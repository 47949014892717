import { useState } from 'react'
import classNames from 'classnames'
import _ from 'lodash'

import { compareDates, formatDate } from '../utils/dates'

import type { GiftChange } from './giftTypes'

import Collapse from '../assets/collapse.svg'
import Expand from '../assets/expand.svg'

const STATUSES = {
    CREATED: 'New',
    UPDATED: 'Updated',
    DELETED: 'Deleted',
    INVALID: 'Invalid',
}

const labelClassNameByStatus = {
    [STATUSES.CREATED]: 'new',
    [STATUSES.UPDATED]: 'update',
    [STATUSES.DELETED]: 'delete',
    [STATUSES.INVALID]: 'invalid',
}

const displayProperties = [
    'startDate',
    'endDate',
    'releaseNumber',
] as (keyof GiftChange)[]
const dateStringProperties = ['startDate', 'endDate']

function getDisplayValue(value: unknown, isDateString: boolean) {
    if (
        isDateString &&
        (typeof value === 'string' || typeof value === 'number')
    ) {
        value = formatDate(value)
    }
    return String(value)
}

type BulkTableRowProps = {
    oldGift?: GiftChange
    newGift?: GiftChange
    validationErrors?: string[]
}

function BulkTableRow(props: BulkTableRowProps) {
    const { oldGift, newGift, validationErrors } = props
    const [isOpen, setIsOpen] = useState(false)

    let status = STATUSES.CREATED
    if (newGift && oldGift) {
        status = STATUSES.UPDATED
    }
    if (!newGift) {
        status = STATUSES.DELETED
    }
    if (validationErrors) {
        status = STATUSES.INVALID
    }

    if (status === STATUSES.UPDATED || status === STATUSES.INVALID) {
        if (newGift) {
            return (
                <tr
                    onClick={() => setIsOpen(!isOpen)}
                    style={{ ...(isOpen && { backgroundColor: '#f1f3f3' }) }}
                >
                    <td>{newGift.giftId}</td>
                    {displayProperties.map((property) => {
                        const oldValue = oldGift?.[property]
                        const newValue = newGift[property]
                        const isDateString =
                            dateStringProperties.includes(property)
                        const isValueChanged = isDateString
                            ? compareDates(oldValue, newValue) !== 0
                            : !_.isEqual(oldValue, newValue)
                        const oldDisplayValue = getDisplayValue(
                            oldValue,
                            isDateString
                        )
                        const newDisplayValue = getDisplayValue(
                            newValue,
                            isDateString
                        )
                        return (
                            <td key={property}>
                                <div>
                                    {isValueChanged &&
                                        isOpen &&
                                        (validationErrors?.includes(
                                            property
                                        ) ? (
                                            <div className="status invalid">
                                                Invalid entry
                                            </div>
                                        ) : (
                                            <div className="status new">
                                                New entry
                                            </div>
                                        ))}

                                    <div>{newDisplayValue}</div>
                                </div>
                                <div style={{ marginTop: '8px' }}>
                                    {oldGift && isValueChanged && isOpen && (
                                        <div style={{ color: '#677977' }}>
                                            <div className="status">
                                                Old entry
                                            </div>
                                            <div>{oldDisplayValue}</div>
                                        </div>
                                    )}
                                </div>
                            </td>
                        )
                    })}
                    <td>
                        <span
                            className={classNames(
                                'label',
                                labelClassNameByStatus[status]
                            )}
                        >
                            {status}
                        </span>
                    </td>
                    <td>
                        <div>
                            <img
                                alt={isOpen ? 'Collapse row' : 'Expand row'}
                                src={isOpen ? Collapse : Expand}
                            />
                        </div>
                    </td>
                </tr>
            )
        }
    }
    if (status === STATUSES.CREATED || status === STATUSES.DELETED) {
        const gift = newGift || oldGift
        if (gift) {
            return (
                <tr>
                    <td>{gift.giftId}</td>
                    {displayProperties.map((property) => {
                        const isDateString =
                            dateStringProperties.includes(property)
                        const displayValue = getDisplayValue(
                            gift[property],
                            isDateString
                        )
                        return <td key={property}>{displayValue}</td>
                    })}
                    <td>
                        <span
                            className={classNames(
                                'label',
                                labelClassNameByStatus[status]
                            )}
                        >
                            {status}
                        </span>
                    </td>
                </tr>
            )
        }
    }
    return null
}

export default BulkTableRow
