import { useState } from 'react'
import { Tooltip } from 'react-tooltip'
import _ from 'lodash'

import { Loading, Section, SpeechBubble } from '../shared'

import { getPackDisplayName } from '../utils/utils'

function CreateNewDistrict(props) {
    const [stringId, setStringId] = useState('')

    const { packs, existing, onClick, onCancel, isLoading } = props

    if (isLoading) {
        return <Loading />
    }

    const districts = _.filter(packs, { type: 'pack' })

    return (
        <Section style={{ textAlign: 'center' }}>
            <div
                style={{
                    display: 'block',
                    textAlign: 'right',
                    padding: '0 20px',
                    visibility: existing.length > 0 ? 'visible' : 'hidden',
                }}
            >
                <button className="secondary" onClick={() => onCancel()}>
                    Cancel
                </button>
            </div>

            <h1>Standard district</h1>
            {districts.map((item, i) => {
                const { packId } = item
                let exist = _.find(existing, { packId })
                let name = getPackDisplayName(item)
                return (
                    <div key={'district' + i}>
                        <div
                            style={{
                                display: 'inline-block',
                                width: '40%',
                                textAlign: 'right',
                            }}
                        >
                            <Tooltip id={`new-district-${packId}`} />
                            <label
                                style={{ textAlign: 'right', width: 250 }}
                                data-tooltip-id={`new-district-${packId}`}
                                data-tooltip-content={packId}
                            >
                                {name}
                            </label>
                        </div>
                        <div
                            style={{
                                display: 'inline-block',
                                textAlign: 'left',
                                width: '40%',
                            }}
                        >
                            <button
                                onClick={() => onClick(item.packId)}
                                key={'newd' + i}
                                disabled={exist}
                                className="primary"
                                style={{ margin: 5 }}
                            >
                                {exist ? 'Included!' : 'Choose'}
                            </button>
                        </div>
                    </div>
                )
            })}

            <SpeechBubble
                width="500"
                style={{ marginTop: 30, marginBottom: 30 }}
            >
                Standard districts are packs that contains playsets. They are
                sold as a package in the app and are full featured members of
                the map.
            </SpeechBubble>
            <h1 style={{ marginTop: 40, marginBottom: 0 }}>
                Standalone district
            </h1>
            <div>
                <input
                    type="text"
                    className="editInput semiwide"
                    style={{ margin: 10 }}
                    placeholder="String ID"
                    value={stringId}
                    onChange={(e) => setStringId(e.target.value)}
                />
                <button
                    onClick={() => onClick(null, stringId)}
                    className="positive"
                    style={{ margin: 5 }}
                >
                    Create
                </button>
            </div>
            <SpeechBubble
                width="500"
                style={{ marginTop: 30, marginBottom: 30 }}
            >
                Standalone districts are shown as districts/sections on the map
                but are not sold as packages. They contain playsets that are
                sold invididually.
            </SpeechBubble>
        </Section>
    )
}

export default CreateNewDistrict
