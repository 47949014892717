import { RoutePath, RouteTo } from '../routes.ts'

export const getRedirectToAttr = (): string | null => {
    const urlParams = new URLSearchParams(decodeURIComponent(location.search))
    const redirectTo = urlParams.get('redirectTo')

    return redirectTo
}

export const getRedirectPath = (): string | undefined => {
    const redirectTo = getRedirectToAttr()

    if (redirectTo) {
        return redirectTo
    }

    if (location.pathname === RoutePath.Root) {
        return undefined
    }

    if (!location.pathname.startsWith(RoutePath.Login)) {
        return location.pathname
    }

    return undefined
}

export function redirectToLogin() {
    const redirectPath = getRedirectPath()
    const redirectTo = redirectPath
        ? encodeURIComponent(redirectPath)
        : undefined

    window.location.assign(RouteTo.Login(redirectTo))
}
