import { useEffect, useState } from 'react'
import axios from 'axios'
import classNames from 'classnames'
import _ from 'lodash'

import AddProductsModal from './AddProducts'
import CopyProductsModal from './CopyProducts'
import {
    LabelInput,
    Loading,
    ManagePlaysets,
    Modal,
    NewRow,
    Section,
    SpeechBubble,
} from '../shared'

import { getActiveEnvironment } from '../utils/envaccess'
import { handleError } from '../utils/utils'

import { Profile, Purchase } from '../types/accountTypes'
import { Environment } from '../types/environmentTypes'
import { Pack } from '../types/packTypes'

import styles from './Accounts.module.scss'

import warningImg from '../assets/warning.png'

const Accounts = function () {
    const [deviceId, setDeviceId] = useState('')
    const [profileIds, setProfileIds] = useState<Profile[]>([])
    const [selectedProfileId, setSelectedProfileId] = useState('')
    const [ownedSKUs, setOwnedSKUs] = useState<string[]>([])
    const [selectedPacks, setSelectedPacks] = useState<string[]>([])
    const [hasLoaded, setHasLoaded] = useState(false)
    const [packs, setPacks] = useState<Pack[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const [resetCacheUrl, setResetCacheUrl] = useState('')
    const [packIdsToAdd, setPackIdsToAdd] = useState<string[]>([])

    const fetchPacksAndEnv = async () => {
        setHasLoaded(true)
        try {
            const response = await axios.get<{ packs: Pack[] }>('/api/packs')
            const packs = response.data.packs
            setPacks(packs)
        } catch (err) {
            handleError('Failed to fetch packs', err)
            return
        }

        const activeEnvironment = await getActiveEnvironment()
        setupCurrentEnvLink(activeEnvironment)
    }

    // TODO fix this to useEnvironments hook
    function setupCurrentEnvLink(activeEnvironment: Environment) {
        if (
            activeEnvironment &&
            (activeEnvironment.name === 'staging' ||
                activeEnvironment.name === 'dev')
        ) {
            // replace `https://url.com/api` with `https://url.com/resetcache`
            setResetCacheUrl(
                activeEnvironment.gateway.substring(
                    0,
                    activeEnvironment.gateway.lastIndexOf('/')
                ) + '/resetcache'
            )
        }
    }

    useEffect(() => {
        if (!hasLoaded) fetchPacksAndEnv()
    })

    const reset = () => {
        setSelectedProfileId('')
        setSelectedPacks([])
        setProfileIds([])
        setOwnedSKUs([])
        setPackIdsToAdd([])
    }

    const getProfiles = async () => {
        if (deviceId) {
            setIsLoading(true)
            reset()
            try {
                const res = await axios.get<Profile[]>(
                    `/api/v2/profiles/${deviceId}`
                )
                const profiles = res.data
                console.log('profiles', profiles)
                if (!profiles || profiles.length === 0) {
                    setIsLoading(false)
                    Modal.alert({
                        heading: 'Nothing found',
                        text: 'there are no accounts connected to this profile id.',
                    })
                    return
                }

                setProfileIds(profiles)
                setIsLoading(false)
                if (profiles.length > 1) return
                getPurchases(profiles[0].profileId)
                setSelectedProfileId(profiles[0].profileId)
            } catch (e) {
                console.log(`Error fetching profiles`, e)
                Modal.alert({
                    heading: 'Error getting accounts',
                    text: 'Did you enter a correct profile id?',
                })
                setIsLoading(false)
            }
        }
    }

    const handleOwnedSKUs = (ownedSKUs: string[]) => {
        // set owned SKUs before marking child packs of bundle as owned
        setOwnedSKUs(ownedSKUs)
        let ownedPacks: string[] = []
        for (const pack of packs) {
            for (const product of pack.priceLevels) {
                const productId = product.productId
                if (ownedSKUs.indexOf(productId) > -1) {
                    ownedPacks.push(pack.packId)
                    ownedPacks = [...ownedPacks, ...pack.childPacks]
                }
            }
        }
        // remove duplicate entries
        ownedPacks = [...new Set(ownedPacks)]

        setSelectedPacks(ownedPacks)
    }

    const getPurchases = async (profileId: string) => {
        setIsLoading(true)
        setSelectedProfileId(profileId)
        try {
            const res = await axios.get<{ purchases: Purchase[] }>(
                `/api/v2/purchases/${profileId}`
            )
            const purchases = (res.data && res.data.purchases) || []
            const ownedSKUs = purchases.map((item) => item.productId)
            // Reset owned packs
            setSelectedPacks([])
            setOwnedSKUs([])
            if (ownedSKUs.length > 0) {
                handleOwnedSKUs(ownedSKUs)
            }
            setIsLoading(false)
        } catch (e) {
            console.log(`Error fetching purchases`, e)
            setIsLoading(false)
            Modal.alert({
                heading: 'Error getting purchases',
                text: `Error message ${e}`,
            })
        }
    }

    const togglePack = ({ packId }: { packId: string }) => {
        const selected = _.cloneDeep(packIdsToAdd)
        const ix = selected.indexOf(packId)
        ix > -1 ? selected.splice(ix, 1) : selected.push(packId)
        setPackIdsToAdd(selected)
    }

    const selectAllUncheckedPacks = () => {
        const allNotOwnedPacks = packs
            .filter((pack) => !selectedPacks.includes(pack.packId))
            .map((pack) => pack.packId)
        setPackIdsToAdd(allNotOwnedPacks)
    }

    async function onSuccessfullyAddedPacks() {
        setPackIdsToAdd([])
        getPurchases(selectedProfileId)
    }

    async function deleteAccount(profileId: string) {
        const isConfirmed = await Modal.confirm({
            heading: 'Delete profile and purchases',
            text: `Really delete all info about selected account and all it's purchases from our system? It can't be undone!`,
            okLabel: 'Yes, do it!',
        })

        if (!isConfirmed) return
        setIsLoading(true)
        try {
            await axios.delete(`/api/v2/account/${profileId}`)
            reset()
            setDeviceId('')
            setIsLoading(false)
            Modal.alert({
                heading: 'Account was deleted',
                text: `All info was deleted for profile id "${profileId}"`,
            })
        } catch (error) {
            setIsLoading(false)
            Modal.alert({
                heading: 'Error while deleting account',
                text: `${error}`,
            })
        }
    }

    return (
        <Section title="Account management">
            {resetCacheUrl && (
                <div className="editInput">
                    <a
                        rel="noopener noreferrer"
                        className={styles.colorPositive}
                        target="_blank"
                        href={`${resetCacheUrl}`}
                    >
                        Clear server cache
                    </a>
                </div>
            )}
            <NewRow />
            <LabelInput
                value={deviceId}
                size="semiwide"
                label="Profile ID"
                placeholder="ex: 84ddd9dd-f3af-46b1-8fac-8241ff507e38"
                onChange={(e) => setDeviceId(e.target.value)}
                onKeyPress={(e) => {
                    e.key === 'Enter' && getProfiles()
                }}
            />
            <button onClick={getProfiles} disabled={!deviceId}>
                Find
            </button>
            {isLoading && <Loading text="Loading..." />}
            {!isLoading && profileIds.length > 0 && (
                <div>
                    {profileIds.length > 1 && (
                        <>
                            <div className={styles.warningInfo}>
                                <img
                                    alt="Multiple accounts"
                                    className={styles.icon}
                                    src={warningImg}
                                />
                                <label>
                                    There are multiple profiles for this device.
                                    Select one to see related purchases!
                                </label>
                            </div>
                            {profileIds.map((p) => {
                                const isSelected =
                                    selectedProfileId === p.profileId
                                return (
                                    <div
                                        key={`profile_${p.profileId}`}
                                        className={classNames(
                                            styles.selectAccounts,
                                            isSelected ? styles.selected : ''
                                        )}
                                    >
                                        <label>{p.profileId}</label>
                                        {profileIds.length > 1 && (
                                            <button
                                                disabled={isSelected}
                                                className="small"
                                                onClick={() => {
                                                    setPackIdsToAdd([])
                                                    getPurchases(p.profileId)
                                                }}
                                            >
                                                Select
                                            </button>
                                        )}
                                    </div>
                                )
                            })}
                        </>
                    )}
                </div>
            )}
            {!isLoading && selectedProfileId && (
                <>
                    <SpeechBubble
                        style={{
                            justifyContent: 'left',
                            marginBottom: 20,
                        }}
                    >
                        Looking at profile id {selectedProfileId}
                    </SpeechBubble>
                    <div className={styles.marginBottom20}>
                        <button
                            className={styles.deleteButton}
                            onClick={() => deleteAccount(selectedProfileId)}
                        >
                            Delete account and purchases info
                        </button>
                        <CopyProductsModal
                            profileIdCopyFrom={selectedProfileId}
                            selectedProductIds={ownedSKUs}
                            allPacksInfo={packs}
                        />
                    </div>
                    <AddProductsModal
                        profileId={selectedProfileId}
                        packsToAdd={packs.filter((pack) =>
                            packIdsToAdd.includes(pack.packId)
                        )}
                        onSuccess={onSuccessfullyAddedPacks}
                    />
                    <div className={styles.marginBottom20}>
                        <button
                            className={styles.secondaryButton}
                            onClick={() => selectAllUncheckedPacks()}
                        >
                            Select all not owned packs
                        </button>
                        <button
                            className={styles.secondaryButton}
                            onClick={() => setPackIdsToAdd([])}
                        >
                            Unselect all not owned packs
                        </button>
                    </div>
                    <ManagePlaysets
                        title={`Player owned packs (${selectedPacks.length}/${packs.length})`}
                        items={packIdsToAdd}
                        preselectedItems={new Set(selectedPacks)}
                        playsets={packs}
                        togglePlayset={togglePack}
                    />
                </>
            )}
        </Section>
    )
}

export default Accounts
