import { createRef } from 'react'
import ReactDOM from 'react-dom'
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd'
import classNames from 'classnames'

const CarouselsTable = (props) => {
    const carousels = props.carousels
    const css = {
        name: '',
        buttons: '',
        draghandle: '',
        row: {
            width: '',
            height: '',
        },
    }
    let nameTd = createRef()
    let buttonsTd = createRef()
    let draghandleTd = createRef()
    let rowElement = createRef()

    const onBeforeDragStart = () => {
        css.name = window.getComputedStyle(ReactDOM.findDOMNode(nameTd)).width
        css.buttons = window.getComputedStyle(
            ReactDOM.findDOMNode(buttonsTd)
        ).width
        css.draghandle = window.getComputedStyle(
            ReactDOM.findDOMNode(draghandleTd)
        ).width
        css.row.width = window.getComputedStyle(
            ReactDOM.findDOMNode(rowElement)
        ).width
        css.row.height = window.getComputedStyle(
            ReactDOM.findDOMNode(rowElement)
        ).height
        return true
    }

    const getTdStyle = (isDragging, columnName) => ({
        width: isDragging && css[columnName],
        backgroundColor: isDragging && '#EFF8FB',
    })

    const getItemStyle = (isDragging, draggableStyle) => ({
        width: css.row.width,
        height: css.row.height,
        ...draggableStyle,
    })

    const getDraggableRow = (provided, snapshot, c, i) => {
        return [
            <tr
                key={'tr_carousel_draggablerow_id_' + c.carouselId}
                className="draggableCarousel"
                onClick={() => props.onCarouselClick(c.carouselId)}
                ref={(element) => {
                    rowElement = element
                    provided.innerRef(element)
                }}
                style={getItemStyle(
                    snapshot.isDragging,
                    provided.draggableStyle
                )}
                {...provided.draggableProps}
            >
                <td
                    ref={(ref) => (nameTd = ref)}
                    className={'alignLeft'}
                    style={getTdStyle(snapshot.isDragging, 'name')}
                >
                    {c.carouselName}
                </td>

                <td
                    ref={(ref) => (buttonsTd = ref)}
                    className={'alignRight'}
                    style={getTdStyle(snapshot.isDragging, 'buttons')}
                    key={'td_buttons_' + c.carouselId}
                >
                    <button
                        style={{ margin: '0 3px' }}
                        className={classNames(
                            'small',
                            !c.active ? 'positive' : 'warning'
                        )}
                        onClick={(e) => props.onActivateCarouselClick(e, c)}
                    >
                        {!c.active ? 'Activate' : 'Deactivate'}
                    </button>
                    <button
                        style={{ margin: '0 3px' }}
                        className="small primary"
                        onClick={() => props.onCarouselClick(c.carouselId)}
                    >
                        Edit carousel
                    </button>
                    <button
                        style={{ margin: '0 3px' }}
                        className="small"
                        onClick={(e) => props.onCopyCarouselClick(e, c)}
                    >
                        Copy to...
                    </button>
                    <button
                        style={{ margin: '0 3px' }}
                        className="small danger"
                        onClick={(e) => props.onCarouselDeleteClick(e, i)}
                    >
                        Delete
                    </button>
                </td>

                <td
                    ref={(ref) => (draghandleTd = ref)}
                    style={getTdStyle(snapshot.isDragging, 'draghandle')}
                    className="alignRight dragHandle"
                    key={'ts_handle_' + c.carouselId}
                    {...provided.dragHandleProps}
                >
                    ≡
                </td>
            </tr>,
        ]
    }

    const renderTable = (carousels) => {
        return (
            <DragDropContext
                key={'dnd_carousels'}
                onDragEnd={props.onCarouselDragEnd}
                onBeforeDragStart={onBeforeDragStart}
            >
                <Droppable droppableId="droppable">
                    {(provided) => (
                        <tbody ref={provided.innerRef}>
                            {carousels.map((c, i) => (
                                <Draggable
                                    key={c.carouselId.toString()}
                                    draggableId={c.carouselId.toString()}
                                    index={i}
                                >
                                    {(dragProvided, dragSnapshot) =>
                                        getDraggableRow(
                                            dragProvided,
                                            dragSnapshot,
                                            c,
                                            i
                                        )
                                    }
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </tbody>
                    )}
                </Droppable>
            </DragDropContext>
        )
    }

    return (
        <table style={{ width: '100%' }}>
            <thead>
                <tr>
                    <th style={{ textAlign: 'left' }}>
                        <label htmlFor="name" style={{ marginRight: 0 }}>
                            Name
                        </label>
                    </th>
                    <th style={{ textAlign: 'right' }}>
                        <label
                            htmlFor="buttons"
                            style={{ marginRight: 0 }}
                        ></label>
                    </th>
                    <th style={{ textAlign: 'right' }}>
                        <label
                            htmlFor="draghandle"
                            style={{ marginRight: 0 }}
                        ></label>
                    </th>
                </tr>
            </thead>

            {renderTable(carousels)}
        </table>
    )
}

export default CarouselsTable
