import placeHolderImg from '../assets/mapPlaceholder.png'

function getEmptyMapPlayset(packId) {
    return {
        packId,
        img: {},
        x: Math.random() * 0.7 + 0.1,
        y: Math.random() * 0.7 + 0.1,
        page: 0,
        pricetag: {
            x: 0,
            y: 0,
            rotation: 180,
        },
    }
}

function getEmptyMapDistrict({ packId, displayName, locations, stringId }) {
    return {
        packId,
        stringId: stringId || null,
        displayName,
        background: {
            url: placeHolderImg,
        },
        locations,
    }
}

export { getEmptyMapDistrict, getEmptyMapPlayset }
