import { ReactNode } from 'react'
import classNames from 'classnames'

import { ChangeStatusesUnion, statusToCSSClass } from './StatusLabel'

import styles from './Cell.module.scss'

type CellProps = {
    children?: ReactNode
    type: 'horizontalHeader' | 'verticalHeader' | 'data' | 'expand'
    changeStatus?: ChangeStatusesUnion
}

function Cell(props: CellProps) {
    const { children, type, changeStatus } = props

    return (
        <div
            className={classNames(
                type === 'verticalHeader' && styles.verticalHeader,
                type === 'horizontalHeader' && styles.horizontalHeader,
                type === 'data' && styles.dataCell,
                type === 'expand' && styles.expandCell,
                changeStatus && styles[statusToCSSClass[changeStatus]]
            )}
        >
            {children}
        </div>
    )
}

export default Cell
