import _ from 'lodash'

import StatusLabel, { ChangeStatuses } from './StatusLabel'

import { mediaTypeToDisplayText } from '../../settings'

import { MediaType as MediaTypeEnum } from '../../types/enums/mediatype.enum'

import { Media as MediaType } from '../../types/mediaTypes'

import styles from './Media.module.scss'

type MediaProps = {
    medias: MediaType[]
    isUpdated?: boolean
    isExpanded: boolean
    isMediasIncluded?: boolean
    onIncludeMediasToggle?: (isMediasIncluded: boolean) => void
}

function Media(props: MediaProps) {
    const {
        medias,
        isUpdated,
        isExpanded,
        isMediasIncluded,
        onIncludeMediasToggle,
    } = props
    const mediaByType = _.groupBy(medias, 'type')

    return (
        <div>
            <div className={styles.summaryRow}>
                <div className={styles.summary}>
                    {medias.length}
                    {isUpdated && (
                        <StatusLabel status={ChangeStatuses.UPDATED} />
                    )}
                </div>
                {onIncludeMediasToggle && (
                    <label>
                        Copy all media
                        <input
                            type="checkbox"
                            checked={isMediasIncluded}
                            onChange={(e) =>
                                onIncludeMediasToggle(e.target.checked)
                            }
                        />
                    </label>
                )}
            </div>
            {isExpanded &&
                Object.entries(mediaByType).map(([type, medias]) => {
                    return (
                        <div key={type} className={styles.mediaList}>
                            <div className={styles.mediaType}>
                                {
                                    mediaTypeToDisplayText[
                                        // Fixme groupBy converts type to string and the generic does not allow to set key type
                                        // There could be better solution to this than casting
                                        type as MediaType['type']
                                    ]
                                }{' '}
                                ({medias.length})
                            </div>
                            <div className={styles.thumbnails}>
                                {medias.map((media) =>
                                    media.type === MediaTypeEnum.VIDEO ? (
                                        <video
                                            key={media.id}
                                            aria-label={`Video for media ID: ${media.id}`}
                                            controls
                                            width="256"
                                        >
                                            <source
                                                src={media.url}
                                                type="video/mp4"
                                            />
                                        </video>
                                    ) : (
                                        <img
                                            key={media.id}
                                            className={styles.thumbnail}
                                            src={media.url.replace(
                                                '{0}',
                                                '@1x'
                                            )}
                                            alt={`Thumbnail for media ID: ${media.id}`}
                                        />
                                    )
                                )}
                            </div>
                        </div>
                    )
                })}
        </div>
    )
}

export default Media
