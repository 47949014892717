import Joi, { ValidationResult } from 'joi'
import _ from 'lodash'

import {
    descriptionLanguagesSchema,
    displayNameLanguagesSchema,
} from '../utils/utils'

import { PackType } from '../types/enums/packtype.enum'

import { Pack, PackUpdate, PriceLevel } from '../types/packTypes'

const sku = Joi.string()
    .regex(/^[a-z0-9][a-z0-9_.]+$/)
    .max(64)
    .messages({
        'string.pattern.base':
            'Product Id / SKU must start with a number or lowercase letter, and can contain numbers (0-9), lowercase letters (a-z), underscores (_) and periods (.)',
    })

const platformData = Joi.object().keys({
    store: Joi.string().required(),
    sku: sku.required(),
    active: Joi.boolean().required(),
    deprecatedSkus: Joi.array().items(Joi.string()),
})

const priceLevel = Joi.object().keys({
    productId: sku.required(),
    min: Joi.number().required(),
    max: Joi.number().required(),
    price: Joi.number().precision(2).min(0).max(1000).allow(null).allow(''),
    platformData: Joi.array().items(platformData),
})

const priceLevelComparator = (left: PriceLevel, right: PriceLevel) => {
    return left.min === right.min || left.max === right.max
}

const displayName = Joi.object()
    .keys(displayNameLanguagesSchema)
    .required()
    .unknown(true)
const features = Joi.object().keys(descriptionLanguagesSchema).unknown(true)

type PackValidationItem = PackUpdate & {
    activeToDateCopy: Pack['activeToDate']
    childPacks: Pack[]
    isBundleWorthCovered?: boolean
}

const createSchemas = (item: PackValidationItem) => {
    const {
        PACK,
        STANDALONE,
        MULTIPACK,
        PLAYSET,
        HOME_DESIGNER,
        OUTFIT_MAKER,
        CHARACTER_CREATOR,
    } = PackType

    item.isBundleWorthCovered = true

    if (item.type === MULTIPACK) {
        const totalWorth = item.childPacks.reduce(
            (acc, pack: Pack) => acc + pack?.worth,
            0
        )
        const totalWorthInPriceId = item.priceLevels.find((product) => {
            return product.min <= totalWorth && totalWorth <= product.max
        })
        item.isBundleWorthCovered = !!totalWorthInPriceId
    }

    const packWarningSchema = Joi.object()
        .options({ abortEarly: false })
        .keys({
            packId: Joi.string().required(),
            name: Joi.string().required(),
            requiredVersion: Joi.number().required(),
            worth: Joi.number().integer().min(0),
            priority: Joi.number().integer().min(0),
            displayName,
            features,
            type: Joi.string()
                .valid(
                    MULTIPACK,
                    PLAYSET,
                    HOME_DESIGNER,
                    OUTFIT_MAKER,
                    CHARACTER_CREATOR
                )
                .invalid(STANDALONE, PACK)
                .messages({
                    'any.invalid': `Packs of the type "${item.type}" are deprecated and changes are restricted`,
                })
                .required(),
            activeFromDate: Joi.date().required(),
            activeToDate: Joi.date().required(),
            activeToDateCopy: Joi.any(),

            // Scene is required for `Playset`, has to be empty for `Multipack` and could be added to Homedesigner, Outfit maker, or Character creator packs
            scenes: Joi.array()
                .when('type', {
                    is: Joi.alternatives().try(
                        Joi.string().valid(MULTIPACK),
                        Joi.string().valid(PACK)
                    ),
                    then: Joi.array().length(0).required(),
                })
                .when('type', {
                    is: PLAYSET,
                    then: Joi.array().min(1).items(Joi.string()).required(),
                    otherwise: Joi.array()
                        .min(0)
                        .items(Joi.string())
                        .required(),
                })
                .messages({
                    'array.min': `"${PLAYSET}" pack should have at least 1 scene`,
                    'array.length': `"${MULTIPACK}" type should have no scenes`,
                    'any.required': 'Scene count is not correct for this type',
                }),
            // Content can be added to playset, Home designer, Outfit maker, or Character creator
            content: Joi.array()
                .when('type', {
                    is: Joi.alternatives().try(
                        Joi.string().valid(MULTIPACK),
                        Joi.string().valid(PACK)
                    ),
                    then: Joi.array().length(0).required(),
                    otherwise: Joi.array().items(Joi.string()),
                })
                .messages({
                    'array.length': `"${MULTIPACK}" type should not have content id`,
                    'any.required':
                        'Content count is incorrect for this pack type',
                }),
            childPacks: Joi.array()
                .when('type', {
                    is: Joi.alternatives().try(
                        Joi.string().valid(MULTIPACK),
                        Joi.string().valid(PACK)
                    ),
                    then: Joi.array()
                        .min(1)
                        .items(
                            Joi.object()
                                .keys({
                                    name: Joi.string(),
                                    requiredVersion: Joi.number()
                                        .integer()
                                        .custom((value, helpers) => {
                                            if (
                                                value > item.requiredVersion &&
                                                Array.isArray(
                                                    helpers.state.path
                                                )
                                            ) {
                                                return helpers.error(
                                                    'childPack.requiredVersion',
                                                    {
                                                        limit: item.requiredVersion,
                                                        packName:
                                                            item.childPacks[
                                                                helpers.state
                                                                    .path[1]
                                                            ].packId,
                                                    }
                                                )
                                            }
                                            return value
                                        })
                                        .messages({
                                            'childPack.requiredVersion': `Child pack '{#packName}' has a higher required version ({#value}) than this pack ({#limit})`,
                                        }),
                                })
                                .unknown(true)
                        )
                        .required(),
                    otherwise: Joi.array().length(0),
                })
                .messages({
                    'array.min': `${MULTIPACK} should have at least 1 pack in its content`,
                    'array.sparse': 'Some of the content packs are missed',
                    'array.length':
                        'Nr of districts and playsets are not correct for this pack type',
                }),

            locations: Joi.when('type', {
                is: PLAYSET,
                then: Joi.number().integer().required().min(0).max(100),
                otherwise: Joi.number().min(0).max(0).required(),
            }).label('Building count'),
            characters: Joi.when('type', {
                is: PLAYSET,
                then: Joi.number().integer().required().min(0).max(100),
                otherwise: Joi.number().min(0).max(0).required(),
            }).label('Character count'),
            templates: Joi.number().integer().required(),
            furniture: Joi.number().integer().required(),
            surfaceDesigns: Joi.number().integer().required(),
            garments: Joi.number().integer().required(),
            patterns: Joi.number().integer().required(),
            stickers: Joi.number().integer().required(),
            outfits: Joi.number().integer(),
            accessories: Joi.number().integer(),
            hairStyles: Joi.number().integer(),
            medias: Joi.array().items(Joi.number()),

            priceLevels: Joi.array().min(1).items(priceLevel).required(),

            carouselIds: Joi.array(),
            isBundleWorthCovered: Joi.boolean()
                .invalid(false)
                .required()
                .messages({
                    'any.invalid':
                        'Bundle value must be represented as a min/max value',
                }),
        })

    const packErrorSchema = Joi.object()
        .options({ abortEarly: false })
        .keys({
            packId: Joi.string()
                .pattern(/^[a-z0-9._]*$/)
                .required()
                .messages({
                    'string.empty': 'Pack ID can not be empty',
                    'string.pattern.base':
                        'Pack ID can only contain lowercase alphanumeric characters and dots',
                }),
            name: Joi.string().required().messages({
                'string.empty': 'String ID can not be empty',
            }),
            requiredVersion: Joi.number().required(),
            worth: Joi.number().integer(),
            priority: Joi.number().integer(),
            displayName: Joi.object(),
            features: Joi.object(),
            type: Joi.string()
                .valid(
                    MULTIPACK,
                    PLAYSET,
                    HOME_DESIGNER,
                    OUTFIT_MAKER,
                    CHARACTER_CREATOR
                )
                .invalid(STANDALONE, PACK)
                .messages({
                    'any.invalid': `Packs of the type "${item.type}" are deprecated and changes are restricted`,
                })
                .required(),
            activeFromDate: Joi.date()
                .required()
                .less(Joi.ref('activeToDateCopy'))
                .messages({
                    'date.base': '"Starts on" date must be selected',
                    'date.less':
                        '"Starts on" date must be before "Ends on" date',
                }),
            activeToDate: Joi.date()
                .required()
                .greater(Joi.ref('activeFromDate'))
                .messages({
                    'date.base': '"Ends on" date must be selected',
                    'date.greater':
                        '"Ends on" date must be after "Starts on" date',
                }),
            activeToDateCopy: Joi.any(),
            scenes: Joi.array().required(),
            content: Joi.array().required(),
            childPacks: Joi.array().items(Joi.object()).required(),
            locations: Joi.number().integer().required(),
            characters: Joi.number().integer().required(),
            templates: Joi.number().integer().required(),
            furniture: Joi.number().integer().required(),
            surfaceDesigns: Joi.number().integer().required(),
            garments: Joi.number().integer().required(),
            patterns: Joi.number().integer().required(),
            stickers: Joi.number().integer().required(),
            outfits: Joi.number().integer(),
            accessories: Joi.number().integer(),
            hairStyles: Joi.number().integer(),
            medias: Joi.array().items(Joi.number()),
            priceLevels: Joi.array()
                .min(1)
                .items(priceLevel)
                .unique(priceLevelComparator)
                .messages({
                    'array.unique': `Product ID '{#value.productId}' has a duplicate min or max value`,
                    'array.min': `Pack must contain at least 1 price level`,
                })
                .required(),
            carouselIds: Joi.array(),
            isBundleWorthCovered: Joi.boolean(),
        })

    return {
        packWarningSchema,
        packErrorSchema,
    }
}

function validate(
    item: PackUpdate,
    childPacksData: Pack[]
): {
    errors: ValidationResult
    warnings: ValidationResult
} {
    const validationItem: PackValidationItem = Object.assign(
        _.cloneDeep(item),
        {
            activeToDateCopy: item.activeToDate,
            childPacks: childPacksData,
        }
    )

    const { packWarningSchema, packErrorSchema } = createSchemas(validationItem)
    const warnings = packWarningSchema.validate(validationItem)
    const errors = packErrorSchema.validate(validationItem)
    return { errors, warnings }
}

export default validate
