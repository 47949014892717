import { useState } from 'react'
import { Tooltip } from 'react-tooltip'
import axios from 'axios'
import _ from 'lodash'

import ModalV2, {
    Button,
    ButtonKind,
    Footer,
    Status,
    StatusType,
} from '../shared/ModalV2'
import { Checkbox, Loading } from '../shared'

import { getPackDisplayName, handleError } from '../utils/utils'

import { Profile, Purchase } from '../types/accountTypes'
import { Pack } from '../types/packTypes'

import styles from './CopyProducts.module.scss'

const defaultProgressText = `Please note, this process can't be reversed`

type CopyProductsProps = {
    profileIdCopyFrom: string
    selectedProductIds: string[]
    allPacksInfo: Pack[]
}

const CopyProductsModal = (props: CopyProductsProps) => {
    const { profileIdCopyFrom, selectedProductIds, allPacksInfo } = props
    const [isModalShown, setIsModalShown] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [hasCopiedSuccessfully, setHasCopiedSuccessfully] = useState(false)
    const [deviceIdCopyTo, setDeviceIdCopyTo] = useState('')
    const [profileIdCopyTo, setProfileIdCopyTo] = useState('')
    const [progressText, setProgressText] = useState(defaultProgressText)
    const [productIdsToCopy, setProductIdsToCopy] = useState<string[]>([])
    const [isProfileSearched, setIsProfileSearched] = useState(false)

    const getPurchases = async (profileId: string) => {
        setIsLoading(true)
        setProfileIdCopyTo(profileId)
        setProductIdsToCopy([])
        try {
            const res = await axios.get<{ purchases: Purchase[] }>(
                `/api/v2/purchases/${profileId}`
            )
            const purchases = (res.data && res.data.purchases) || []
            const filteredProducts = selectedProductIds.filter((productId) => {
                return !purchases.some((item) => item.productId === productId)
            })
            setProductIdsToCopy(filteredProducts)
        } catch (e) {
            handleError('Error fetching purchases', e)
        }
        setIsLoading(false)
    }

    const fetchProfiles = async () => {
        if (!deviceIdCopyTo) return
        setIsLoading(true)
        setProgressText('Searching profiles')

        try {
            const res = await axios.get<Profile[]>(
                `/api/v2/profiles/${deviceIdCopyTo}`
            )
            const profiles = res.data

            if (!profiles || profiles.length === 0) {
                setIsLoading(false)
                setIsProfileSearched(true)
                setProfileIdCopyTo('')
                setProgressText('⚠️ No profiles found')
                return
            }

            // ToDo do better handling of multiple profiles for MVP2
            getPurchases(profiles[0].profileId)
            setIsProfileSearched(true)
            setProgressText(
                `✅ Found profile with ID: ${profiles[0].profileId}`
            )
        } catch (error) {
            handleError('Error fetching profiles', error)
            setProfileIdCopyTo('')
            setIsProfileSearched(true)
            setProgressText('⚠️ Error fetching profiles')
        }
        setIsLoading(false)
    }

    const copyProducts = async () => {
        if (!profileIdCopyTo) return

        if (productIdsToCopy.length === 0) {
            setProgressText('No products to copy')
            return
        }
        setIsLoading(true)
        setProgressText('Copying products')

        try {
            await axios.post(`/api/v2/profile/${profileIdCopyTo}/addproducts`, {
                productIds: productIdsToCopy,
            })
            setProgressText(
                `✅ ${productIdsToCopy.length} products successfully copied`
            )
            setHasCopiedSuccessfully(true)
        } catch (error) {
            handleError('Error adding products', error)
            setProgressText('⚠️ Error adding products. Please try again later.')
            setHasCopiedSuccessfully(false)
        }
        setIsLoading(false)
    }

    const resetState = () => {
        setDeviceIdCopyTo('')
        setProfileIdCopyTo('')
        setProductIdsToCopy([])
        setProgressText(defaultProgressText)
        setIsLoading(false)
        setHasCopiedSuccessfully(false)
        setIsProfileSearched(false)
    }

    const renderCopyingDetails = () => {
        if (isLoading) return <Loading />
        if (!profileIdCopyTo) return null

        return (
            <>
                <hr className={styles.lineSeparator} />
                {productIdsToCopy.length === 0 ? (
                    <p className="noMargin">
                        All selected products already exist in the second
                        profile.
                    </p>
                ) : (
                    <>
                        <p className="noMargin">
                            Products selected for copy (
                            {productIdsToCopy.length}).
                        </p>
                        <label>
                            If a product already exists in the second profile it
                            won't be copied.
                        </label>
                    </>
                )}
                <div className={styles.packsList} style={{ marginTop: 20 }}>
                    {allPacksInfo
                        .filter((pack) =>
                            pack.priceLevels.some((product) =>
                                productIdsToCopy.includes(product.productId)
                            )
                        )
                        .map((pack, i) => (
                            <div className={styles.pack} key={'pack_' + i}>
                                <Tooltip id={`copy-products-${pack.packId}`} />
                                <div
                                    data-tooltip-id={`copy-products-${pack.packId}`}
                                    data-tooltip-content={pack.packId}
                                    style={{ display: 'inline-block' }}
                                >
                                    <Checkbox
                                        key={pack.packId}
                                        disabled={true}
                                        label={`${getPackDisplayName(pack)} (${
                                            pack.worth
                                        })`}
                                        highlight={false}
                                        isSearching={false}
                                        checked={true}
                                        pack={pack}
                                        onToggle={_.noop}
                                    />
                                </div>
                            </div>
                        ))}
                </div>
            </>
        )
    }

    const renderModalContent = () => {
        if (hasCopiedSuccessfully) {
            return (
                <>
                    <Status
                        type={StatusType.Info}
                        header={`${productIdsToCopy.length} products successfully copied`}
                        body="The products can be accessed by the destination Profile ID. You can now close this window."
                    />
                    <Footer>
                        <Button
                            kind={ButtonKind.SECONDARY}
                            onClick={() => {
                                setIsModalShown(false)
                                resetState()
                            }}
                        >
                            Close
                        </Button>
                    </Footer>
                </>
            )
        }

        const isCopyDisabled =
            isLoading || !profileIdCopyTo || !productIdsToCopy.length
        return (
            <div>
                <form
                    id="copyToForm"
                    className={styles.twoCols}
                    style={{ marginBottom: 15 }}
                    onReset={() => resetState()}
                    onSubmit={(e) => {
                        e.preventDefault()
                        fetchProfiles()
                    }}
                >
                    <div>
                        <span>From Profile ID:</span>
                        <span className={styles.greyColor}>
                            {profileIdCopyFrom}
                        </span>
                    </div>
                    <div>
                        <span>To Profile ID:</span>
                        <input
                            name="deviceIdCopyTo"
                            type="text"
                            autoFocus={true}
                            className="semiwide"
                            placeholder="ex: 84ddd9dd-f3af-46b1-8fac-8241ff507e38"
                            value={deviceIdCopyTo}
                            onChange={(e) => {
                                setDeviceIdCopyTo(e.target.value)
                            }}
                        />
                        <Button type="submit" disabled={!deviceIdCopyTo}>
                            Find
                        </Button>
                        <Button kind={ButtonKind.SECONDARY} type="reset">
                            Reset form
                        </Button>
                    </div>
                    <div
                        style={{
                            color: profileIdCopyTo ? 'green' : 'red',
                            display:
                                isProfileSearched && !isLoading
                                    ? 'block'
                                    : 'none',
                        }}
                    >
                        <span>
                            <input
                                type="hidden"
                                name="profileIdCopyTo"
                                value={profileIdCopyTo}
                            />
                        </span>
                        <small>
                            {profileIdCopyTo
                                ? '✅ Profile ID found'
                                : '⚠️ Sorry, Profile ID not found'}
                        </small>
                    </div>
                </form>
                {profileIdCopyTo && renderCopyingDetails()}
                <hr className={styles.lineSeparator} />
                <Footer>
                    <p>{progressText}</p>
                    <div>
                        <Button
                            kind={ButtonKind.SECONDARY}
                            onClick={() => setIsModalShown(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            kind={ButtonKind.PRIMARY}
                            onClick={copyProducts}
                            disabled={isCopyDisabled}
                        >
                            Copy products
                        </Button>
                    </div>
                </Footer>
            </div>
        )
    }

    return (
        <>
            <button
                style={{ marginLeft: 20 }}
                onClick={() => setIsModalShown(!isModalShown)}
                disabled={selectedProductIds.length === 0}
            >
                Copy products
            </button>
            {isModalShown && (
                <ModalV2>
                    <h1>Copy Products</h1>
                    {renderModalContent()}
                </ModalV2>
            )}
        </>
    )
}

export default CopyProductsModal
