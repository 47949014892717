import axios from 'axios'

import { handleError } from './utils'

async function fetchDataFromPath(path) {
    try {
        const { data } = await axios.get(path)
        console.log(`Found ${data} in path ${path}`)
        return data
    } catch (error) {
        if (error.response?.status === 404) {
            console.log(`No data retrieved using path ${path}`)
            return {}
        }

        console.log(
            `Error when fetching data - ${error.response?.body?.message}`
        )
        handleError('Error getting data', error)
    }
}

async function postDataForPath(data, path) {
    console.log(`Posting data using path ${path}`)
    try {
        const res = await axios.post(path, data)
        if (res.status === 201) return true
        else return false
    } catch (e) {
        handleError('Error saving data', e)
        return false
    }
}

async function putDataForPath(data, path) {
    console.log(`Putting data using path ${path}:`, data)
    try {
        const res = await axios.put(path, data)
        if (res.status === 200) return true
        else return false
    } catch (e) {
        handleError('Error saving data', e)
        return false
    }
}

export { fetchDataFromPath, postDataForPath, putDataForPath }
