import { ChangeEventHandler, useEffect, useState } from 'react'
import {
    createSearchParams,
    useNavigate,
    useSearchParams,
} from 'react-router-dom'
import _ from 'lodash'

import ModalV2 from '../shared/ModalV2'
import Pagination from '../shared/pagination/Pagination'
import Header from './header/Header'
import Media from './Media'
import { Loading, MediaImage, Section } from '../shared'

import { Order, SortByOption, useDeleteMedia, useMedias } from '../hooks/medias'

import { handleError } from '../utils/utils'
import { getFileNameFromUrl } from './utils'

import { MediaType as MediaTypeEnum } from '../types/enums/mediatype.enum'

import { Media as MediaType } from '../types/mediaTypes'
import { MediaUsages } from '../types/usageTypes'

import styles from './Medias.module.scss'

import Link from '../assets/link.svg'

export const PAGE_SIZE = 30
enum SearchParams {
    Page = 'page',
    SortBy = 'sortBy',
    Order = 'order',
    FilterByType = 'filterByType[]',
    FilterByDateFrom = 'filterByDateFrom',
    FilterByDateTo = 'filterByDateTo',
}

function getUsageCountByMediaId(
    usagesByMediaId: Record<MediaType['id'], MediaUsages>
) {
    const usageCountByMediaId: Record<MediaType['id'], number> = {}
    for (const [mediaId, usages] of Object.entries(usagesByMediaId)) {
        usageCountByMediaId[Number(mediaId)] = _.sumBy(
            Object.values(usages),
            (usage) => usage.length
        )
    }
    return usageCountByMediaId
}

function Medias() {
    const {
        deleteMedia,
        isLoading: isDeleting,
        error: deleteError,
    } = useDeleteMedia()

    const [searchParams, setSearchParams] = useSearchParams()
    const page = Number(searchParams.get(SearchParams.Page)) || 1
    const sortBy = searchParams.get(SearchParams.SortBy) || undefined
    const order = searchParams.get(SearchParams.Order) || undefined
    const filterByType = searchParams.getAll(SearchParams.FilterByType)
    const filterByDateFrom =
        searchParams.get(SearchParams.FilterByDateFrom) || undefined
    const filterByDateTo =
        searchParams.get(SearchParams.FilterByDateTo) || undefined
    const navigate = useNavigate()
    const {
        mediasResponse,
        isPending: isLoading,
        error,
    } = useMedias({
        page,
        limit: PAGE_SIZE,
        sortBy: sortBy as SortByOption,
        order: order as Order,
        filterByType: filterByType as MediaTypeEnum[],
        filterByDateFrom,
        filterByDateTo,
    })
    const medias = mediasResponse?.medias || []
    const usagesByMediaId = mediasResponse?.usagesByMediaId || {}
    const totalPageCount = mediasResponse?.pagination.totalPageCount || 0

    const usageCountByMediaId = getUsageCountByMediaId(usagesByMediaId)

    const [selectedMediaId, setSelectedMediaId] = useState<
        MediaType['id'] | undefined
    >()
    const selectedMedia = _.find(medias, { id: selectedMediaId })
    const selectedMediaUsage =
        selectedMediaId === undefined
            ? { packs: [], tabs: [], maps: [] }
            : usagesByMediaId[selectedMediaId]
    const selectedMediaUsageCount =
        selectedMediaId === undefined ? 0 : usageCountByMediaId[selectedMediaId]

    useEffect(() => {
        // If the current page is greater than the total page count, redirect to the last page. This happens when user adds filters and the total page count decreases.
        if (totalPageCount && page > totalPageCount) {
            const redirectSearchParams = createSearchParams(searchParams)
            redirectSearchParams.set(
                SearchParams.Page,
                totalPageCount.toString()
            )
            navigate({ search: redirectSearchParams.toString() })
        }
    }, [page, totalPageCount])

    useEffect(() => {
        // Redirect to sort by created date in descending order as a default.
        if (!sortBy || !order) {
            const redirectSearchParams = createSearchParams(searchParams)
            !sortBy &&
                redirectSearchParams.set(
                    SearchParams.SortBy,
                    SortByOption.Created
                )
            !order && redirectSearchParams.set(SearchParams.Order, Order.DESC)
            navigate({ search: redirectSearchParams.toString() })
        }
    }, [sortBy, order, page])

    const onTypeFilterChange = (toggledType: MediaTypeEnum) => {
        setSearchParams((searchParams) => {
            searchParams.has(SearchParams.FilterByType, toggledType)
                ? searchParams.delete(SearchParams.FilterByType, toggledType)
                : searchParams.append(SearchParams.FilterByType, toggledType)
            return searchParams
        })
    }

    const onDateFilterChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        const { name, value } = e.currentTarget
        setSearchParams((searchParams) => {
            value ? searchParams.set(name, value) : searchParams.delete(name)
            return searchParams
        })
    }

    const onSortChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
        const [sortBy, order] = e.currentTarget.value.split(':')
        setSearchParams((searchParams) => {
            sortBy
                ? searchParams.set(SearchParams.SortBy, sortBy)
                : searchParams.delete(SearchParams.SortBy)
            order
                ? searchParams.set(SearchParams.Order, order)
                : searchParams.delete(SearchParams.Order)
            return searchParams
        })
    }

    const onMediaClick = (mediaId: MediaType['id']) => {
        setSelectedMediaId(mediaId)
    }

    const onMediaClose = () => {
        setSelectedMediaId(undefined)
    }

    const onDeleteMedia = async (id: MediaType['id']) => {
        await deleteMedia(id)
        setSelectedMediaId(undefined)
    }

    if (error) {
        handleError('Failed to list medias', error)
    }
    if (deleteError) {
        handleError('Failed to delete media', deleteError)
    }

    return (
        <Section>
            {selectedMedia && (
                <ModalV2>
                    {isDeleting && <Loading />}
                    {!isDeleting && (
                        <Media
                            media={selectedMedia}
                            usage={selectedMediaUsage}
                            usageCount={selectedMediaUsageCount}
                            onDeleteMedia={onDeleteMedia}
                            onClose={onMediaClose}
                        />
                    )}
                </ModalV2>
            )}
            <Header
                sortBy={sortBy}
                order={order}
                filterByType={filterByType as MediaTypeEnum[]}
                filterByDateFrom={filterByDateFrom}
                filterByDateTo={filterByDateTo}
                onSortChange={onSortChange}
                onTypeFilterChange={onTypeFilterChange}
                onDateFilterChange={onDateFilterChange}
            />
            {isLoading && <Loading />}
            {!isLoading && (
                <div className={styles.grid}>
                    {medias.map((media) => (
                        <div key={media.id} className={styles.gridCell}>
                            <div
                                className={styles.imageWrapper}
                                onClick={() => onMediaClick(media.id)}
                            >
                                <MediaImage media={media} />
                            </div>
                            <div className={styles.metadata}>
                                <div className={styles.fileName}>
                                    {getFileNameFromUrl(media.url)}
                                </div>
                                <div className={styles.timestamp}>
                                    {media.created}
                                </div>
                                <div className={styles.usages}>
                                    <img
                                        alt="Usages count"
                                        src={Link}
                                        width="12px"
                                    />
                                    {usageCountByMediaId[media.id]}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            <div className={styles.pagination}>
                <Pagination
                    currentPage={page}
                    totalPageCount={totalPageCount}
                />
            </div>
        </Section>
    )
}

export default Medias
