import Link from './link/Link'

import styles from './Pagination.module.scss'

import ChevronLeft from '../../assets/chevron-left.svg'
import ChevronRight from '../../assets/chevron-right.svg'

type PaginationProps = {
    currentPage: number
    totalPageCount: number
}

function Pagination(props: PaginationProps) {
    const { currentPage, totalPageCount } = props

    const pageRange = 4
    let startPage = currentPage - pageRange / 2
    let endPage = currentPage + pageRange / 2

    if (totalPageCount <= pageRange) {
        startPage = 1
        endPage = totalPageCount
    } else if (currentPage < pageRange) {
        startPage = 1
        endPage = pageRange + 1
    } else if (currentPage > totalPageCount - pageRange) {
        startPage = totalPageCount - pageRange
        endPage = totalPageCount - 1
    }

    const pages = []
    pages.push(
        <Link page={Math.max(currentPage - 1, 1)}>
            <img alt="Previous" className={styles.arrow} src={ChevronLeft} />
        </Link>
    )
    if (startPage > 1) {
        pages.push(
            <Link page={1} isActive={currentPage === 1}>
                1
            </Link>
        )
    }
    if (startPage > 2) {
        pages.push('…')
    }
    for (let i = startPage; i <= endPage; i += 1) {
        pages.push(
            <Link page={i} isActive={currentPage === i}>
                {i}
            </Link>
        )
    }
    if (endPage < totalPageCount - 1) {
        pages.push('…')
    }
    if (endPage < totalPageCount) {
        pages.push(
            <Link
                page={totalPageCount}
                isActive={currentPage === totalPageCount}
            >
                {totalPageCount}
            </Link>
        )
    }
    pages.push(
        <Link page={Math.min(currentPage + 1, totalPageCount)}>
            <img alt="Next" className={styles.arrow} src={ChevronRight} />
        </Link>
    )

    return <nav className={styles.pagination}>{pages}</nav>
}

export default Pagination
