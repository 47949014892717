import _ from 'lodash'

const interval = 100

function start(component) {
    stop()
    component.setState(
        { initialData: _.cloneDeep(component.state.data), hasChanged: false },
        () => {
            window.trackInterval = setInterval(
                () => trackComponentChanges(component),
                interval
            )
        }
    )
}

function stop() {
    window.trackInterval && clearInterval(window.trackInterval)
    window.trackInterval = false
    setHasUnsavedChanges(false)
}

function restart(component) {
    setTimeout(() => {
        component.setState({ saveStatus: 'saved' })
    }, 500)

    setTimeout(() => {
        if (typeof component.state.isNew === 'boolean') {
            component.setState({ isNew: false }) // set isNew to allow updating a new pack without reloading
        }
        start(component)
    }, 2000)
}

function trackComponentChanges(component) {
    if (
        component.state.hasChanged ||
        !_.isEqual(component.state.data, component.state.initialData)
    ) {
        if (component.state.saveStatus !== 'hasChanged') {
            component.setState({ saveStatus: 'hasChanged' })
            setHasUnsavedChanges(true)
        }
    } else {
        if (component.state.saveStatus !== 'nochanges') {
            component.setState({ saveStatus: 'nochanges' })
            setHasUnsavedChanges(false)
        }
    }
}

const tracking = {
    start,
    stop,
    restart,
}

function setHasUnsavedChanges(hasUnsavedChanges) {
    localStorage.setItem('hasUnsavedChanges', hasUnsavedChanges)
}

export default tracking
