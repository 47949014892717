import { useState } from 'react'
import _ from 'lodash'

import About from './About'
import Cell from './Cell'
import DatePicker from './DatePicker'
import Expand from './Expand'
import Media from './Media'
import Row from './Row'
import StatusLabel, { ChangeStatuses, ChangeStatusesUnion } from './StatusLabel'
import Table from './Table'

import { compareDates } from '../../utils/dates'
import {
    hasAboutChanged,
    hasItemsChanged,
    hasMediasChanged,
    hasPackChanged,
} from './utils'

import {
    itemPropertiesByPackType,
    packPropertyToDisplayText,
    packTypeToDisplayText,
} from '../../settings'

import { Pack } from '../../types/packTypes'

import styles from './PreviewTable.module.scss'

type CopyPackPreviewProps = {
    pack: Pack
    newPack: Pack
    packInTargetEnv?: Pack
    currentEnvName?: string
    targetEnvName?: string
    validationErrors: Record<string, boolean>
    isMediasIncluded: boolean
    onIncludeMediasToggle: (isMediasIncluded: boolean) => void
    activeFrom?: string
    onActiveFromChange: (date?: string) => void
    activeTo?: string
    onActiveToChange: (date?: string) => void
}

function CopyPackPreview(props: CopyPackPreviewProps) {
    const {
        pack,
        newPack,
        packInTargetEnv,
        currentEnvName,
        targetEnvName,
        validationErrors,
        isMediasIncluded,
        onIncludeMediasToggle,
        activeFrom,
        activeTo,
        onActiveFromChange,
        onActiveToChange,
    } = props
    const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>(
        {}
    )

    let changeStatus: ChangeStatusesUnion = ChangeStatuses.NO_CHANGES
    if (packInTargetEnv && hasPackChanged(newPack, packInTargetEnv)) {
        changeStatus = ChangeStatuses.UPDATED
    }
    if (targetEnvName && !packInTargetEnv) {
        changeStatus = ChangeStatuses.NEW
    }
    if (!_.isEmpty(validationErrors)) {
        changeStatus = ChangeStatuses.INVALID
    }

    const itemProperties = itemPropertiesByPackType[pack.type] ?? []
    let itemsCount = 0
    for (const itemProperty of itemProperties) {
        // Fixme There should be a way to define itemProperties so it returns correct (number) property type
        itemsCount += pack[itemProperty] as number
    }

    let updatedRows: Record<string, boolean> = {}
    if (packInTargetEnv) {
        updatedRows = {
            about: hasAboutChanged(pack, packInTargetEnv),
            type: pack.type !== packInTargetEnv.type,
            requiredVersion:
                pack.requiredVersion !== packInTargetEnv.requiredVersion,
            worth: pack.worth !== packInTargetEnv.worth,
            activeToDate:
                compareDates(
                    activeTo ?? pack.activeToDate,
                    packInTargetEnv.activeToDate
                ) !== 0,
            activeFromDate:
                compareDates(
                    activeFrom ?? pack.activeFromDate,
                    packInTargetEnv.activeFromDate
                ) !== 0,
            items: hasItemsChanged(pack, packInTargetEnv),
            childPacks: !_.isEqual(pack.childPacks, packInTargetEnv.childPacks),
            media: hasMediasChanged(newPack, packInTargetEnv),
        }
    }

    return (
        <div className={styles.table}>
            <Row>
                <Cell type="verticalHeader" />
                <Cell type="horizontalHeader">
                    From <b className={styles.envName}>{currentEnvName}</b>
                </Cell>
                <Cell type="horizontalHeader" changeStatus={changeStatus}>
                    {targetEnvName && (
                        <>
                            To <b className={styles.envName}>{targetEnvName}</b>
                            <StatusLabel status={changeStatus} />
                        </>
                    )}
                </Cell>
                <Cell type="expand" />
            </Row>
            <Row>
                <Cell type="verticalHeader">About</Cell>
                <Cell type="data">
                    <About pack={pack} isExpanded={expandedRows.about} />
                </Cell>
                <Cell type="data">
                    {targetEnvName && (
                        <About
                            pack={pack}
                            isExpanded={expandedRows.about}
                            isUpdated={updatedRows.about}
                        />
                    )}
                </Cell>
                <Cell type="expand">
                    <Expand
                        isExpanded={expandedRows.about}
                        onClick={() =>
                            setExpandedRows({
                                ...expandedRows,
                                about: !expandedRows.about,
                            })
                        }
                    />
                </Cell>
            </Row>
            <Row>
                <Cell type="verticalHeader">Type</Cell>
                <Cell type="data">
                    <div className={styles.data}>
                        {packTypeToDisplayText[pack.type]}
                    </div>
                </Cell>
                <Cell type="data">
                    {targetEnvName && (
                        <div className={styles.data}>
                            {packTypeToDisplayText[pack.type]}
                            {updatedRows.type && (
                                <StatusLabel status={ChangeStatuses.UPDATED} />
                            )}
                        </div>
                    )}
                </Cell>
                <Cell type="expand" />
            </Row>
            <Row>
                <Cell type="verticalHeader">Shop Version</Cell>
                <Cell type="data">
                    <div className={styles.data}>{pack.requiredVersion}</div>
                </Cell>
                <Cell type="data">
                    {targetEnvName && (
                        <div className={styles.data}>
                            {pack.requiredVersion}
                            {updatedRows.requiredVersion && (
                                <StatusLabel status={ChangeStatuses.UPDATED} />
                            )}
                        </div>
                    )}
                </Cell>
                <Cell type="expand" />
            </Row>
            <Row>
                <Cell type="verticalHeader">Worth</Cell>
                <Cell type="data">
                    <div className={styles.data}>{pack.worth}</div>
                </Cell>
                <Cell type="data">
                    {targetEnvName && (
                        <div className={styles.data}>
                            {pack.worth}
                            {updatedRows.worth && (
                                <StatusLabel status={ChangeStatuses.UPDATED} />
                            )}
                        </div>
                    )}
                </Cell>
                <Cell type="expand" />
            </Row>
            <Row>
                <Cell type="verticalHeader">Start</Cell>
                <Cell type="data">
                    <div className={styles.data}>
                        <DatePicker
                            label="active-from-current"
                            date={pack.activeFromDate}
                            isDisabled={true}
                        />
                    </div>
                </Cell>
                <Cell type="data">
                    {targetEnvName && (
                        <div className={styles.data}>
                            <DatePicker
                                label="active-from"
                                date={activeFrom ?? pack.activeFromDate}
                                isInvalid={validationErrors.activeFromDate}
                                isUpdated={updatedRows.activeFromDate}
                                onChange={(date) => onActiveFromChange(date)}
                            />
                        </div>
                    )}
                </Cell>
                <Cell type="expand" />
            </Row>
            <Row>
                <Cell type="verticalHeader">End</Cell>
                <Cell type="data">
                    <div className={styles.data}>
                        <DatePicker
                            label="active-to-current"
                            date={pack.activeToDate}
                            isDisabled={true}
                        />
                    </div>
                </Cell>
                <Cell type="data">
                    {targetEnvName && (
                        <div className={styles.data}>
                            <DatePicker
                                label="active-to"
                                date={activeTo ?? pack.activeToDate}
                                isInvalid={validationErrors.activeToDate}
                                isUpdated={updatedRows.activeToDate}
                                onChange={(date) => onActiveToChange(date)}
                            />
                        </div>
                    )}
                </Cell>
                <Cell type="expand" />
            </Row>
            {pack.childPacks.length > 0 && (
                <Row>
                    <Cell type="verticalHeader">Packs</Cell>
                    <Cell type="data">
                        <Table
                            summary={pack.childPacks.length}
                            headerRow={['Pack ID']}
                            dataRows={pack.childPacks.map((childPackId) => [
                                childPackId,
                            ])}
                            isExpanded={expandedRows.childPacks}
                        />
                    </Cell>
                    <Cell type="data">
                        {targetEnvName && (
                            <Table
                                summary={pack.childPacks.length}
                                headerRow={['Pack ID']}
                                dataRows={pack.childPacks.map((childPackId) => [
                                    childPackId,
                                ])}
                                isExpanded={expandedRows.childPacks}
                                isUpdated={updatedRows.childPacks}
                                isInvalid={validationErrors.childPacks}
                            />
                        )}
                    </Cell>
                    <Cell type="expand">
                        <Expand
                            isExpanded={expandedRows.childPacks}
                            onClick={() =>
                                setExpandedRows({
                                    ...expandedRows,
                                    childPacks: !expandedRows.childPacks,
                                })
                            }
                        />
                    </Cell>
                </Row>
            )}
            {itemProperties.length > 0 && (
                <Row>
                    <Cell type="verticalHeader">Items</Cell>
                    <Cell type="data">
                        <Table
                            summary={itemsCount}
                            headerRow={itemProperties.map(
                                (property) =>
                                    packPropertyToDisplayText[property]
                            )}
                            dataRows={[
                                itemProperties.map((property) => [
                                    // Fixme There should be a way to define itemProperties so it returns correct (number) property type
                                    pack[property] as number,
                                ]),
                            ]}
                            isExpanded={expandedRows.items}
                        />
                    </Cell>
                    <Cell type="data">
                        {targetEnvName && (
                            <Table
                                summary={itemsCount}
                                headerRow={itemProperties.map(
                                    (property) =>
                                        packPropertyToDisplayText[property]
                                )}
                                dataRows={[
                                    itemProperties.map((property) => [
                                        // Fixme There should be a way to define itemProperties so it returns correct (number) property type
                                        pack[property] as number,
                                    ]),
                                ]}
                                isExpanded={expandedRows.items}
                                isUpdated={updatedRows.items}
                            />
                        )}
                    </Cell>
                    <Cell type="expand">
                        <Expand
                            isExpanded={expandedRows.items}
                            onClick={() =>
                                setExpandedRows({
                                    ...expandedRows,
                                    items: !expandedRows.items,
                                })
                            }
                        />
                    </Cell>
                </Row>
            )}

            <Row>
                <Cell type="verticalHeader">Price ID (SKU)</Cell>
                <Cell type="data">
                    <Table
                        isExpanded={expandedRows.priceLevels}
                        summary={pack.priceLevels.length}
                        headerRow={['Product ID / SKU', 'Min', 'Max']}
                        dataRows={pack.priceLevels.map((priceLevel) => [
                            priceLevel.productId,
                            priceLevel.min,
                            priceLevel.max,
                        ])}
                    />
                </Cell>
                <Cell type="data">
                    {targetEnvName && (
                        <Table
                            isExpanded={expandedRows.priceLevels}
                            summary={pack.priceLevels.length}
                            headerRow={['Product ID / SKU', 'Min', 'Max']}
                            dataRows={pack.priceLevels.map((priceLevel) => [
                                priceLevel.productId,
                                priceLevel.min,
                                priceLevel.max,
                            ])}
                            isUpdated={updatedRows.priceLevels}
                        />
                    )}
                </Cell>
                <Cell type="expand">
                    <Expand
                        isExpanded={expandedRows.priceLevels}
                        onClick={() =>
                            setExpandedRows({
                                ...expandedRows,
                                priceLevels: !expandedRows.priceLevels,
                            })
                        }
                    />
                </Cell>
            </Row>
            <Row>
                <Cell type="verticalHeader">Media</Cell>
                <Cell type="data">
                    <Media
                        medias={pack.medias}
                        isExpanded={expandedRows.media}
                    />
                </Cell>
                <Cell type="data">
                    {targetEnvName && (
                        <Media
                            medias={pack.medias}
                            isUpdated={updatedRows.media}
                            isExpanded={expandedRows.media}
                            isMediasIncluded={isMediasIncluded}
                            onIncludeMediasToggle={onIncludeMediasToggle}
                        />
                    )}
                </Cell>
                <Cell type="expand">
                    {pack.medias.length > 0 && (
                        <Expand
                            isExpanded={expandedRows.media}
                            onClick={() =>
                                setExpandedRows({
                                    ...expandedRows,
                                    media: !expandedRows.media,
                                })
                            }
                        />
                    )}
                </Cell>
            </Row>
        </div>
    )
}

export default CopyPackPreview
