import axios from 'axios'

import { UserProfilePublic } from '../user/userTypes'

export async function getUserProfileData(): Promise<UserProfilePublic | null> {
    try {
        const {
            data: { user },
        } = await axios.get('/api/helloAuthenticated')

        return user
    } catch (err: unknown) {
        return null
    }
}
