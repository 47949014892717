import { useEffect } from 'react'
import _ from 'lodash'

import { Status, StatusType } from '../../../shared/ModalV2'
import {
    DraggablePacks,
    Loading,
    ManagePlaysets,
    Section,
} from '../../../shared'

import { usePacks } from '../../../hooks/packs'

import { moveListItem } from '../../../utils/utils'

import { MediaType } from '../../../types/enums/mediatype.enum'
import { PackType } from '../../../types/enums/packtype.enum'

import { Pack } from '../../../types/packTypes'

type ShopTabPacksProps = {
    tabPackIds: string[]
    openSections: string[]
    toggleSection: (name: string) => void
    onPacksChanged: (packIds: string[]) => void
}

export const ShopTabPacks = (props: ShopTabPacksProps) => {
    const { packs, fetch: fetchPacks, isLoading, error } = usePacks()

    const { tabPackIds, onPacksChanged } = props

    useEffect(() => {
        fetchPacks()
    }, [])

    const togglePackCheckbox = (item: Pack) => {
        if (!_.isEmpty(tabPackIds) && tabPackIds.includes(item.packId)) {
            _.pull(tabPackIds, item.packId)
        } else {
            tabPackIds.push(item.packId)
        }

        onPacksChanged(tabPackIds)
    }

    const tabItemDeleteClick = async (
        e: { stopPropagation: () => void },
        item: Pack
    ) => {
        e.stopPropagation()
        togglePackCheckbox(item)
    }

    const tabItemDragEnd = (result: {
        source: { index: number }
        destination: { index: number }
    }) => {
        if (!result.destination) {
            return
        }

        const items = moveListItem(
            tabPackIds,
            result.source.index,
            result.destination.index
        )

        onPacksChanged(items)
    }

    if (isLoading) {
        return (
            <Section>
                <Loading />
            </Section>
        )
    }

    if (error) {
        return (
            <Section>
                <Status
                    type={StatusType.Error}
                    header="Error loading packs"
                    body="Please reload the page or contact system administrator."
                />
            </Section>
        )
    }

    return (
        packs && (
            <>
                <Section
                    id="tabContentThumbnail"
                    title="Arrange packs appearance"
                    {...props}
                >
                    {tabPackIds.length === 0 && (
                        <label className="placeholder">
                            Add some packs from the section below to see
                            thumbnails here
                        </label>
                    )}
                    <DraggablePacks
                        type="horizontal"
                        onDelete={tabItemDeleteClick}
                        items={packs
                            .filter((p) => tabPackIds.includes(p.packId))
                            .toSorted((a, b) => {
                                // sort by tab packs' order
                                return (
                                    tabPackIds.indexOf(a.packId) -
                                    tabPackIds.indexOf(b.packId)
                                )
                            })}
                        thumbType={MediaType.THUMBNAIL}
                        onDragEnd={tabItemDragEnd}
                    />
                </Section>
                <Section id="tabContent" title="Tab content" {...props}>
                    <ManagePlaysets
                        items={tabPackIds}
                        title="Add packs"
                        playsets={packs.filter(
                            (p) =>
                                p.type !== PackType.STANDALONE &&
                                p.type !== PackType.PACK
                        )}
                        togglePlayset={togglePackCheckbox}
                    />
                </Section>
            </>
        )
    )
}
