import { Children, ReactNode } from 'react'
import classNames from 'classnames'

import styles from './Footer.module.scss'

type FooterProps = {
    children: ReactNode
}

export function Footer(props: FooterProps) {
    const { children } = props
    const childrenCount = Children.count(children)
    return (
        <footer
            className={classNames(
                styles.footer,
                childrenCount === 1 && styles.justifyEnd,
                childrenCount > 1 && styles.justifyBetween
            )}
        >
            {children}
        </footer>
    )
}
