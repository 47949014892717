import { handleError } from './utils.ts'

export enum StorageCacheKeys {
    PriceAutomationApple = 'PriceAutomationApple',
    PriceAutomationGoogle = 'PriceAutomationGoogle',
}

export class LocalStorageCache<T> {
    private readonly key: StorageCacheKeys
    private readonly ls: Storage = localStorage
    private readonly merger: (a: T, b: T) => T

    constructor(key: StorageCacheKeys, merger: (a: T, b: T) => T) {
        this.key = key
        this.merger = merger

        this.save()
    }

    save(newData?: T | null) {
        if (!newData) {
            return
        }

        const existingData: T | undefined = this.getSavedData()
        const data = existingData ? this.merger(existingData, newData) : newData

        this.ls.setItem(this.key, JSON.stringify(data))
    }

    getSavedData(): T | undefined {
        const dataString = this.ls.getItem(this.key)

        if (dataString) {
            try {
                return JSON.parse(dataString)
            } catch (error) {
                handleError('Could not parse saved data', error, false)
            }
        }

        return undefined
    }

    clear() {
        this.ls.removeItem(this.key)
    }
}
