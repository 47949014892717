import { languages } from '../utils/utils'

function LanguageSelector(props) {
    const { language, onChange } = props

    function changeAndRemember(e) {
        localStorage.setItem('language', e.target.value)
        onChange(e)
    }

    return (
        <select
            className="blurrable"
            value={language}
            onChange={changeAndRemember}
        >
            {Object.keys(languages).map((item, i) => {
                return (
                    <option key={'lang' + i} value={item}>
                        {languages[item]}
                    </option>
                )
            })}
        </select>
    )
}

export default LanguageSelector
