import { useState } from 'react'

const Prompt = (props) => {
    const [name, onChange] = useState(props.value || '')
    return (
        <div
            tabIndex="0"
            onKeyDown={(e) => e.key === 'Enter' && props.done(name)}
        >
            <h1>{props.heading || 'So what?'}</h1>
            {props.text && <p>{props.text}</p>}
            <div style={{ marginBottom: 15 }}>
                <input
                    placeholder={props.placeholder || ''}
                    autoFocus
                    value={name}
                    onChange={(e) => onChange(e.target.value)}
                    style={{ boxSizing: 'border-box', width: '100%' }}
                />
            </div>

            <div style={{ textAlign: 'right' }}>
                <button className="secondary" onClick={() => props.cancel()}>
                    {props.cancelLabel || 'Cancel'}
                </button>
                <button
                    className="primary"
                    style={{ marginLeft: 10 }}
                    onClick={() => props.done(name)}
                >
                    {props.okLabel || 'OK'}
                </button>
            </div>
        </div>
    )
}

export default Prompt
