import { ReactNode } from 'react'

import styles from './ModalV2.module.scss'

type ModalV2Props = {
    children: ReactNode
}

export function ModalV2(props: ModalV2Props) {
    const { children } = props
    return (
        <div className="modalBackdrop">
            <div className={styles.modal} data-testid="modal">
                {children}
            </div>
        </div>
    )
}
