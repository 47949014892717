import {
    AppEvent,
    AppEventAttributesPriority,
    AppEventAttributesPurchaseRequirement,
    AppEventLocalization,
    AppEventScreenshot,
} from '@tocaboca/app-store-connect'
import moment from 'moment-timezone'

import { appleLanguageMapping } from '../utils/utils'

import { LanguageType } from '../types/enums/languagetype.enum.ts'
import { MediaType } from '../types/enums/mediatype.enum'

import { InAppEvent } from '../types/inappeventTypes'
import { AppleMedia } from '../types/mediaTypes'

export enum AppEventDateEnum {
    PUBLISH_START = 'publishStart',
    EVENT_START = 'eventStart',
    EVENT_END = 'eventEnd',
}

export function getAppEventDate(
    appEvent: AppEvent,
    dateType: AppEventDateEnum
): string | undefined {
    const tz = 'Europe/Stockholm'
    const format = 'YYYY-MM-DDTHH:mm'
    let resultDate

    if (appEvent.attributes?.territorySchedules?.length) {
        resultDate = appEvent.attributes?.territorySchedules[0][dateType]
    }
    if (appEvent.attributes?.archivedTerritorySchedules?.length) {
        resultDate =
            appEvent.attributes?.archivedTerritorySchedules[0][dateType]
    }

    return resultDate ? moment(resultDate).tz(tz).format(format) : undefined
}

export function setAppEventDate(
    appEvent: AppEvent,
    dateType: AppEventDateEnum,
    value: string
) {
    if (!appEvent.attributes) {
        appEvent.attributes = {}
    }
    if (!appEvent.attributes.territorySchedules) {
        appEvent.attributes.territorySchedules = []
    }

    appEvent.attributes.territorySchedules[0] = {
        ...appEvent.attributes.territorySchedules[0],
        [dateType]: value,
    }
}

export enum AppEventLocalisationEnum {
    NAME = 'name',
    SHORT_DESCRIPTION = 'shortDescription',
    LONG_DESCRIPTION = 'longDescription',
}

export function getAppEventLocalisation(
    localisations: AppEventLocalization[] | undefined,
    locale: LanguageType,
    localeKey: AppEventLocalisationEnum
): string | undefined {
    return localisations?.find(
        (l) => l.attributes?.locale === appleLanguageMapping[locale]
    )?.attributes?.[localeKey]
}

export function setAppEventLocalisation(
    localisations: AppEventLocalization[],
    locale: LanguageType,
    localeKey: AppEventLocalisationEnum,
    value: string
) {
    let localisation = localisations.find(
        (l) => l.attributes?.locale === appleLanguageMapping[locale]
    )
    if (!localisation) {
        localisation = { id: '', type: 'appEventLocalizations' }
        localisations.push(localisation)
    }
    if (!localisation.attributes) {
        localisation.attributes = { locale: appleLanguageMapping[locale] }
    }
    localisation.attributes[localeKey] = value
}

export function isInAppPurchaseRequired(appEvent: AppEvent) {
    return (
        appEvent.attributes?.purchaseRequirement ===
        AppEventAttributesPurchaseRequirement.IN_APP_PURCHASE
    )
}

export function isHighPriority(appEvent: AppEvent) {
    return appEvent.attributes?.priority === AppEventAttributesPriority.HIGH
}

export function getAppEventStoreUrl(iae: InAppEvent) {
    return `https://appstoreconnect.apple.com/apps/${iae.appId}/distribution/events/${iae.appEvent.id}`
}

export function getAppEventUrl(iae: InAppEvent) {
    return iae.appEvent.id
        ? `https://apps.apple.com/app/id${iae.appId}?eventid=${iae.appEvent.id}`
        : ''
}

export function getAppEventScreenshotImageUrl(
    screenshot: AppEventScreenshot | null,
    width: number,
    height: number,
    type: 'jpeg' | 'png'
): string | undefined {
    return screenshot?.attributes?.imageAsset?.templateUrl
        ?.replace('{w}', width.toString())
        .replace('{h}', height.toString())
        .replace('{f}', type)
}

export function mapAppEventScreenshotToMedia(
    screenshot: AppEventScreenshot,
    width: number,
    height: number
): AppleMedia {
    // check if the image is portrait and swap dimensions
    if (
        screenshot.attributes?.imageAsset?.height &&
        screenshot.attributes?.imageAsset?.width &&
        screenshot.attributes?.imageAsset?.height >
            screenshot.attributes?.imageAsset?.width
    )
        [width, height] = [height, width]

    return {
        id: screenshot.id,
        type: MediaType.IAE_IMAGE,
        // e.g. https://is1-ssl.mzstatic.com/image/thumb/FF_K239lK-yLoLIu8awsNQ/{w}x{h}bb.{f}
        url:
            getAppEventScreenshotImageUrl(screenshot, width, height, 'jpeg') ||
            '',
        backgroundColor: '',
        heightRatio: 1,
        md5: '',
    }
}
