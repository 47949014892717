import { useState } from 'react'
import axios, { AxiosError } from 'axios'
import classNames from 'classnames'
import _ from 'lodash'

import ModalV2, {
    Button,
    ButtonKind,
    Footer,
    Status,
    StatusType,
} from '../shared/ModalV2'
import { Checkbox, Loading } from '../shared'

import { getPackDisplayName } from '../utils/utils'

import { Pack } from '../types/packTypes'

import styles from './AddProducts.module.scss'

type AddProductsProps = {
    profileId: string
    packsToAdd: Pack[]
    onSuccess: () => void
}
function AddProductsModal(props: AddProductsProps) {
    const { packsToAdd, profileId } = props
    const [isModalShown, setIsModalShown] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [hasAddedSuccessfully, setHasAddedSuccessfully] = useState(false)

    async function addPacks() {
        setIsLoading(true)
        const productIds = packsToAdd.map(
            (pack) => pack.priceLevels[0].productId
        )

        try {
            await axios.post(`/api/v2/profile/${profileId}/addproducts`, {
                productIds,
            })
            setHasAddedSuccessfully(true)
            setErrorMessage('')
            props.onSuccess()
        } catch (error) {
            setErrorMessage('Please try again or contact system administrator.')
            if (error instanceof AxiosError) {
                const status: number | undefined = error.response?.status
                if (status === 403) {
                    setErrorMessage(
                        `You've been logged out, please refresh page to login again.`
                    )
                } else if (status === 401) {
                    setErrorMessage(
                        `You don't have permissions to add products. Please contact system administrator.`
                    )
                }
            }
            setHasAddedSuccessfully(false)
        }
        setIsLoading(false)
    }

    const resetState = () => {
        setIsModalShown(false)
        setIsLoading(false)
        setErrorMessage('')
        setHasAddedSuccessfully(false)
    }

    const renderModalContent = () => {
        if (isLoading) {
            return <Loading />
        }
        if (errorMessage !== '') {
            return (
                <>
                    <Status
                        type={StatusType.Error}
                        header="Oops, something went wrong"
                        body={errorMessage}
                    />
                    <Footer>
                        <Button
                            kind={ButtonKind.SECONDARY}
                            onClick={resetState}
                        >
                            Close window
                        </Button>
                    </Footer>
                </>
            )
        }

        if (hasAddedSuccessfully) {
            return (
                <>
                    <Status
                        type={StatusType.Info}
                        header={'Products successfully added to profile'}
                        body="Please close this window to go back to profile's page"
                    />
                    <Footer>
                        <Button
                            kind={ButtonKind.SECONDARY}
                            onClick={resetState}
                        >
                            Close
                        </Button>
                    </Footer>
                </>
            )
        }

        const modalHeader =
            packsToAdd.length === 1
                ? 'Add 1 product'
                : `Add ${packsToAdd.length} products`

        return (
            <>
                <h1>{modalHeader}</h1>
                <div
                    className={classNames(
                        styles.greyText,
                        styles.marginBottom20
                    )}
                >
                    To Profile ID:{' '}
                    <span className={styles.notBoldText}>{profileId}</span>
                </div>
                <div className={styles.modalContent}>
                    <div>Selected products ({packsToAdd.length})</div>
                    <div className={styles.packsList}>
                        {packsToAdd.map((pack, i) => (
                            <div className={styles.pack} key={'pack_' + i}>
                                <Checkbox
                                    key={pack.packId}
                                    disabled={true}
                                    label={`${getPackDisplayName(pack)} (${
                                        pack.worth
                                    })`}
                                    highlight={false}
                                    isSearching={false}
                                    checked={true}
                                    pack={pack}
                                    onToggle={_.noop}
                                />
                            </div>
                        ))}
                    </div>
                </div>
                <Footer>
                    <p
                        className={classNames(
                            styles.paragraph,
                            styles.greyText
                        )}
                    >
                        Please note, this process can't be reversed confirm the
                        selected Profile ID and packs before continuing.
                    </p>
                    <div>
                        <Button
                            kind={ButtonKind.SECONDARY}
                            onClick={() => setIsModalShown(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            kind={ButtonKind.PRIMARY}
                            onClick={addPacks}
                            disabled={!packsToAdd.length || isLoading}
                        >
                            Add products
                        </Button>
                    </div>
                </Footer>
            </>
        )
    }

    return (
        <>
            {!isModalShown && (
                <div className={styles.actionArea}>
                    <span className={styles.greyText}>
                        {packsToAdd.length < 1
                            ? 'No products selected'
                            : packsToAdd.length === 1
                            ? '1 product selected'
                            : `${packsToAdd.length} products selected`}
                    </span>
                    <Button
                        kind={ButtonKind.PRIMARY}
                        onClick={() => setIsModalShown(true)}
                        disabled={!profileId || packsToAdd.length === 0}
                        style={{ marginLeft: 20 }}
                    >
                        Add products
                    </Button>
                </div>
            )}
            {isModalShown && <ModalV2>{renderModalContent()}</ModalV2>}
        </>
    )
}

export default AddProductsModal
