export enum PackType {
    MULTIPACK = 'multipack',
    PLAYSET = 'playset',
    HOME_DESIGNER = 'home_designer',
    OUTFIT_MAKER = 'outfit_maker',
    CHARACTER_CREATOR = 'character_creator',
    UNKNOWN = 'unknown',
    /**
     * @deprecated
     * District pack. No new are created
     */
    PACK = 'pack',
    /**
     * @deprecated
     * No new packs with this type are created
     */
    STANDALONE = 'standalone',
}

export type PackTypeUnion = `${PackType}`
