import {
    createSearchParams,
    NavLink,
    NavLinkProps,
    useSearchParams,
} from 'react-router-dom'
import classNames from 'classnames'

import styles from './Link.module.scss'

type LinkProps = {
    page: number
    isActive?: boolean
} & Omit<NavLinkProps, 'to'>

function Link(props: LinkProps) {
    const { page, isActive, className, children, ...navLinkProps } = props
    const [searchParams] = useSearchParams()
    const firstPageSearchParams = createSearchParams(searchParams)
    firstPageSearchParams.set('page', page.toString())

    return (
        <NavLink
            {...navLinkProps}
            to={{ search: firstPageSearchParams.toString() }}
            className={classNames(
                className,
                styles.link,
                isActive && styles.isActive
            )}
        >
            {children}
        </NavLink>
    )
}

export default Link
