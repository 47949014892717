import { ChangeEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
    AppEventAttributesEventState,
    AppEventAttributesPriority,
    AppEventAttributesPurchaseRequirement,
    AppEventScreenshot,
} from '@tocaboca/app-store-connect'
import axios from 'axios'
import classNames from 'classnames'
import _ from 'lodash'

import { Loading, NewRow, Section, SortIcon, Validator } from '../shared'

import { compareDates, formatDate, getDate } from '../utils/dates.ts'
import { handleError } from '../utils/utils'

import { RouteTo } from '../routes.ts'

import { InAppEvent } from '../types/inappeventTypes'

import {
    AppEventDateEnum,
    getAppEventDate,
    getAppEventScreenshotImageUrl,
    getAppEventStoreUrl,
} from './appeventfunctions'
import validate from './validate'

import widePlaceholderImg from '../assets/widethumb_placeholder.png'

const statusFilterKey = 'iaeStatusFilter'
const stateFilterKey = 'iaeStateFilter'
const priorityFilterKey = 'iaePriorityFilter'
const purchaseFilterKey = 'iaePurchaseFilter'
const searchStringKey = 'iaeSearchString'

function filterByStatus(statusFilter: string, iaes: InAppEvent[]) {
    if (statusFilter === 'all') {
        return iaes
    }
    const today = new Date()
    return iaes.filter((iae) => {
        const endDate = getDate(
            getAppEventDate(iae.appEvent, AppEventDateEnum.EVENT_END)
        )
        const startDate = getDate(
            getAppEventDate(iae.appEvent, AppEventDateEnum.EVENT_START)
        )
        if (statusFilter === 'active') {
            return endDate >= today && startDate <= today
        }

        return endDate < today || startDate > today
    })
}

function filterByState(stateFilter: string, iaes: InAppEvent[]) {
    if (stateFilter === 'all') {
        return iaes
    }
    return iaes.filter(
        (iae) => iae.appEvent.attributes?.eventState === stateFilter
    )
}

function filterByPriority(priorityFilter: string, iaes: InAppEvent[]) {
    if (priorityFilter === 'all') {
        return iaes
    }
    return iaes.filter(
        (iae) => iae.appEvent.attributes?.priority === priorityFilter
    )
}

function filterByPurchase(purchaseFilter: string, iaes: InAppEvent[]) {
    if (purchaseFilter === 'all') {
        return iaes
    }
    return iaes.filter((iae) =>
        iae.appEvent.attributes?.purchaseRequirement
            ? purchaseFilter === 'no'
                ? iae.appEvent.attributes.purchaseRequirement ===
                  AppEventAttributesPurchaseRequirement.NO_COST_ASSOCIATED
                : iae.appEvent.attributes?.purchaseRequirement !==
                  AppEventAttributesPurchaseRequirement.NO_COST_ASSOCIATED
            : false
    )
}

function filterBySearchString(
    searchString: string,
    language: string,
    iaes: InAppEvent[]
) {
    if (!searchString) {
        return iaes
    }

    const loweredSearchString = searchString.toLowerCase()
    return iaes.filter(
        (iae) =>
            iae.appEvent.attributes?.referenceName
                ?.toLowerCase()
                ?.includes(loweredSearchString) ||
            iae.localisations
                ?.find((l) => l.attributes?.locale === language)
                ?.attributes?.name?.toLowerCase()
                ?.includes(loweredSearchString)
    )
}

enum BasicComparatorsEnum {
    REFERENCE_NAME = 'referenceName',
    EVENT_STATE = 'eventState',
    PRIORITY = 'priority',
    PURCHASE_REQUIREMENT = 'purchaseRequirement',
}
const ComparatorsType = { ...BasicComparatorsEnum, ...AppEventDateEnum }
type ComparatorsType = BasicComparatorsEnum | AppEventDateEnum

function sort(
    iaes: InAppEvent[],
    sortBy: ComparatorsType | undefined,
    isSortAscend: boolean
) {
    if (!sortBy) {
        return iaes
    }
    const comparators: {
        [key in ComparatorsType]: (a: InAppEvent, b: InAppEvent) => number
    } = {
        [ComparatorsType.REFERENCE_NAME]: (a: InAppEvent, b: InAppEvent) =>
            a.appEvent.attributes?.referenceName?.localeCompare(
                b.appEvent.attributes?.referenceName || '',
                undefined,
                {
                    sensitivity: 'base',
                }
            ) || 0,
        [ComparatorsType.EVENT_STATE]: (a: InAppEvent, b: InAppEvent) =>
            (a.appEvent.attributes?.eventState || '').localeCompare(
                b.appEvent.attributes?.eventState || ''
            ),
        [ComparatorsType.PRIORITY]: (a: InAppEvent, b: InAppEvent) =>
            (a.appEvent.attributes?.priority || '').localeCompare(
                b.appEvent.attributes?.priority || ''
            ),
        [ComparatorsType.PURCHASE_REQUIREMENT]: (
            a: InAppEvent,
            b: InAppEvent
        ) =>
            (a.appEvent.attributes?.purchaseRequirement || '').localeCompare(
                b.appEvent.attributes?.purchaseRequirement || ''
            ),
        [ComparatorsType.PUBLISH_START]: (a: InAppEvent, b: InAppEvent) =>
            compareDates(
                getAppEventDate(a.appEvent, AppEventDateEnum.PUBLISH_START),
                getAppEventDate(b.appEvent, AppEventDateEnum.PUBLISH_START)
            ),
        [ComparatorsType.EVENT_START]: (a: InAppEvent, b: InAppEvent) =>
            compareDates(
                getAppEventDate(a.appEvent, AppEventDateEnum.EVENT_START),
                getAppEventDate(b.appEvent, AppEventDateEnum.EVENT_START)
            ),
        [ComparatorsType.EVENT_END]: (a: InAppEvent, b: InAppEvent) =>
            compareDates(
                getAppEventDate(a.appEvent, AppEventDateEnum.EVENT_END),
                getAppEventDate(b.appEvent, AppEventDateEnum.EVENT_END)
            ),
    }
    const sorted = iaes.slice().sort(comparators[sortBy])

    if (isSortAscend) {
        return sorted
    }
    return sorted.reverse()
}

type InAppEventsProps = {
    language: string
}

function InAppEvents(props: InAppEventsProps) {
    const [iaes, setIaes] = useState<InAppEvent[]>([])
    const [isLoading, setIsLoading] = useState(true)
    const [statusFilter, setStatusFilter] = useState(
        localStorage.getItem(statusFilterKey) ?? 'all'
    )
    const [stateFilter, setStateFilter] = useState(
        localStorage.getItem(stateFilterKey) ?? 'all'
    )
    const [priorityFilter, setPriorityFilter] = useState(
        localStorage.getItem(priorityFilterKey) ?? 'all'
    )
    const [purchaseFilter, setPurchaseFilter] = useState(
        localStorage.getItem(purchaseFilterKey) ?? 'all'
    )
    const [searchString, setSearchString] = useState(
        localStorage.getItem('iaeSearchString') ?? ''
    )
    const [isSortAscend, setIsSortAscend] = useState(false)
    const [sortBy, setSortBy] = useState<ComparatorsType>()
    const navigate = useNavigate()

    const images: Record<string, AppEventScreenshot | null> = {}
    for (const iae of iaes) {
        // Find an image to show in list
        images[iae.appEvent.id] =
            iae.screenshots && iae.screenshots.length > 0
                ? iae.screenshots[0]
                : null
    }

    async function getAllInAppEvents() {
        setIsLoading(true)
        try {
            const { data } = await axios.get<InAppEvent[]>('/api/v2/iaes')
            setIaes(data)
            setIsLoading(false)
        } catch (err) {
            handleError('Error fetching In-App Events', err)
        }
    }

    useEffect(() => {
        getAllInAppEvents()
    }, [])

    const filter: (iaes: InAppEvent[]) => InAppEvent[] = _.flow([
        (iaes) => filterBySearchString(searchString, props.language, iaes),
        (iaes) => filterByStatus(statusFilter, iaes),
        (iaes) => filterByState(stateFilter, iaes),
        (iaes) => filterByPriority(priorityFilter, iaes),
        (iaes) => filterByPurchase(purchaseFilter, iaes),
    ])

    enum FilterChangeEnum {
        STATUS = 'status',
        STATE = 'state',
        PRIORITY = 'priority',
        PURCHASE = 'purchase',
    }

    function onRadioFilterChange(
        changeType: FilterChangeEnum,
        e: ChangeEvent<HTMLInputElement>
    ) {
        const value = e.target.value
        if (changeType === FilterChangeEnum.STATUS) {
            localStorage.setItem(statusFilterKey, value)
            setStatusFilter(value)
        } else if (changeType === FilterChangeEnum.STATE) {
            localStorage.setItem(stateFilterKey, value)
            setStateFilter(value)
        } else if (changeType === FilterChangeEnum.PRIORITY) {
            localStorage.setItem(priorityFilterKey, value)
            setPriorityFilter(value)
        } else if (changeType === FilterChangeEnum.PURCHASE) {
            localStorage.setItem(purchaseFilterKey, value)
            setPurchaseFilter(value)
        }
    }

    function onSearchInputChange(e: ChangeEvent<HTMLInputElement>) {
        const value = e.target.value
        localStorage.setItem(searchStringKey, value)
        setSearchString(value)
    }

    function onClickSort(newSortBy: ComparatorsType) {
        if (sortBy === newSortBy) {
            if (isSortAscend) {
                setSortBy(undefined)
            }
            return setIsSortAscend(!isSortAscend)
        }
        setSortBy(newSortBy)
    }

    function makeRow(iae: InAppEvent) {
        const { appEvent } = iae
        const now = new Date()
        const eventStart = getAppEventDate(
                appEvent,
                AppEventDateEnum.EVENT_START
            ),
            eventEnd = getAppEventDate(appEvent, AppEventDateEnum.EVENT_END),
            publishStart = getAppEventDate(
                appEvent,
                AppEventDateEnum.PUBLISH_START
            )
        const inactive =
            !eventStart ||
            !eventEnd ||
            now < new Date(eventStart) ||
            now > new Date(eventEnd)
                ? 'inactive'
                : ''

        return (
            <tr
                onClick={() => navigate(RouteTo.InAppEventsEdit(appEvent.id))}
                key={appEvent.id}
            >
                <td>
                    <img
                        alt="image"
                        className="thumb"
                        src={
                            getAppEventScreenshotImageUrl(
                                images[appEvent.id],
                                128,
                                72,
                                'jpeg'
                            ) || widePlaceholderImg
                        }
                    />
                </td>
                <td className="alignLeft">
                    {appEvent.attributes?.referenceName}
                </td>
                <td className={classNames('alignRight', inactive)}>
                    {publishStart ? formatDate(publishStart) : 'not set'}
                </td>
                <td className={classNames('alignRight', inactive)}>
                    {eventStart ? formatDate(eventStart) : 'not set'}
                </td>
                <td className={classNames('alignRight', inactive)}>
                    {eventEnd ? formatDate(eventEnd) : 'not set'}
                </td>
                <td className={classNames('alignRight', inactive)}>
                    {_.capitalize(
                        appEvent.attributes?.eventState?.replaceAll('_', ' ')
                    ) || 'not set'}
                </td>
                <td className={classNames('alignRight', inactive)}>
                    {_.capitalize(appEvent.attributes?.priority) || 'not set'}
                </td>
                <td className={classNames('alignRight', inactive)}>
                    {appEvent.attributes?.purchaseRequirement
                        ? appEvent.attributes?.purchaseRequirement ===
                          AppEventAttributesPurchaseRequirement.NO_COST_ASSOCIATED
                            ? 'No'
                            : 'Yes'
                        : 'not set'}
                </td>
                <td className="alignRight">
                    <a
                        href={getAppEventStoreUrl(iae)}
                        rel="noreferrer"
                        target="_blank"
                    >
                        <button
                            className="small"
                            onClick={(e) => e.stopPropagation()}
                        >
                            View on App Store C.
                        </button>
                    </a>
                </td>
                <td className="alignRight validate">
                    <Validator
                        id={appEvent.id}
                        height={23}
                        validationResults={validate(iae, false)}
                    />
                </td>
            </tr>
        )
    }

    if (isLoading) return <Loading />

    const filteredIaes = filter(iaes)
    const sortedIaes = sort(filteredIaes, sortBy, isSortAscend)

    return (
        <Section>
            <div id="controlsPanel" style={{ float: 'right' }}>
                <input
                    autoFocus
                    className="editInput"
                    type="text"
                    value={searchString}
                    placeholder="Search..."
                    onChange={onSearchInputChange}
                />
            </div>

            <div style={{ display: 'inline-block' }}>
                <h1>In-App Events ({sortedIaes.length})</h1>
                <button
                    className="new withHeading"
                    onClick={() => navigate(RouteTo.InAppEventsNew())}
                >
                    Add new
                </button>
            </div>
            <NewRow />

            <div
                style={{ display: 'inline-block' }}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    onRadioFilterChange(FilterChangeEnum.STATUS, e)
                }
            >
                <fieldset>
                    <legend>Status</legend>
                    <input
                        defaultChecked={statusFilter === 'all'}
                        id="status-all"
                        data-testid="status-all"
                        name="statusFilter"
                        type="radio"
                        value="all"
                    />
                    <label htmlFor="status-all">All</label>
                    <input
                        defaultChecked={statusFilter === 'active'}
                        id="status-active"
                        name="statusFilter"
                        type="radio"
                        value="active"
                    />
                    <label htmlFor="status-active">Active</label>
                    <input
                        defaultChecked={statusFilter === 'inactive'}
                        id="status-inactive"
                        name="statusFilter"
                        type="radio"
                        value="inactive"
                    />
                    <label htmlFor="status-inactive">Inactive</label>
                </fieldset>
            </div>
            <div
                style={{ display: 'inline-block' }}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    onRadioFilterChange(FilterChangeEnum.STATE, e)
                }
            >
                <fieldset>
                    <legend>State</legend>
                    <input
                        defaultChecked={statusFilter === 'all'}
                        id="state-all"
                        data-testid="state-all"
                        name="stateFilter"
                        type="radio"
                        value="all"
                    />
                    <label htmlFor="state-all">All</label>
                    <input
                        defaultChecked={
                            statusFilter ===
                            AppEventAttributesEventState.PUBLISHED
                        }
                        id={`state-${AppEventAttributesEventState.PUBLISHED}`}
                        name="stateFilter"
                        type="radio"
                        value={AppEventAttributesEventState.PUBLISHED}
                    />
                    <label
                        htmlFor={`state-${AppEventAttributesEventState.PUBLISHED}`}
                    >
                        {_.capitalize(AppEventAttributesEventState.PUBLISHED)}
                    </label>
                    <input
                        defaultChecked={
                            statusFilter === AppEventAttributesEventState.DRAFT
                        }
                        id={`state-${AppEventAttributesEventState.DRAFT}`}
                        name="stateFilter"
                        type="radio"
                        value={AppEventAttributesEventState.DRAFT}
                    />
                    <label
                        htmlFor={`state-${AppEventAttributesEventState.DRAFT}`}
                    >
                        {_.capitalize(AppEventAttributesEventState.DRAFT)}
                    </label>
                    <input
                        defaultChecked={
                            statusFilter ===
                            AppEventAttributesEventState.APPROVED
                        }
                        id={`state-${AppEventAttributesEventState.APPROVED}`}
                        name="stateFilter"
                        type="radio"
                        value={AppEventAttributesEventState.APPROVED}
                    />
                    <label
                        htmlFor={`state-${AppEventAttributesEventState.APPROVED}`}
                    >
                        {_.capitalize(AppEventAttributesEventState.APPROVED)}
                    </label>
                </fieldset>
            </div>
            <div
                style={{ display: 'inline-block' }}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    onRadioFilterChange(FilterChangeEnum.PRIORITY, e)
                }
            >
                <fieldset>
                    <legend>Priority</legend>
                    <input
                        defaultChecked={priorityFilter === 'all'}
                        id="priority-all"
                        data-testid="priority-all"
                        name="priorityFilter"
                        type="radio"
                        value="all"
                    />
                    <label htmlFor="priority-all">All</label>
                    <input
                        defaultChecked={
                            priorityFilter === AppEventAttributesPriority.HIGH
                        }
                        id={`priority-${AppEventAttributesPriority.HIGH}`}
                        name="priorityFilter"
                        type="radio"
                        value={AppEventAttributesPriority.HIGH}
                    />
                    <label
                        htmlFor={`priority-${AppEventAttributesPriority.HIGH}`}
                    >
                        {_.capitalize(AppEventAttributesPriority.HIGH)}
                    </label>
                    <input
                        defaultChecked={
                            priorityFilter === AppEventAttributesPriority.NORMAL
                        }
                        id={`priority-${AppEventAttributesPriority.NORMAL}`}
                        name="priorityFilter"
                        type="radio"
                        value={AppEventAttributesPriority.NORMAL}
                    />
                    <label
                        htmlFor={`priority-${AppEventAttributesPriority.NORMAL}`}
                    >
                        {_.capitalize(AppEventAttributesPriority.NORMAL)}
                    </label>
                </fieldset>
            </div>
            <div
                style={{ marginBottom: '12px', display: 'inline-block' }}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    onRadioFilterChange(FilterChangeEnum.PURCHASE, e)
                }
            >
                <fieldset>
                    <legend>Is Purchase Required</legend>
                    <input
                        defaultChecked={purchaseFilter === 'all'}
                        id="purchase-all"
                        data-testid="purchase-all"
                        name="purchaseFilter"
                        type="radio"
                        value="all"
                    />
                    <label htmlFor="purchase-all">All</label>
                    <input
                        defaultChecked={purchaseFilter === 'yes'}
                        id="purchase-yes"
                        name="purchaseFilter"
                        type="radio"
                        value="yes"
                    />
                    <label htmlFor="purchase-yes">Yes</label>
                    <input
                        defaultChecked={purchaseFilter === 'no'}
                        id="purchase-no"
                        name="purchaseFilter"
                        type="radio"
                        value="no"
                    />
                    <label htmlFor="purchase-no">No</label>
                </fieldset>
            </div>

            <table style={{ width: '100%' }}>
                <thead>
                    <tr>
                        <th></th>
                        <th
                            onClick={() =>
                                onClickSort(ComparatorsType.REFERENCE_NAME)
                            }
                        >
                            <div className="sortable">
                                Reference Name
                                <SortIcon
                                    isActive={
                                        sortBy ===
                                        ComparatorsType.REFERENCE_NAME
                                    }
                                    isSortAscend={isSortAscend}
                                />
                            </div>
                        </th>
                        <th
                            onClick={() =>
                                onClickSort(ComparatorsType.PUBLISH_START)
                            }
                        >
                            <div
                                className="sortable"
                                style={{ justifyContent: 'flex-end' }}
                            >
                                Publish on
                                <SortIcon
                                    isActive={
                                        sortBy === ComparatorsType.PUBLISH_START
                                    }
                                    isSortAscend={isSortAscend}
                                />
                            </div>
                        </th>
                        <th
                            onClick={() =>
                                onClickSort(ComparatorsType.EVENT_START)
                            }
                        >
                            <div
                                className="sortable"
                                style={{ justifyContent: 'flex-end' }}
                            >
                                Start
                                <SortIcon
                                    isActive={
                                        sortBy === ComparatorsType.EVENT_START
                                    }
                                    isSortAscend={isSortAscend}
                                />
                            </div>
                        </th>
                        <th
                            onClick={() =>
                                onClickSort(ComparatorsType.EVENT_END)
                            }
                        >
                            <div
                                className="sortable"
                                style={{ justifyContent: 'flex-end' }}
                            >
                                End
                                <SortIcon
                                    isActive={
                                        sortBy === ComparatorsType.EVENT_END
                                    }
                                    isSortAscend={isSortAscend}
                                />
                            </div>
                        </th>
                        <th
                            onClick={() =>
                                onClickSort(ComparatorsType.EVENT_STATE)
                            }
                        >
                            <div
                                className="sortable"
                                style={{ justifyContent: 'flex-end' }}
                            >
                                State
                                <SortIcon
                                    isActive={
                                        sortBy === ComparatorsType.EVENT_STATE
                                    }
                                    isSortAscend={isSortAscend}
                                />
                            </div>
                        </th>
                        <th
                            onClick={() =>
                                onClickSort(ComparatorsType.PRIORITY)
                            }
                        >
                            <div
                                className="sortable"
                                style={{ justifyContent: 'flex-end' }}
                            >
                                Priority
                                <SortIcon
                                    isActive={
                                        sortBy === ComparatorsType.PRIORITY
                                    }
                                    isSortAscend={isSortAscend}
                                />
                            </div>
                        </th>
                        <th
                            onClick={() =>
                                onClickSort(
                                    ComparatorsType.PURCHASE_REQUIREMENT
                                )
                            }
                        >
                            <div
                                className="sortable"
                                style={{ justifyContent: 'flex-end' }}
                            >
                                Pay Req.?
                                <SortIcon
                                    isActive={
                                        sortBy ===
                                        ComparatorsType.PURCHASE_REQUIREMENT
                                    }
                                    isSortAscend={isSortAscend}
                                />
                            </div>
                        </th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>{sortedIaes.map((item) => makeRow(item))}</tbody>
            </table>
        </Section>
    )
}

export default InAppEvents
