import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import _ from 'lodash'

import CarouselsTable from './CarouselsTable'
import { Loading, Modal, NewRow, Section } from '../shared'

import { handleEnvAuth } from '../utils/envaccess'
import {
    fetchDataFromPath,
    postDataForPath,
    putDataForPath,
} from '../utils/envcopy'
import { handleError } from '../utils/utils'

import { RouteTo } from '../routes'

const Carousels = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [loadingMessage, setLoadingMessage] = useState('')
    const [carousels, setCarousels] = useState([])
    const navigate = useNavigate()

    const fetchData = async () => {
        setIsLoading(true)
        try {
            let response = await axios.get('/api/v2/carousels')
            setCarousels(response.data)
        } catch (e) {
            handleError('Error loading carousels', e)
        }
        setIsLoading(false)
    }

    useEffect(() => {
        fetchData()
    }, [])

    const deleteCarousel = async (e, i) => {
        e.stopPropagation()

        let isConfirmed = await Modal.confirm({
            heading: 'Delete carousel',
            text: 'Really delete this carousel?',
            okLabel: 'Yes do it!',
        })
        if (!isConfirmed) return

        let carouselsCopy = _.cloneDeep(carousels)
        let id = carouselsCopy[i].carouselId

        try {
            await axios.delete('/api/v2/carousel/' + id)
            console.log('Carousel deleted')

            carouselsCopy.splice(i, 1)
            setCarousels(carouselsCopy)
        } catch {
            handleError('Error deleting carousel', e)
        }
    }

    const prepareCarouselContent = async (carousel, env) => {
        // If carousel items exist, we need to check if the packs also exist in target env, if not remove them from this carousel before save
        if (carousel.items.length > 0) {
            console.log(
                `Current carousel has ${carousel.items.length} packs, checking which of them exist in ${env.name}...`
            )
            let foundPacks = []
            for (const item of carousel.items) {
                const fetchedData = await fetchDataFromPath(
                    `${env.basepath}/v2/pack/${item.packId}`
                )
                const fetchedPack = fetchedData.pack
                if (!_.isEmpty(fetchedPack)) {
                    foundPacks.push({ packId: fetchedPack.packId })
                }
            }
            // Show modal here of how many packs that were found
            let isConfirmed = await Modal.confirm({
                heading: 'Packs found',
                text: `${foundPacks.length} out of ${carousel.items.length} packs were found in ${env.name}, do you still want to continue?`,
                okLabel: 'Continue',
            })
            if (!isConfirmed) return false

            console.log(
                `Found ${foundPacks.length} packs, adding them to carousel...`
            )
            carousel.items = foundPacks
        } else {
            console.log('Current carousel has no packs, continuing...')
        }

        // Setting carousel as inactive
        carousel.active = false

        return carousel
    }

    const copyCarouselClick = async (e, item) => {
        e.stopPropagation()
        setIsLoading(true)
        setLoadingMessage('Copying carousel...')

        const env = await handleEnvAuth()
        if (!env) {
            setIsLoading(false)
            setLoadingMessage('')
            return
        }

        // Get and compare carousels
        const currentCarousel = await fetchDataFromPath(
            `/api/v2/carousel/${item.carouselName}`
        )
        const refCarousel = await fetchDataFromPath(
            `${env.basepath}/v2/carousel/${item.carouselName}`
        )

        if (_.isEmpty(refCarousel)) {
            // No carousel in target environment, show confirm modal
            let isConfirmed = await Modal.confirm({
                heading: 'Copy carousel',
                text: `No carousel with name '${item.carouselName}' found in ${env.name}, do you want to make a copy?`,
                okLabel: 'Yes do it!',
            })
            if (!isConfirmed) {
                setIsLoading(false)
                setLoadingMessage('')
                return
            }
        } else {
            // Carousel found, show compare modal
            const isCompareConfirmed = await Modal.compare({
                heading: 'Compare carousels',
                text: 'The following differences were found',
                okLabel: 'Copy',
                object1: refCarousel,
                object2: currentCarousel,
            })
            if (!isCompareConfirmed) {
                setIsLoading(false)
                setLoadingMessage('')
                return
            }
        }

        // Prepare carousel content before copy
        setLoadingMessage('Preparing carousel content...')
        const carousel = await prepareCarouselContent(currentCarousel, env)
        if (!carousel) {
            setIsLoading(false)
            setLoadingMessage('')
            return
        }

        // Finally, copy carousel to target environment
        setLoadingMessage(`Saving carousel to ${env.name}...`)
        const hasCopiedSuccessfully = _.isEmpty(refCarousel)
            ? await postDataForPath(
                  { data: carousel },
                  `${env.basepath}/v2/carousel`
              )
            : await putDataForPath(
                  { data: carousel },
                  `${env.basepath}/v2/carousel/${refCarousel.carouselId}`
              )

        setIsLoading(false)

        if (hasCopiedSuccessfully)
            await Modal.alert({
                heading: 'Copying done!',
                text: `Woho, the carousel with name '${currentCarousel.carouselName}' was successfully stored in ${env.name}!`,
            })
        else
            await Modal.alert({
                heading: 'On no!',
                text: `Something went wrong when trying to copy carousel with name '${currentCarousel.carouselName}' to ${env.name}!`,
            })
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list)
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)
        return result
    }

    const carouselDragEnd = (result) => {
        if (!result.destination) {
            return
        }

        let items = reorder(
            carousels,
            result.source.index,
            result.destination.index
        )

        saveCarouselOrder(items)

        setCarousels(items)
    }

    const saveCarouselOrder = async (items) => {
        let ids = items.map((c) => c.carouselId)
        console.log('New order:', ids)
        try {
            await axios.put(`/api/v2/carousels`, { carouselIds: ids })
            console.log('Carousel order saved')
        } catch (e) {
            handleError('Error updating carousel order', e)
        }
    }

    const activateCarousel = async (e, carousel) => {
        e.stopPropagation()

        const isActivatingCarousel = !carousel.active

        let isConfirmed = await Modal.confirm({
            heading: `${
                isActivatingCarousel ? 'Activate' : 'Deactivate'
            } carousel`,
            text: `${
                isActivatingCarousel ? 'Activate' : 'Deactivate'
            } carousel with name "${carousel.carouselName}"?`,
            okLabel: 'Yes do it!',
        })
        if (!isConfirmed) return

        try {
            await axios.put(
                `/api/v2/carousels/${carousel.carouselId}/toggleactive`,
                {
                    setActive: isActivatingCarousel,
                }
            )
            console.log('Saved carousel!')
            const refreshedCarousels = carousels.map((c) => {
                if (c.carouselId === carousel.carouselId) {
                    return { ...c, active: isActivatingCarousel }
                }
                return c
            })
            setCarousels(refreshedCarousels)
        } catch (e) {
            handleError('Error saving carousel', e)
        }
    }

    const redirectToCarousel = (carouselId) => {
        navigate(RouteTo.CarouselEdit(carouselId))
    }

    if (isLoading) return <Loading text={loadingMessage} />

    return (
        <Section>
            <div style={{ display: 'inline-block' }}>
                <h1>Carousels</h1>
                <button
                    className="new withHeading"
                    onClick={() => navigate(RouteTo.CarouselNew())}
                >
                    Add new
                </button>
            </div>
            <NewRow />

            <CarouselsTable
                carousels={carousels}
                onCarouselClick={redirectToCarousel}
                onCarouselDeleteClick={deleteCarousel}
                onCopyCarouselClick={copyCarouselClick}
                onCarouselDragEnd={carouselDragEnd}
                onActivateCarouselClick={activateCarousel}
            />
        </Section>
    )
}

export default Carousels
