import { Component } from 'react'
import { Tooltip } from 'react-tooltip'
import axios from 'axios'
import _ from 'lodash'

import Section from '../shared/Section'
import { LabelInput, Modal, NewRow, SavePanel, SpeechBubble } from '../shared'

import tracking from '../utils/changetracker'
import { toHTMLDate, toJSONDate } from '../utils/dates.ts'
import { handleError } from '../utils/utils'
import withRouter from '../utils/withRouter'

import { RouteTo } from '../routes'

import emptyGift from './emtpyGift'
import validate from './validate.ts'

class Gift extends Component {
    state = {
        data: emptyGift,
        isNew: false,
        hasChanged: false,
        saveStatus: 'nochanges',
        initialGiftId: null,
        loadingGoogleSheetText: '',
    }

    async componentDidMount() {
        let id = this.props.params.id
        // We have a New Gift page
        if (id === undefined) {
            this.setState({ isNew: true, loading: false }, () => {
                tracking.start(this)
            })
            return
        }

        try {
            let response = await axios.get(`/api/v2/gift/${id}`)
            this.gotData(response.data)
        } catch (e) {
            handleError('Error loading data', e)
        }
    }

    gotData(data) {
        this.preProcess(data)

        this.setState({ data, loading: false }, () => {
            tracking.start(this)
        })
    }

    preProcess = (data) => {
        if (data.releaseNumber === undefined) data.releaseNumber = ''
        data.startDate = toHTMLDate(data.startDate)
        data.endDate = toHTMLDate(data.endDate)
        this.setState({ initialGiftId: data.giftId })
    }

    deleteGift = async (e) => {
        let isConfirmed = await Modal.confirm({
            heading: 'Delete gift',
            text: 'Really delete this gift?',
            okLabel: 'Yes do it!',
        })
        if (!isConfirmed) return

        try {
            let id = this.state.data.giftId
            await axios.delete('/api/gift/' + id)
            console.log('gift deleted')
            this.props.navigate(RouteTo.Gifts())
        } catch {
            handleError('Error deleting gift', e)
        }
    }

    componentWillUnmount = () => {
        tracking.stop()
    }

    onInputChange = (e) => {
        let data = _.cloneDeep(this.state.data)
        data[e.target.name] = e.target.value
        this.setState({ data })
    }

    save = async () => {
        tracking.stop()
        this.setState({ saveStatus: 'saving' })
        let data = _.cloneDeep(this.state.data)
        data.startDate = toJSONDate(data.startDate)
        data.endDate = toJSONDate(data.endDate)
        data.requiredVersion = parseInt(data.requiredVersion)

        let id = this.state.initialGiftId || data.giftId // handle gift id change

        try {
            if (this.state.isNew) await axios.post('/api/gift', { gift: data })
            else {
                let url = '/api/gift/' + id
                await axios.put(url, { gift: data })
            }
            this.setState({ initialGiftId: data.giftId }) // overwrite initial so we can change pack id again
            tracking.restart(this)
        } catch (e) {
            handleError('Error saving gift', e)
            this.setState({ saveStatus: 'hasChanged' })
        }
    }

    loadGoogleSheetData = async (giftIds) => {
        try {
            const giftData = await axios.put(`/api/v2/importgifts`, { giftIds })

            return giftData.data
        } catch (err) {
            handleError('Error loading data from Google Sheet', err)
            console.error(err)
        }
    }

    getGoogleSheetData = () => {
        const data = _.cloneDeep(this.state.data)
        if (data.giftId === '') {
            this.setState({
                loadingGoogleSheetText: `Please add Gift id to load it's data`,
            })
            setTimeout(() => {
                this.setState({ loadingGoogleSheetText: '' })
            }, 3000)
            return
        }
        this.setState({
            loadingGoogleSheetText: 'Loading from google sheet...',
        })

        this.loadGoogleSheetData([data.giftId])
            .then((data) => {
                if (data.success === false) {
                    this.setState({
                        loadingGoogleSheetText: 'Something went wrong!',
                    })
                    return
                }

                if (_.isEmpty(data)) {
                    this.setState({
                        loadingGoogleSheetText: 'No data for this gift id',
                    })
                    return
                }

                if (data[0]) {
                    this.setState({
                        loadingGoogleSheetText: `Everything is loaded! Don't forget to save`,
                    })
                    data[0].startDate = toHTMLDate(data[0].startDate)
                    data[0].endDate = toHTMLDate(data[0].endDate)
                    this.setState({ data: data[0] })
                    setTimeout(() => {
                        this.setState({ loadingGoogleSheetText: '' })
                    }, 5000)
                }
            })
            .catch((error) => {
                handleError('Error updating gift', error)
                console.error(error)
            })
    }

    render() {
        const { data, saveStatus, isNew } = this.state
        const loadingGoogleSheetText = this.state.loadingGoogleSheetText
        const validationResults = validate(data)

        return (
            <div>
                <Section title="Edit data">
                    <LabelInput
                        object={data}
                        size="wide"
                        name="giftId"
                        label="Gift Id"
                        onChange={this.onInputChange}
                    />
                    {loadingGoogleSheetText === '' ? (
                        <div style={{ display: 'inline-block' }}>
                            <Tooltip
                                delayShow={300}
                                place="bottom"
                                id="update_button"
                            />
                            <button
                                onClick={this.getGoogleSheetData}
                                data-tooltip-content="'World Gifts Overview 2022' sheet only"
                                data-tooltip-id="update_button"
                            >
                                Load data from Google Sheet
                            </button>
                        </div>
                    ) : (
                        <SpeechBubble style={{ display: 'inline-flex' }}>
                            {loadingGoogleSheetText}
                        </SpeechBubble>
                    )}
                    <NewRow />
                    <LabelInput
                        object={data}
                        size="normal"
                        type="datetime-local"
                        name="startDate"
                        label="Starts"
                        onChange={this.onInputChange}
                    />
                    <LabelInput
                        object={data}
                        size="normal"
                        type="datetime-local"
                        name="endDate"
                        label="Ends"
                        onChange={this.onInputChange}
                    />
                    <NewRow />
                    <LabelInput
                        object={data}
                        size="normal"
                        name="requiredVersion"
                        label="Required shop version"
                        onChange={this.onInputChange}
                    />
                    <LabelInput
                        object={data}
                        size="normal"
                        name="releaseNumber"
                        label="In which release?"
                        onChange={this.onInputChange}
                    />
                </Section>

                <SavePanel
                    validationResults={validationResults}
                    item={data}
                    save={this.save}
                    showDelete={!isNew}
                    saveStatus={saveStatus}
                    onDelete={this.deleteGift}
                />
            </div>
        )
    }
}

export default withRouter(Gift)
