export enum Store {
    APPLE = 'apple',
    GOOGLE = 'google',
    AMAZON = 'amazon',
    TOCA = 'toca',
    SAMSUNG = 'samsung',
}

export enum PriceAutomationStore {
    APPLE = 'apple',
    GOOGLE = 'google',
}

export type StoreUnion = `${Store}`
