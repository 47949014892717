import { ReactNode } from 'react'

import styles from './Spoiler.module.scss'

type SpoilerProps = {
    id: string
    children: ReactNode
}

export const Spoiler = ({ id, children }: SpoilerProps) => {
    return (
        <div className={styles.spoiler}>
            <input id={id} type="checkbox" className={styles.hiddenCheckbox} />
            <div className={styles.territoriesSpoiler}>{children}</div>
        </div>
    )
}
