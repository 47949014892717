import { FormEvent, useState } from 'react'
import axios from 'axios'

import { SpeechBubble } from '../../shared'

import { toHTMLDate } from '../../utils/dates.ts'
import { CSV_UTF8_MIME_TYPE, download } from '../../utils/download.ts'
import { handleError, toCsv } from '../../utils/utils.ts'

import { PriceAutomationStore } from '../../types/enums/store.enum.ts'

import styles from './ExportActiveSKUsForm.module.scss'

type ActiveSKUsRes = {
    store: PriceAutomationStore
    products: { sku: string; price: number | null; error: string }[]
}

export const ExportActiveSKUsForm = () => {
    const [errors, setErrors] = useState<string[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setIsLoading(true)
        setErrors([])

        const data = new FormData(event.target as HTMLFormElement)

        const checkedStores = [
            PriceAutomationStore.APPLE,
            PriceAutomationStore.GOOGLE,
        ].filter((store) => data.get(store) === 'on')

        if (checkedStores.length === 0) {
            setErrors(['At least one store must be selected'])
            setIsLoading(false)
            return
        }

        try {
            await Promise.all(
                checkedStores.map(async (store) => {
                    const {
                        data: { products },
                    } = await axios.get<ActiveSKUsRes>(
                        `/api/v2/inapppurchases/export-active-skus/${store}`
                    )
                    const csv = toCsv(products)

                    if (!csv) {
                        setErrors((errors) => [
                            ...errors,
                            `Unable to generate CSV file for ${store} store`,
                        ])
                        return
                    }

                    const filename = `[${toHTMLDate(
                        new Date()
                    )}] - ${store} skus.csv`

                    download(csv, CSV_UTF8_MIME_TYPE, filename)
                })
            )
        } catch (error) {
            const msg = handleError("Couldn't download CSV", error, false)

            setErrors((errors) => [...errors, msg])
        }

        setIsLoading(false)
    }

    const getCheckboxId = (store: PriceAutomationStore): string => {
        return `export-skus-${store}`
    }

    return (
        <>
            <SpeechBubble
                style={{
                    justifyContent: 'left',
                    marginTop: 10,
                    marginBottom: 20,
                }}
            >
                Both Apple and Google USD prices are taken from Google Play.
            </SpeechBubble>
            <form onSubmit={onSubmit} className={styles.Form}>
                <div>
                    <input
                        type="checkbox"
                        id={getCheckboxId(PriceAutomationStore.APPLE)}
                        name={PriceAutomationStore.APPLE}
                    />
                    <label htmlFor={getCheckboxId(PriceAutomationStore.APPLE)}>
                        {' '}
                        Apple Store
                    </label>
                    <input
                        type="checkbox"
                        id={getCheckboxId(PriceAutomationStore.GOOGLE)}
                        name={PriceAutomationStore.GOOGLE}
                    />
                    <label htmlFor={getCheckboxId(PriceAutomationStore.GOOGLE)}>
                        {' '}
                        Google Play Store
                    </label>
                </div>

                <button type="submit" className="btn" disabled={isLoading}>
                    {isLoading ? 'Exporting...' : 'Export'}
                </button>
            </form>
            {errors?.length ? (
                <pre className={styles.ErrorMessage}>{errors.join('\n')}</pre>
            ) : null}
        </>
    )
}
