import { MouseEvent } from 'react'

import styles from './user.module.scss'
import type { UserProfilePublic } from './userTypes'

type UserProps = {
    user: UserProfilePublic
    signout: (e: MouseEvent<HTMLElement>) => void
}

export const User = ({ user, signout }: UserProps) => (
    <div className={styles.container}>
        <button type="button" className={styles.button}>
            <img
                className={styles.photo}
                src={user.photo}
                alt="User profile picture"
            />
        </button>
        <div className={styles.popup}>
            <div>{user.email}</div>
            <div>Access level: {user.roles.join(', ') || 'Read-Only'}</div>
            <input
                type="button"
                className={styles.signoutButton}
                onClick={signout}
                value="Sign out"
            />
        </div>
    </div>
)
