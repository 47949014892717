import pagingImg from '../assets/paging.png'

const wrapperStyle = {
    width: '100%',
    height: '100%',
    padding: '5%',
    boxSizing: 'border-box',
    position: 'absolute',
}

let dotStyle = {
    height: 50,
    width: 50,
    margin: 15,
    borderRadius: '50%',
    display: 'inline-block',
    marginTop: '57%',
}

function Paging({ page, setPage, pageCount }) {
    if (pageCount < 2) return <></>

    return (
        <div style={wrapperStyle}>
            <img
                src={pagingImg}
                style={{
                    position: 'absolute',
                    top: '51%',
                    left: '5%',
                    transform: 'scale(1.5)',
                    opacity: page === 0 ? 0.5 : 1,
                }}
                onClick={() => setPage(page - 1)}
            />
            <img
                src={pagingImg}
                style={{
                    position: 'absolute',
                    top: '51%',
                    right: '5%',
                    opacity: page === pageCount - 1 ? 0.5 : 1,
                    transform: 'scale(1.5) rotate(180deg)',
                }}
                onClick={() => setPage(page + 1)}
            />
            <div>
                {[...Array(pageCount)].map((item, i) => {
                    return (
                        <div
                            key={`item${i}`}
                            onClick={() => setPage(i)}
                            style={{
                                ...{
                                    background: i === page ? '#fff' : '#ccc',
                                },
                                ...dotStyle,
                            }}
                        ></div>
                    )
                })}
            </div>
        </div>
    )
}

export default Paging
