import {
    AppEventAttributesBadge,
    AppEventAttributesPriority,
    AppEventAttributesPurchaseRequirement,
    AppEventLocalization,
} from '@tocaboca/app-store-connect'
import Joi from 'joi'
import _ from 'lodash'

import { appleLanguageMapping } from '../utils/utils'

import { InAppEvent } from '../types/inappeventTypes'

function makeScheduleSchema(isNew: boolean) {
    return Joi.array().items(
        Joi.object({
            publishStart: Joi.date()
                .min(isNew ? Date.now() : new Date(Number.MIN_VALUE))
                .required(),
            eventStart: Joi.date().min(Joi.ref('publishStart')).required(),
            eventEnd: Joi.date().min(Joi.ref('eventStart')).required(),
        })
    )
}

function makeSchema(isNew: boolean, isErrors: boolean) {
    // make schema for error validations
    if (isErrors) {
        return Joi.object({
            // list of mandatory fields for error checking
            appId: isNew ? Joi.string().allow('') : Joi.string().required(),
            appEvent: Joi.object({
                id: isNew ? Joi.string().allow('') : Joi.string().required(),
                attributes: Joi.object({
                    badge: Joi.string()
                        .valid(...Object.values(AppEventAttributesBadge))
                        .required(),
                    deepLink: Joi.string().uri().required(),
                    priority: Joi.string()
                        .valid(...Object.values(AppEventAttributesPriority))
                        .required(),
                    purchaseRequirement: Joi.string()
                        .valid(
                            ...Object.values(
                                AppEventAttributesPurchaseRequirement
                            )
                        )
                        .required(),
                    referenceName: Joi.string().max(64).required(),
                    territorySchedules: makeScheduleSchema(isNew),
                    archivedTerritorySchedules: makeScheduleSchema(isNew),
                })
                    .custom((value, helpers) => {
                        const {
                            territorySchedules,
                            archivedTerritorySchedules,
                        } = value
                        if (
                            territorySchedules.length > 0 &&
                            archivedTerritorySchedules.length > 0
                        ) {
                            return helpers.message({
                                custom: "Can't have an event both archived and in progres.",
                            })
                        }
                        if (
                            territorySchedules.length === 0 &&
                            archivedTerritorySchedules.length === 0
                        ) {
                            return helpers.message({
                                custom: 'Dates are mandatory for new events.',
                            })
                        }
                        return value
                    }, 'custom validation to check if only one array is non-empty')
                    .required(),
            }),
            // list of non-mandatory fields for error checking
            localisations: Joi.array(),
            screenshots: Joi.array(),
        })
    }
    // make schema for warning validations
    return Joi.object({
        // list of non-mandatory fields for warning checking
        appId: Joi.string().allow(''),
        appEvent: Joi.object(),
        // list of mandatory fields for warning checking
        localisations: Joi.array()
            .items(
                Joi.object({
                    id: isNew
                        ? Joi.string().allow('')
                        : Joi.string().required(),
                    attributes: Joi.object({
                        locale: Joi.string()
                            .valid(...Object.values(appleLanguageMapping))
                            .required(),
                        longDescription: Joi.string().max(120).required(),
                        name: Joi.string().max(30).required(),
                        shortDescription: Joi.string().max(50).required(),
                    }).required(),
                })
            )
            .custom((values, helpers) => {
                const diff = Object.values(appleLanguageMapping).filter(
                    (appleLoc) =>
                        !values
                            ?.map(
                                (userLoc: AppEventLocalization) =>
                                    userLoc?.attributes?.locale
                            )
                            .includes(appleLoc)
                )
                if (diff.length)
                    return helpers.message({
                        custom: `"${diff}" language is missing`,
                    })

                return values
            }, 'custom validation to check all locales are present')
            .required(),
        screenshots: Joi.array()
            .items(
                Joi.object({
                    id: isNew
                        ? Joi.string().allow('')
                        : Joi.string().required(),
                    attributes: Joi.object({
                        imageAsset: Joi.object({
                            height: Joi.number().min(1).required(),
                            templateUrl: Joi.string()
                                .regex(/^https:\/\/.+$/)
                                .required(),
                            width: Joi.number().min(1).required(),
                        }).required(),
                    }).required(),
                })
            )
            .min(2)
            .required(),
    }).options({ abortEarly: false })
}

export function validate(item: InAppEvent, isNew: boolean) {
    const validationItem = _.cloneDeep(item)
    const errors = makeSchema(isNew, true).validate(validationItem, {
        allowUnknown: true,
    })
    const warnings = makeSchema(isNew, false).validate(validationItem, {
        allowUnknown: true,
    })
    if (warnings.error) {
        warnings.error.details = warnings.error.details.slice(0, 10) // return first 10 warnings to not bloat the UI
    }
    return { errors, warnings }
}

export default validate
