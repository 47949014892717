import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import _ from 'lodash'

import {
    DraggablePacks,
    LabelInput,
    Loading,
    ManagePlaysets,
    Modal,
    NewRow,
    SavePanel,
    Section,
    SpeechBubble,
} from '../shared'

import { handleError, languages, moveListItem } from '../utils/utils'

import { RouteTo } from '../routes'

import { MediaType } from '../types/enums/mediatype.enum'
import { PackType } from '../types/enums/packtype.enum'

import validate from './validate'

const Carousel = (props) => {
    const emptyCarousel = {
        carouselId: null,
        carouselName: '',
        active: false,
        displayName: {},
        priority: 0,
        items: [],
    }
    const language = props.language
    const [isLoading, setIsLoading] = useState(false)
    const [carousel, setCarousel] = useState(emptyCarousel)
    const [packs, setPacks] = useState([])
    const [saveStatus, setSaveStatus] = useState('nochanges')
    const validationResults = validate(carousel)
    const [loadGoogleSheetStateText, setLoadGoogleSheetStateText] = useState('')
    const navigate = useNavigate()
    const params = useParams()
    const id = params.id
    const isNew = id ? false : true

    const fetchData = async () => {
        setIsLoading(true)
        try {
            let packResponse = await axios.get('/api/packs')
            let carouselResponse = await axios.get('/api/v2/carousels')
            gotData(carouselResponse.data, packResponse.data.packs, id)
        } catch (e) {
            handleError('Error fetching data', e)
        }
        setIsLoading(false)
    }

    const gotData = (carousels, packs, id) => {
        if (!isNew) {
            let data = _.find(carousels, { carouselId: parseInt(id) })
            if (!data) {
                console.log(`Carousel with id ${id} was not found`)
                alert(`Carousel with id ${id} was not found`)
                return navigate(RouteTo.Carousels()) // Carousel not found
            } else {
                setCarousel(data)
            }
        }
        setPacks(packs)
    }

    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        setSaveStatus('hasChanged')
    }, [carousel])

    /**
     * That is an absolutely horrible callback, and it needs to be refactored, but for now here is my understanding of how it works:
     * There are nested objects in data structure and {child} represents the name of the field within data which needs to be changed.
     * That field could store array, so if {i} present, then it uses it as an index to the item which needs to be changed.
     * Finally, the item it recovered could have localization, so if {language} present, it uses it to pick the right value which needs to be changed.
     */
    const onInputChange = (e, child, i, language) => {
        let data = _.cloneDeep(carousel)

        // handle nested objects
        let path = child ? data[child] : data
        if (Number.isInteger(i)) path = path[i]

        // handle languages
        if (language) path[e.target.name][language] = e.target.value
        else path[e.target.name] = e.target.value

        setCarousel(data)
    }

    const togglePackCheckbox = (item) => {
        let data = _.cloneDeep(carousel)
        let selectedPacks = data.items ?? []
        let pack = _.find(selectedPacks, (p) => p.packId === item.packId)
        if (!pack) {
            selectedPacks.push({ packId: item.packId })
        } else {
            _.pull(selectedPacks, pack)
        }
        setCarousel({ ...data, items: selectedPacks })
    }

    const packThumbnailDeleteClick = (e, item) => {
        e.preventDefault()
        togglePackCheckbox(item)
    }

    const packThumbnailDragEnd = (result) => {
        if (!result.destination) return
        let data = _.cloneDeep(carousel)
        let items = moveListItem(
            data.items,
            result.source.index,
            result.destination.index
        )
        setCarousel({ ...data, items })
    }

    const loadGoogleSheetData = async () => {
        try {
            setLoadGoogleSheetStateText('Loading from google...')
            const data = _.cloneDeep(carousel)
            let message = ''
            let hasError = false

            let ret = await axios.get(
                '/api/v2/getpackfromsource/' + data.carouselName
            )

            // Everything went well, but there are no translations from Google sheet for this Category ID
            if (_.isEmpty(ret.data.name)) {
                message = 'There are no translations for this Category ID!'
                hasError = true
            }

            if (hasError === false) {
                message = `I've updated the text for you!`

                setCarousel({ ...data, displayName: ret.data.name })
            }
            setLoadGoogleSheetStateText(message)
        } catch (error) {
            setLoadGoogleSheetStateText(
                `That didn't work. Sorry! Server says: ` +
                    error.response?.data.message
            )
        }
        setTimeout(() => {
            setLoadGoogleSheetStateText('')
        }, 5000)
    }

    const deleteCarousel = async (e) => {
        e.preventDefault()
        let isConfirmed = await Modal.confirm({
            heading: 'Delete carousel',
            text: 'Really delete this carousel?',
            okLabel: 'Yes do it!',
        })
        if (!isConfirmed) return
        try {
            await axios.delete(`/api/v2/carousel/${id}`)
            console.log('Carousel deleted')
            return navigate(RouteTo.Carousels())
        } catch (e) {
            handleError('Error deleting carousel', e)
        }
    }

    const save = async () => {
        setSaveStatus('saving')
        let data = _.cloneDeep(carousel)

        try {
            if (isNew) {
                await axios.post('/api/v2/carousel', { data })
                const carouselReposonse = await axios.get(
                    `/api/v2/carousel/${data.carouselName}`
                )
                if (!carouselReposonse.data.carouselId)
                    throw new Error('Creating carousel did not return id')
                navigate(
                    RouteTo.CarouselEdit(carouselReposonse.data.carouselId)
                )
            } else {
                await axios.put(`/api/v2/carousel/${id}`, { data })
            }

            setSaveStatus('saved')
            setTimeout(() => {
                setSaveStatus('nochanges')
            }, 2000)
        } catch (e) {
            handleError('Error saving carousel', e)
            setSaveStatus('hasChanged')
        }
    }

    if (isLoading) return <Loading />

    return (
        <div>
            <Section
                title={isNew ? 'New carousel' : carousel.carouselName}
                {...props}
            >
                <LabelInput
                    object={carousel}
                    size="wide"
                    name="carouselName"
                    label="Category ID"
                    onChange={onInputChange}
                />
                <LabelInput
                    object={carousel}
                    size="wide"
                    name="displayName"
                    tooltip="Localized name"
                    language={language}
                    label={'display name in '}
                    labelHighlight={languages[language]}
                    onChange={(e) => onInputChange(e, null, null, language)}
                />
                <NewRow />
                {loadGoogleSheetStateText !== '' ? (
                    <SpeechBubble
                        style={{
                            justifyContent: 'left',
                            marginTop: 20,
                            marginBottom: 10,
                        }}
                    >
                        {loadGoogleSheetStateText}
                    </SpeechBubble>
                ) : (
                    ''
                )}
                <button onClick={loadGoogleSheetData}>
                    Load from Google Sheet
                </button>
            </Section>
            <Section
                id="carouselContentThumbnails"
                title="Arrange packs appearance"
                {...props}
            >
                {carousel.items.length === 0 && (
                    <label className="placeholder">
                        Add some packs from the section below to see thumbnails
                        here
                    </label>
                )}
                <DraggablePacks
                    type="horizontal"
                    onDelete={(e, item) => packThumbnailDeleteClick(e, item)}
                    items={packs
                        .filter((p) => {
                            return carousel.items.some((item) => {
                                return p.packId === item.packId
                            })
                        })
                        .sort((a, b) => {
                            return (
                                carousel.items.findIndex(
                                    (item) => item.packId === a.packId
                                ) -
                                carousel.items.findIndex(
                                    (item) => item.packId === b.packId
                                )
                            )
                        })}
                    thumbType={MediaType.THUMBNAIL}
                    onDragEnd={(e) => packThumbnailDragEnd(e)}
                />
            </Section>
            <Section id="carouselContent" title="Carousel content" {...props}>
                {packs.length > 0 && (
                    <ManagePlaysets
                        items={carousel?.items ?? []}
                        title="Add bundles, playsets, home designer, and character creator packs"
                        playsets={packs.filter(
                            (p) =>
                                p.type !== PackType.STANDALONE &&
                                p.type !== PackType.PACK
                        )}
                        togglePlayset={togglePackCheckbox}
                    />
                )}
            </Section>
            <SavePanel
                validationResults={validationResults}
                item={carousel}
                save={save}
                showDelete={!isNew}
                saveStatus={saveStatus}
                onDelete={deleteCarousel}
            />
        </div>
    )
}

export default Carousel
