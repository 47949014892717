import { Component } from 'react'
import axios from 'axios'
import _ from 'lodash'

import Loading from '../shared/Loading'
import { ManagePlaysets } from '../shared'

import { PackType } from '../types/enums/packtype.enum'

import { Pack } from '../types/packTypes'

type PackPlaysetsProps = {
    allPacksData: Pack[]
    childPackIds: Pack['packId'][]
    isLoading: boolean
    toggleContentCheckbox: (childPack: Pack) => void
}

type PackPlaysetsState = {
    playsets: Pack[]
}

class PackPlaysets extends Component<PackPlaysetsProps, PackPlaysetsState> {
    state: PackPlaysetsState = {
        playsets: [],
    }

    async componentDidMount() {
        try {
            // Got all packs data already from the parent component
            if (this.props.allPacksData.length > 0) {
                this.filterAllPacksData(this.props.allPacksData)
            } else {
                // Have no data about all packs, so make a request
                const response = await axios.get('/api/packs')
                this.filterAllPacksData(response.data.packs)
            }
        } catch (e) {
            console.error(e)
            alert(e)
        }
    }

    filterAllPacksData = (packs: Pack[]) => {
        // Bundle cannot contain another bundle
        const ps = packs.filter(
            (p) =>
                p.type === PackType.PLAYSET ||
                p.type === PackType.HOME_DESIGNER ||
                p.type === PackType.OUTFIT_MAKER ||
                p.type === PackType.CHARACTER_CREATOR
        )
        const playsets = _.cloneDeep(ps)
        playsets.sort(function (a, b) {
            return (a.displayName.en as string) > (b.displayName.en as string)
                ? 1
                : -1
        })

        this.setState({ playsets })
    }

    render() {
        const { playsets } = this.state
        const childPackIds = this.props.childPackIds

        if (this.props.isLoading) return <Loading />

        const selectedPlaysets = playsets.filter((x) =>
            childPackIds.includes(x.packId)
        )
        const totalWorth = selectedPlaysets.reduce(
            (acc, val) => acc + val.worth,
            0
        )

        return (
            <ManagePlaysets
                items={childPackIds}
                title={`Add packs (Selected: ${selectedPlaysets.length}, Bundle value: ${totalWorth})`}
                playsets={playsets}
                togglePlayset={this.props.toggleContentCheckbox}
            />
        )
    }
}

export { PackPlaysets }
