import { useEffect, useState } from 'react'
import axios, { isAxiosError } from 'axios'

import { getAuthStatus, signInWithGoogle } from '../utils/envaccess'

import { Environment } from '../types/environmentTypes'

export function useEnvironments() {
    const [environments, setEnvironments] = useState<Environment[]>()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>()

    async function fetchEnvironments() {
        setEnvironments(undefined)
        setIsLoading(true)
        setError(undefined)

        try {
            const { data } = await axios.get('/api/v2/env')
            setEnvironments(data)
        } catch (err) {
            if (err instanceof Error) {
                setError(err.message)
            }
            if (isAxiosError(err)) {
                setError(err.response?.data.message ?? err.message)
            }
        }

        setIsLoading(false)
    }

    useEffect(() => {
        fetchEnvironments()
    }, [])

    return { environments, isLoading, error }
}

export function useEnvironment(envName: string) {
    const {
        environments,
        isLoading: isEnvironmentsLoading,
        error: environmentsError,
    } = useEnvironments()

    let environment: Environment | undefined
    let error: string | undefined

    if (environments) {
        environment = environments.find((env) => env.name === envName)
        if (!environment) {
            error = 'Environment was not found'
        }
    }

    return {
        environment,
        isLoading: isEnvironmentsLoading,
        error: error ?? environmentsError,
    }
}

export function useAuthenticate(envName: string) {
    const {
        environment,
        isLoading: isEnvLoading,
        error: envError,
    } = useEnvironment(envName)
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | undefined>()

    async function authenticate(basepath: string) {
        setIsAuthenticated(false)
        setIsLoading(true)
        setError(undefined)

        const isAuthenticated = await getAuthStatus(basepath)
        if (isAuthenticated) {
            setIsAuthenticated(true)
            setIsLoading(false)
            return
        }

        try {
            await signInWithGoogle(basepath)
            setIsAuthenticated(true)
        } catch (err) {
            if (err instanceof Error) {
                setError(err.message)
            }
        }

        setIsLoading(false)
    }

    useEffect(() => {
        if (envName) {
            if (envName === environment?.name) {
                authenticate(environment.basepath)
            }
        }
    }, [envName, environment?.name, environment?.basepath])

    return {
        isAuthenticated,
        isLoading: isLoading ?? isEnvLoading,
        error: error ?? envError,
    }
}
