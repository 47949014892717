import Joi from 'joi'

import { LanguageType } from '../types/enums/languagetype.enum.ts'
import { MediaType } from '../types/enums/mediatype.enum'
import { PackType } from '../types/enums/packtype.enum'

import { Media } from '../types/mediaTypes'
import { Pack } from '../types/packTypes'

import { getDate } from './dates'
import { redirectToLogin } from './redirectToLogin.ts'

import { Gift } from '../gifts/giftTypes'

function filterActive(
    items: Pack[],
    active: boolean,
    startDateName: 'activeFromDate',
    endDateName: 'activeToDate'
): Pack[]
function filterActive(
    items: Gift[],
    active: boolean,
    startDateName: 'startDate',
    endDateName: 'endDate'
): Gift[]

function filterActive<T extends Pack | Gift>(
    items: T[],
    active: boolean,
    startDateName: keyof T,
    endDateName: keyof T
): T[] {
    const today = new Date()
    const filteredItems = items.filter((item) => {
        const endDate = getDate(
            item[endDateName] as string,
            'YYYY-MM-DD HH:mm:Z'
        )
        const startDate = getDate(
            item[startDateName] as string,
            'YYYY-MM-DD HH:mm:Z'
        )
        if (active) return endDate >= today && startDate <= today

        return endDate < today || startDate > today
    })

    return filteredItems
}

/**
 * Handles errors by logging the error message and optionally displaying an alert to the user.
 * If the error status is 403, redirects the user to the login page.
 * If the error status is 401, informs the user about insufficient rights.
 *
 * @param {string} message - The error message to be logged and optionally displayed.
 * @param {any} error - The error instance to be handled. Error needs to be `any` here as we use this function with a variety of props.
 * @param {boolean} [showAlert=true] - A flag indicating whether to show an alert for unrecognised errors to the user. Shows by default.
 * @returns {string} - The processed error message from the error instance. Does not include the message provided in the first argument.
 */
function handleError(
    message: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: any,
    showAlert: boolean = true
): string {
    const errorMessage: string =
        error?.response?.data?.message ??
        error?.message ??
        JSON.stringify(error)
    const status: number | undefined = error?.response?.status

    console.error(`${message}:`, errorMessage)
    if (status === 403) {
        alert('You have been logged out, redirecting to login page.')

        redirectToLogin()

        return ''
    } else if (status === 401) {
        const message =
            'The account has insufficient rights to perform this action.'
        alert(message)

        return message
    } else if (showAlert) {
        alert(`${message}:\n\n${errorMessage}`)
    }
    return errorMessage
}

function arrayMove<T>(arr: T[], oldIndex: number, newIndex: number): T[] {
    const arrayLength = arr.length

    // Adjust negative indixes
    const adjustIndex = (index: number) =>
        index < 0 ? index + arrayLength : index

    oldIndex = adjustIndex(oldIndex)
    newIndex = adjustIndex(newIndex)

    // Adjust array length if new index is greater than or equal to array length
    if (newIndex >= arrayLength) {
        arr.push(...Array(newIndex - arrayLength + 1).fill(undefined))
    }

    // Move element
    const elementToMove = arr.splice(oldIndex, 1)[0]
    arr.splice(newIndex, 0, elementToMove)

    return arr
}

function getPackDisplayName(pack: Pack): string {
    let name = 'no name'
    if (pack && pack.name) name = pack.name
    // Show display name only for playsets, homedesigner, outfit maker, and character creator packs
    if (
        pack &&
        pack.type !== PackType.MULTIPACK &&
        pack.displayName &&
        pack.displayName.en
    )
        name = pack.displayName.en
    return name
}

const languages: Record<LanguageType, string> = {
    en: 'English',
    ar: 'Arabic',
    zh: 'Chinese',
    'zh-Hant': 'Chinese traditional',
    da: 'Danish',
    nl: 'Dutch',
    fi: 'Finnish',
    fr: 'French',
    'fr-CA': 'French (Canada)',
    de: 'German',
    id: 'Indonesian',
    it: 'Italian',
    ja: 'Japanese',
    ko: 'Korean',
    nb: 'Norwegian',
    pl: 'Polish',
    'pt-BR': 'Portuguese (Brazil)',
    pt: 'Portuguese',
    ru: 'Russian',
    'es-MX': 'Spanish (Mexico)',
    es: 'Spanish',
    sv: 'Swedish',
    th: 'Thai',
    tr: 'Turkish',
}

// https://docs.fastlane.tools/actions/deliver#available-language-codes
// map CMS locale names to Apple locale names
const appleLanguageMapping: Record<LanguageType, string> = {
    en: 'en-US',
    zh: 'zh-Hans',
    'zh-Hant': 'zh-Hant',
    fr: 'fr-FR',
    de: 'de-DE',
    sv: 'sv',
    es: 'es-ES',
    da: 'da',
    nb: 'no',
    ja: 'ja',
    it: 'it',
    'es-MX': 'es-MX',
    ru: 'ru',
    nl: 'nl-NL',
    'fr-CA': 'fr-CA',
    pt: 'pt-PT',
    'pt-BR': 'pt-BR',
    ko: 'ko',
    fi: 'fi',
    id: 'id',
    th: 'th',
    tr: 'tr',
    pl: 'pl',
    ar: 'ar-SA',
}

// Fixme move it to a function or something
// Fixme Remove partial as we iterate though all languages
const displayNameLanguagesSchema: Partial<
    Record<LanguageType, Joi.StringSchema>
> = {}
const descriptionLanguagesSchema: Partial<
    Record<LanguageType, Joi.StringSchema>
> = {}
// Fixme Replace for..in with something safer
for (const l in languages) {
    displayNameLanguagesSchema[l as LanguageType] = Joi.string()
        .required()
        .messages({
            'string.base': `"${l}" for display name must be a type of string`,
            'string.empty': `"${l}" for display name is missing`,
            'any.required': `"${l}" for display name is missing`,
        })

    descriptionLanguagesSchema[l as LanguageType] = Joi.string()
        .strict()
        .trim()
        .required()
        .messages({
            'string.base': `"${l}" description must be a type of string`,
            'string.empty': `"${l}" description is missing`,
            'string.trim': `"${l}" description must not have leading or trailing whitespace`,
            'any.required': `"${l}" description is missing`,
        })
}

const platforms = {
    google: 'Google',
    apple: 'Apple',
    amazon: 'Amazon',
}

const blockedVersionTypes = {
    store: 'shop',
    app: 'app',
}

function isPackInactive(pack: Pack): boolean {
    const from = new Date(pack.activeFromDate)
    const to = new Date(pack.activeToDate)
    const now = new Date()
    return now < from || now > to
}

function moveListItem<T>(
    list: Iterable<T>,
    fromIndex: number,
    toIndex: number
): T[] {
    const result = Array.from(list)
    const [removed] = result.splice(fromIndex, 1)
    result.splice(toIndex, 0, removed)

    return result
}

function getPackThumbnail(pack: Pack): Media | undefined {
    return pack.medias.find(
        (media: Media) => media.type === MediaType.THUMBNAIL
    )
}

function getMediaSchema(mediaType: MediaType) {
    return Joi.object({
        id: Joi.string(),
        type: Joi.string().valid(mediaType),
        url: Joi.string().uri(),
        backgroundColor: Joi.string().allow(null, ''),
        heightRatio: Joi.number(),
        md5: Joi.string().allow(null, ''),
    })
}

export function toCsv<T extends Record<string, unknown>>(
    data: T[],
    separator = ','
): string | null {
    if (!data || !Array.isArray(data) || !data.length) {
        return null
    }

    const keys = Object.keys(data[0])

    let csv = `${keys.join(separator)}\n`

    for (const row of data) {
        // Object.values doesn't guarantee the order, so only requesting values by array of known keys
        const values = keys.map((key: string) => String(row[key]))

        csv += `${values.join(separator)}\n`
    }

    return csv
}

export {
    filterActive,
    handleError,
    languages,
    appleLanguageMapping,
    displayNameLanguagesSchema,
    descriptionLanguagesSchema,
    arrayMove,
    getPackDisplayName,
    isPackInactive,
    platforms,
    blockedVersionTypes,
    moveListItem,
    getPackThumbnail,
    getMediaSchema,
}
