import { ChangeEvent, MouseEvent, useEffect, useMemo, useState } from 'react'
import axios from 'axios'
import _, { isNumber } from 'lodash'

import Loading from '../shared/Loading'
import { SaveStatus } from '../shared/SavePanel'
import ChildPacksMedia from './ChildPacksMedia.tsx'
import { PackContent, PossibleContentFields } from './Pack/PackContent.tsx'
import {
    PackDescription,
    PossibleDescriptionFields,
} from './Pack/PackDescription.tsx'
import PackMembership from './Pack/PackMembership'
import { PackOverview, PossibleOverviewFields } from './Pack/PackOverview'
import { PackPriceId } from './Pack/PackPriceId/PackPriceId.tsx'
import { PackScreenshotsWrapper } from './Pack/PackScreenshotsWrapper'
import { PackTypeSelector } from './Pack/PackTypeSelector'
import { PackPlaysets } from './PackPlaysets'
import { Modal, SavePanel, Section } from '../shared'

import {
    IapExportStatus,
    PackDescriptionType,
    useCreatePack,
    useIapExport,
    usePack,
    usePacks,
    useUpdatePack,
} from '../hooks/packs.ts'

import { toHTMLDate, toJSONDate } from '../utils/dates.ts'
import { ImageFileUploading, VideoFileUploading } from '../utils/upload'
import { getPackDisplayName, handleError } from '../utils/utils'
import withRouter, { WithRouterProps } from '../utils/withRouter'

import { RouteTo } from '../routes.ts'

import { LanguageType } from '../types/enums/languagetype.enum.ts'
import { MediaType } from '../types/enums/mediatype.enum'
import { PackType } from '../types/enums/packtype.enum'

import { Media } from '../types/mediaTypes'
import { Pack as PackT, PackUpdate, PriceLevel } from '../types/packTypes'

import { emptyPack } from './emptyPack.ts'
import validate from './validate'

import lpppIcon from '../assets/lppp-logo.png'

type PackProps = {
    language: LanguageType
    // Could be moved to context
    openSections?: string[]
    toggleSection?: (name: string) => void
} & WithRouterProps

type MediaOrImageFileUploading = Media | (ImageFileUploading & { id?: number })
type MediaOrVideoFileUploading = Media | (VideoFileUploading & { id?: number })

type MediaState = {
    screenshots: MediaOrImageFileUploading[]
    videos: MediaOrVideoFileUploading[]
    thumbnails: MediaOrImageFileUploading[]
    widethumbnails: MediaOrImageFileUploading[]
    tabthumbnails: MediaOrImageFileUploading[]
    iapimages: MediaOrImageFileUploading[]
    scrollingscreenshots: MediaOrImageFileUploading[]
}

const mapMediasToIds = (medias: MediaState): PackUpdate['medias'] => {
    const mediaIds = _.flatten([
        medias.screenshots,
        medias.videos,
        medias.thumbnails,
        medias.widethumbnails,
        medias.scrollingscreenshots,
        medias.tabthumbnails,
        medias.iapimages,
    ]).map(
        // Validation should prevent the cases when the id is undefined
        (media) => media.id as number
    )

    return mediaIds
}

export function Pack(props: PackProps) {
    const packId = props.params.id
    const {
        pack: fetchedPack,
        fetch: fetchPack,
        isLoading: isPackLoading,
        error: packError,
    } = usePack(packId || '')
    const {
        packs: allPacks = [],
        fetch: fetchPacks,
        isLoading: isPacksLoading,
        error: packsError,
    } = usePacks()
    const {
        createPack,
        isLoading: isCreatePackLoading,
        pack: createdPack,
        error: createPackError,
    } = useCreatePack()
    const {
        updatePack,
        isLoading: isUpdatePackLoading,
        pack: updatedPack,
        error: updatePackError,
    } = useUpdatePack()
    const {
        status: exportIapStatus,
        exportIap,
        results: exportIapResults,
        error: exportIapError,
    } = useIapExport()

    const [pack, setPack] = useState<PackT>(emptyPack)
    const [media, setMedia] = useState<MediaState>({
        // all media lists moved here to transform to hooks easier as we are using dynamic state names :)
        screenshots: [],
        videos: [],
        thumbnails: [],
        widethumbnails: [],
        scrollingscreenshots: [],
        tabthumbnails: [],
        iapimages: [],
    })
    const [initialPack, setInitialPack] = useState<PackT | null>(null)
    const [initialMedia, setInitialMedia] = useState<PackUpdate['medias']>([])
    const [isSavedStatusShown, setIsSavedStatusShown] = useState<boolean>(false)

    const hasUnsavedChanges = useMemo(() => {
        return (
            !_.isEqual(mapMediasToIds(media), initialMedia) ||
            !_.isEqual(pack, initialPack)
        )
    }, [pack, initialPack, media, initialMedia])

    const isLoading = isPackLoading || isPacksLoading

    useEffect(() => {
        ;(async () => {
            if (pack.type === PackType.MULTIPACK) {
                await fetchPacks()
            }

            if (initialPack?.type !== pack.type) {
                resetPackContent(pack.type)
            }
        })()
    }, [pack.type])

    useEffect(() => {
        setHasUnsavedChanges(hasUnsavedChanges)
    }, [hasUnsavedChanges])

    useEffect(() => {
        // Executed on the component mount
        if (packId) {
            fetchPack()
        }

        return () => {
            // Executed on the component unmount
            setHasUnsavedChanges(false)
        }
    }, [])

    useEffect(() => {
        if (packError) {
            handleError('Unable to load pack', packError)
        }

        if (packsError) {
            handleError('Unable to load all packs', packsError)
        }
    }, [packError, packsError])

    useEffect(() => {
        if (createdPack) {
            props.navigate(RouteTo.PackEdit(createdPack.packId))

            const { pack, media } = processCurrentPackData(createdPack)

            setInitialData(pack, media)
        }
    }, [createdPack])

    useEffect(() => {
        if (updatedPack) {
            const { pack, media } = processCurrentPackData(updatedPack)

            setInitialData(pack, media)
        }
    }, [updatedPack])

    useEffect(() => {
        if (fetchedPack) {
            const { pack, media } = processCurrentPackData(fetchedPack)

            setPack(pack)
            setMedia(media)

            setInitialData(pack, media)
        }
    }, [fetchedPack])

    useEffect(() => {
        if (createPackError || updatePackError) {
            handleError(
                'Unable to save changes',
                isNew() ? createPackError : updatePackError
            )
        }
    }, [createPackError, updatePackError])

    useEffect(() => {
        if (isSavedStatusShown) {
            setTimeout(() => {
                setIsSavedStatusShown(false)
            }, 2000)
        }
    }, [isSavedStatusShown])

    useEffect(() => {
        if (
            exportIapStatus === IapExportStatus.Exported ||
            exportIapStatus === IapExportStatus.PartiallyExported
        ) {
            ;(async () => {
                const heading =
                    exportIapStatus === IapExportStatus.Exported
                        ? '✅ The following IAPs were created'
                        : '❌ Not all IAPs were created'

                await Modal.list({
                    heading,
                    items: exportIapResults,
                })
            })()
        } else if (exportIapStatus === IapExportStatus.Failed) {
            handleError(
                'Failed to send data to the Apple store',
                exportIapError
            )
        }
    }, [exportIapStatus, exportIapResults])

    const getSaveStatus = (): SaveStatus => {
        if (isCreatePackLoading || isUpdatePackLoading) {
            return SaveStatus.SAVING
        }

        if (hasUnsavedChanges) {
            return SaveStatus.HAS_CHANGED
        }

        return SaveStatus.NO_CHANGES
    }

    const setInitialData = (pack: PackT, media: MediaState) => {
        setInitialPack(pack)
        setInitialMedia(mapMediasToIds(media))

        setHasUnsavedChanges(false)
    }

    const setHasUnsavedChanges = (hasUnsavedChanges: boolean) => {
        localStorage.setItem('hasUnsavedChanges', String(hasUnsavedChanges))
    }

    const processCurrentPackData = (pack: PackT) => {
        const screenshots = _.filter(pack.medias, {
            type: MediaType.SCREENSHOT,
        })
        const videos = _.filter(pack.medias, { type: MediaType.VIDEO })
        const thumbnails = _.filter(pack.medias, {
            type: MediaType.THUMBNAIL,
        })
        const widethumbnails = _.filter(pack.medias, {
            type: MediaType.WIDE_THUMBNAIL,
        })
        const scrollingscreenshots = _.filter(pack.medias, {
            type: MediaType.SCROLLING_SCREENSHOT,
        })
        const tabthumbnails = _.filter(pack.medias, {
            type: MediaType.TAB_THUMBNAIL,
        })
        const iapimages = _.filter(pack.medias, {
            type: MediaType.IAP_IMAGE,
        })

        return {
            pack: {
                ...pack,
                medias: [],
                // todo Set defaults on server side
                displayName: pack.displayName || {},
                type: pack.type || PackType.UNKNOWN,
                childPacks: pack.childPacks || [],
                scenes: pack.scenes || [],
                content: pack.content || [],
                features: pack.features || {},
                locations: pack.locations || 0,
                characters: pack.characters || 0,
                activeFromDate: toHTMLDate(pack.activeFromDate),
                activeToDate: toHTMLDate(pack.activeToDate),
            },
            media: {
                screenshots,
                videos,
                thumbnails,
                widethumbnails,
                scrollingscreenshots,
                tabthumbnails,
                iapimages,
            },
        }
    }

    const isNew = () => {
        return !props.params.id
    }

    const onInputChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        language?: LanguageType | null
    ) => {
        const name = e.target.name as
            | PossibleContentFields
            | PossibleDescriptionFields
            | PossibleOverviewFields
        const value: string | number = e.target.value
        const data = _.cloneDeep(pack)

        if (
            language &&
            (name === PossibleDescriptionFields.DisplayName ||
                name === PossibleDescriptionFields.Features)
        ) {
            data[name][language] = value
        } else if (isNumber(data[name])) {
            ;(data[name] as number) = value ? Number(value) : NaN
        } else {
            ;(data[name] as string) = value
        }

        setPack(data)
    }

    const toggleContentCheckbox = (childPack: PackT) => {
        const childPackIds: string[] = _.cloneDeep(pack.childPacks)
        const childPacksData = getChildPacksData({
            parentPack: pack,
            allPacks: allPacks,
        })
        let screenshots = _.cloneDeep(media.screenshots)

        // Check if a child pack already exists in the parent's content
        const uncheckedChild = _.find(childPacksData, (item) => {
            return item.packId === childPack.packId
        })

        if (!uncheckedChild) {
            // Child pack was added to bundle's content
            childPackIds.unshift(childPack.packId)
        } else {
            // Remove child pack from the state as it was deselected
            _.pull(childPackIds, uncheckedChild.packId)

            // Remove child's media from the state
            _.forEach(uncheckedChild.medias, (media) => {
                // Delete child's media from parent's screenshots
                screenshots = screenshots.filter((item) => item.id !== media.id)
            })
        }

        setMedia((prevMedia) => ({ ...prevMedia, screenshots }))
        setPack((prevPack) => ({ ...prevPack, childPacks: childPackIds }))
    }

    const toggleScreenshot = (mediaId: Media['id']) => {
        const screenshots = _.cloneDeep(media.screenshots)

        const screenshot = screenshots.find((media) => media.id === mediaId)
        if (screenshot) {
            _.pull(screenshots, screenshot)
        } else {
            const childPacksData = getChildPacksData({
                parentPack: pack,
                allPacks: allPacks,
            })
            _.forEach(childPacksData, (childPack: PackT) => {
                const mediaInTheChild = childPack.medias.find(
                    (childMedia: Media) => childMedia.id === mediaId
                )

                if (mediaInTheChild) {
                    screenshots.push(mediaInTheChild)
                    return
                }
            })
        }

        setMedia((prevMedia) => ({ ...prevMedia, screenshots }))
    }

    const trimNameAndIdValues = (pack: PackT): PackT => {
        if (pack.name) pack.name = pack.name.trim()
        if (pack.packId) pack.packId = pack.packId.trim()

        return pack
    }

    const onGoogleSheetDataLoaded = ({
        displayName,
        features,
    }: PackDescriptionType) => {
        setPack((prevPack) => ({ ...prevPack, displayName, features }))
    }

    const getPackUpdateBody = (): PackUpdate => {
        return {
            ...trimNameAndIdValues(_.cloneDeep(pack)),
            activeFromDate: toJSONDate(pack.activeFromDate),
            activeToDate: toJSONDate(pack.activeToDate),
            medias: mapMediasToIds(media),
        }
    }

    const save = async () => {
        setHasUnsavedChanges(false)

        const body = getPackUpdateBody()

        isNew() ? await createPack(body) : await updatePack(body)

        setIsSavedStatusShown(true)
    }

    const onScenesChange = (scenes: PackT['scenes']) => {
        setPack((prevPack) => ({ ...prevPack, scenes }))
    }

    const onContentChange = (content: PackT['content']) => {
        setPack((prevPack) => ({ ...prevPack, content }))
    }

    const onPriceLevelsChange = (priceLevels: PriceLevel[]) => {
        setPack((prevPack) => ({ ...prevPack, priceLevels }))
    }

    const onMediaChanged = (
        type: MediaType,
        items: (MediaOrImageFileUploading | MediaOrVideoFileUploading)[]
    ) => {
        setMedia((prevMedia) => ({ ...prevMedia, [type + 's']: items }))
    }

    const deletePack = async (e: MouseEvent<HTMLButtonElement>) => {
        const isConfirmed = await Modal.confirm({
            heading: 'Delete pack',
            text: 'Really delete this pack?',
            okLabel: 'Yes do it!',
        })

        if (!isConfirmed) return

        try {
            await axios.delete('/api/pack/' + packId)

            props.navigate(RouteTo.Packs())
        } catch {
            handleError('Error deleting pack', e)
        }
    }

    const resetPackContent = (type: PackType) => {
        const data = _.cloneDeep(pack)

        data.outfits = 0
        data.accessories = 0
        data.hairStyles = 0
        data.furniture = 0
        data.templates = 0
        data.surfaceDesigns = 0
        data.garments = 0
        data.patterns = 0
        data.stickers = 0
        data.characters = 0
        data.locations = 0
        data.childPacks = []
        data.content = []
        data.scenes = []
        data.worth = type === PackType.MULTIPACK ? 0 : 1

        setPack(data)
    }

    const showIAPExportModal = async () => {
        if (hasUnsavedChanges) {
            await Modal.alert({
                heading: '⚠️ Unsaved changes',
                text: 'You must save all changes before exporting to the store fronts!',
            })
            return
        }
        if (pack.priceLevels.some((priceLevel) => !priceLevel.price)) {
            await Modal.alert({
                heading: '❌ Missing prices',
                text: 'Not all SKUs have defined prices!',
            })
            return
        }
        const childPacksData = getChildPacksData({
            parentPack: pack,
            allPacks: allPacks,
        })
        const validationResults = validate(getPackUpdateBody(), childPacksData)
        if (validationResults.errors.error) {
            await Modal.alert({
                heading: '⚠️ Errors in pack',
                text: 'Fix all errors in the pack before exporting to the store fronts!',
            })
            return
        }
        if (
            validationResults.warnings?.error?.details?.find((warn) =>
                warn.path.includes('en')
            )
        ) {
            await Modal.alert({
                heading: '⚠️ Errors in English data',
                text: 'Please fix English display name and description before exporting to the store fronts!',
            })
            return
        }
        const isConfirmed = await Modal.confirm({
            heading: 'Export to Apple store',
            text: 'This will export all data from this pack to App Store Connect as In-App Purchases. Do you want to continue?',
        })
        if (!isConfirmed) return

        await exportIap(pack.packId)
    }

    const getChildPacksData = _.memoize(
        ({
            parentPack,
            allPacks,
        }: {
            parentPack: PackT
            allPacks: PackT[]
        }): PackT[] => {
            return allPacks.filter((pack: PackT) =>
                parentPack.childPacks.includes(pack.packId)
            )
        }
    )

    const renderChildPacksMedia = () => {
        if (pack.type !== PackType.MULTIPACK) {
            return null
        }

        let selectedMediasCount = 0
        const childPacksData = getChildPacksData({
            parentPack: pack,
            allPacks: allPacks,
        })
        media.screenshots.map((parentScreenshot) => {
            _.forEach(childPacksData, (pack) => {
                const mediaExistsInTheChild = pack.medias.find(
                    (childMedia: Media) => childMedia.id === parentScreenshot.id
                )
                if (mediaExistsInTheChild) {
                    selectedMediasCount++
                }
            })
        })

        const sectionTitle = `Select media from child packs ${
            selectedMediasCount > 0
                ? ' (' + selectedMediasCount + ' total)'
                : ''
        }`
        return (
            <Section
                id="childPacksMedia"
                title={sectionTitle}
                titleIcon={lpppIcon}
                openSections={props.openSections}
                toggleSection={props.toggleSection}
            >
                {pack.childPacks.length > 0 && (
                    <ChildPacksMedia
                        isLoading={isLoading}
                        childPacksData={childPacksData}
                        parentScreenshots={media.screenshots}
                        toggleSelectedMedia={toggleScreenshot}
                    />
                )}
                {_.isEmpty(pack.childPacks) && (
                    <div>Packs from bundle's content will appear here</div>
                )}
            </Section>
        )
    }

    if (isLoading) return <Loading />

    const isBundle = [
        PackType.MULTIPACK,
        PackType.PACK,
        PackType.STANDALONE,
    ].includes(pack.type)
    const childPacksData = getChildPacksData({
        parentPack: pack,
        allPacks: allPacks,
    })
    const validationResults = validate(getPackUpdateBody(), childPacksData)

    return (
        <>
            <Section
                id="packOverview"
                title={
                    isNew()
                        ? `New pack: ${pack.name}`
                        : `Edit pack: ${getPackDisplayName(pack)}`
                }
                openSections={props.openSections}
                toggleSection={props.toggleSection}
            >
                <PackTypeSelector
                    packType={pack.type}
                    onTypeChange={onInputChange}
                />
                <div style={{ width: '100%', height: '50px' }}></div>
                <PackOverview
                    pack={pack}
                    isNew={isNew()}
                    onInputChange={(e) => onInputChange(e)}
                    onExportToAppleStoreClick={showIAPExportModal}
                    exportIapStatus={exportIapStatus}
                />
            </Section>

            {packId && (
                <Section
                    id="packMembership"
                    title={'Pack membership'}
                    openSections={props.openSections}
                    toggleSection={props.toggleSection}
                >
                    <PackMembership packId={packId} />
                </Section>
            )}

            <Section
                id="packContent"
                title="Pack content"
                openSections={props.openSections}
                toggleSection={props.toggleSection}
            >
                {isBundle && (
                    <PackPlaysets
                        isLoading={isLoading}
                        childPackIds={pack.childPacks}
                        toggleContentCheckbox={toggleContentCheckbox}
                        allPacksData={allPacks}
                    />
                )}

                <PackContent
                    pack={pack}
                    onScenesChange={onScenesChange}
                    onContentChange={onContentChange}
                    onInputChange={onInputChange}
                />
            </Section>

            <Section
                id="packDescription"
                title="Display name and description"
                openSections={props.openSections}
                toggleSection={props.toggleSection}
            >
                <PackDescription
                    language={props.language}
                    pack={pack}
                    onGoogleSheetDataLoaded={onGoogleSheetDataLoaded}
                    onInputChange={(e) => onInputChange(e, props.language)}
                />
            </Section>

            <Section
                id="priceId"
                title="Set price ID"
                openSections={props.openSections}
                toggleSection={props.toggleSection}
            >
                <PackPriceId
                    pack={pack}
                    onPriceLevelsChange={onPriceLevelsChange}
                />
            </Section>

            {renderChildPacksMedia()}

            <Section
                id="packImages"
                style={{ marginBottom: 50 }}
                title={
                    isBundle
                        ? 'Arrange & Upload bundle media'
                        : 'Images and videos'
                }
                openSections={props.openSections}
                toggleSection={props.toggleSection}
            >
                <PackScreenshotsWrapper
                    pack={pack}
                    media={media}
                    childPacksData={childPacksData}
                    onMediaChanged={onMediaChanged}
                    toggleScreenshot={toggleScreenshot}
                />
            </Section>

            <SavePanel
                title={'Working with: ' + getPackDisplayName(pack)}
                validationResults={validationResults}
                item={pack}
                save={save}
                showDelete={!isNew()}
                saveStatus={
                    isSavedStatusShown ? SaveStatus.SAVED : getSaveStatus()
                }
                onDelete={deletePack}
            />
        </>
    )
}

export default withRouter(Pack)
