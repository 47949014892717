import { useState } from 'react'

import { SpeechBubble } from '../../shared'

const Select = (props) => {
    const [selected, onChange] = useState('')
    const { items, hint, inputName } = props
    return (
        <div>
            <h1>{props.heading || 'Really do this?'}</h1>
            {items.map((item, i) => {
                const { label, value, disabled } = item
                return (
                    <div
                        key={'modalitem' + i}
                        style={{
                            padding: '5px 0',
                            opacity: disabled ? 0.5 : 1,
                        }}
                    >
                        <label>
                            <input
                                type="radio"
                                disabled={disabled}
                                value={value}
                                name={inputName || 'type'}
                                checked={selected === value}
                                onChange={(e) => onChange(e.target.value)}
                            />
                            {label}
                        </label>
                    </div>
                )
            })}

            {hint && (
                <SpeechBubble
                    style={{
                        marginTop: 30,
                        marginBottom: 30,
                        justifyContent: 'left',
                    }}
                >
                    {hint}
                </SpeechBubble>
            )}
            <div style={{ textAlign: 'right', marginTop: 30 }}>
                <button className="secondary" onClick={() => props.cancel()}>
                    {props.cancelLabel || 'Cancel'}
                </button>
                <button
                    disabled={!selected}
                    className="primary"
                    style={{ marginLeft: 10 }}
                    onClick={() => props.done(selected)}
                >
                    {props.okLabel || 'Yes'}
                </button>
            </div>
        </div>
    )
}

export default Select
