import { useEffect, useState } from 'react'
import { flatten, isNumber, sortBy, unionBy } from 'lodash'

import { Spoiler } from '../../shared/Spoiler/Spoiler.tsx'
import { ProductRow } from '../ProductRow/ProductRow.tsx'
import { Loading, Modal } from '../../shared'

import {
    usePriceUpdater,
    useSkuPricesFromGoogleSheet,
} from '../../hooks/pricesAutomation.ts'

import {
    LocalStorageCache,
    StorageCacheKeys,
} from '../../utils/localStorage.ts'
import { handleError } from '../../utils/utils.ts'

import { ProductUpdateStatus } from '../../types/enums/priceUpdateStatus.enum.ts'
import { PriceAutomationStore } from '../../types/enums/store.enum.ts'

import { Price, ProductWithStatus } from '../../types/priceAutomation'

import styles from './PriceAutomation.module.scss'

// Move it to AWS PS, but it requires the endpoint which would return it from the backend
const UpdateBatchSize = 10

type ProductsInternalCache = {
    product: ProductWithStatus
    comparisonKey: string
    index: number
}

const mergePrices = (savedPrices: Price[], newPrices: Price[]): Price[] => {
    return sortBy(unionBy(newPrices, savedPrices, 'territoryId'), 'territoryId')
}

const createComparisonKey = (product: ProductWithStatus) => {
    const prices = sortBy(product.prices, 'territoryId').map(
        ({ territoryId, price }) => `${territoryId}/${price}`
    )

    return `${product.productId}/${prices.join('/')}`
}

/**
 * This function is used to merge new data from the Google Spreadsheet with the data from previous runs stored in LocalStorage
 * @param savedProducts
 * @param newProducts
 */
const mergeProducts = (
    savedProducts: ProductWithStatus[],
    newProducts: ProductWithStatus[]
): ProductWithStatus[] => {
    const internalCache = new Map<string, ProductsInternalCache>(
        savedProducts.map((product, index) => [
            product.productId,
            {
                product,
                index,
                comparisonKey: createComparisonKey(product),
            },
        ])
    )

    const mergedProducts = [...savedProducts]

    // Products with these statuses should be shown after page reload
    const preservedStatuses = [
        ProductUpdateStatus.Updated,
        ProductUpdateStatus.Error,
    ]

    for (const product of newProducts) {
        const savedDataForId = internalCache.get(product.productId)

        if (savedDataForId && isNumber(savedDataForId.index)) {
            // The record with the same productId is in storage already

            if (
                // The saved record contains the same territories and prices
                savedDataForId.comparisonKey === createComparisonKey(product) &&
                // The new status should not overwrite the saved one
                preservedStatuses.includes(savedDataForId.product.status) &&
                !preservedStatuses.includes(product.status)
            ) {
                continue
            }

            // The new product has different territories or prices
            const mergedPrices = mergePrices(
                savedDataForId.product.prices,
                product.prices
            )

            mergedProducts.splice(savedDataForId.index, 1, {
                productId: product.productId,
                prices: mergedPrices,
                status: product.status,
                error: product.error,
            })
        } else {
            // The record with the same productId does not exist in the storage

            mergedProducts.push(product)
        }
    }

    return mergedProducts
}

const StoreToStorageKeyMap: Record<PriceAutomationStore, StorageCacheKeys> = {
    [PriceAutomationStore.APPLE]: StorageCacheKeys.PriceAutomationApple,
    [PriceAutomationStore.GOOGLE]: StorageCacheKeys.PriceAutomationGoogle,
}

type PriceAutomationPageProps = {
    store: PriceAutomationStore
}

const PriceAutomation = (props: PriceAutomationPageProps) => {
    const { store } = props
    const storage = new LocalStorageCache<ProductWithStatus[]>(
        StoreToStorageKeyMap[store],
        mergeProducts
    )
    const { productsToUpdate, fetchProducts, isLoading, error } =
        useSkuPricesFromGoogleSheet(store)
    const { productsUpdateState, updateProductsPrices, isUpdatingPrices } =
        usePriceUpdater(store)
    const [products, setProducts] = useState<ProductWithStatus[]>(
        storage.getSavedData() || []
    )
    const [selectedProducts, setSelectedProducts] = useState<
        Map<string, number>
    >(new Map())

    const askForCachedProductsUsageDecision = (): Promise<boolean> => {
        const cachedProducts = storage.getSavedData()

        if (!cachedProducts?.length) {
            return Promise.resolve(false)
        }

        const uniqueTerritories = new Set(
            flatten(cachedProducts.map((product) => product.prices)).map(
                (price) => price.territoryId
            )
        )

        const attemptedUpdatesResults = cachedProducts.reduce(
            (memo: { errors: number; updated: number }, p) => {
                if (p.status === ProductUpdateStatus.Error) {
                    memo.errors++
                } else if (p.status === ProductUpdateStatus.Updated) {
                    memo.updated++
                }

                return memo
            },
            { errors: 0, updated: 0 }
        )

        const spoilerId = `showTerritoriesSpoiler_${store}`

        return Modal.confirm({
            heading: 'Cached data found!',
            text: (
                <div className={styles.cacheDecisionModalText}>
                    There is some cached data from the previous run:
                    <ul>
                        <li>{cachedProducts.length} SKUs</li>
                        <li>
                            {uniqueTerritories.size} territories (
                            <label
                                htmlFor={spoilerId}
                                className={styles.showMoreLabel}
                            >
                                show all
                            </label>
                            )
                        </li>
                        <Spoiler id={spoilerId}>
                            {Array.from(uniqueTerritories).join(', ')}
                        </Spoiler>
                        <li>
                            {attemptedUpdatesResults.updated} updated /{' '}
                            {attemptedUpdatesResults.errors} errors
                        </li>
                    </ul>
                </div>
            ),
            cancelLabel: 'Clear cache',
            okLabel: 'Keep cache',
        })
    }

    useEffect(() => {
        fetchProducts()
    }, [])

    useEffect(() => {
        if (!productsToUpdate.length) {
            return
        }

        ;(async () => {
            const isKeepPressed = await askForCachedProductsUsageDecision()

            if (isKeepPressed) {
                // Merge the freshly fetched products with the ones from the previous time
                setProducts(mergeProducts(products, productsToUpdate))
                return
            }

            storage.clear()
            setProducts(productsToUpdate)
        })()
    }, [productsToUpdate])

    useEffect(() => {
        if (!products) {
            return
        }

        const nextProducts = [...products]

        for (const productUpdate of productsUpdateState) {
            const index = selectedProducts.get(productUpdate.productId)

            if (!isNumber(index)) {
                continue
            }

            nextProducts.splice(index, 1, productUpdate)

            if (productUpdate.status === ProductUpdateStatus.Updated) {
                selectedProducts.delete(productUpdate.productId)

                setSelectedProducts(new Map(selectedProducts))
            }
        }

        storage.save(nextProducts)
        setProducts(nextProducts)
    }, [productsUpdateState])

    const onSelectNextClicked = () => {
        if (!products?.length) {
            return
        }

        const nextProducts: [string, number][] = []
        const AllowedStatuses = [
            ProductUpdateStatus.Error,
            ProductUpdateStatus.NotStarted,
        ]

        for (const [index, product] of products.entries()) {
            if (AllowedStatuses.includes(product.status)) {
                nextProducts.push([product.productId, index])
            }

            if (nextProducts.length >= UpdateBatchSize) {
                break
            }
        }

        setSelectedProducts(new Map(nextProducts))
    }

    const onDeselectAllClicked = () => {
        setSelectedProducts(new Map())
    }

    const onCheckboxClick = (id: string) => {
        if (selectedProducts.has(id)) {
            selectedProducts.delete(id)
        } else {
            selectedProducts.set(
                id,
                products.findIndex((p) => p.productId === id)
            )
        }

        setSelectedProducts(new Map(selectedProducts))
    }

    const onUpdateSelectedClick = () => {
        if (isUpdatingPrices) {
            return
        }

        updateProductsPrices(
            Array.from(selectedProducts).map(([, productIndex]) => {
                return products[productIndex]
            })
        )
    }

    if (isLoading) {
        return <Loading style={{ textAlign: 'center' }} />
    }

    if (error) {
        return (
            <div className={styles.skuUpdateSection}>
                {handleError('Error loading products', error, false)}
            </div>
        )
    }

    if (!products?.length) {
        return <div className={styles.skuUpdateSection}>Nothing to update</div>
    }

    const updatedCount = products.filter(
        (p) => p.status === ProductUpdateStatus.Updated
    ).length

    return (
        <div className={styles.skuUpdateSection}>
            <header className={styles.header}>
                <div>{`${updatedCount} updated / ${selectedProducts.size} selected / ${products.length} total`}</div>
                <div className={styles.selectButtons}>
                    <button
                        type="button"
                        disabled={isUpdatingPrices}
                        onClick={onDeselectAllClicked}
                    >
                        Deselect all
                    </button>

                    <button
                        type="button"
                        disabled={isUpdatingPrices}
                        onClick={onSelectNextClicked}
                    >{`Select next ${UpdateBatchSize}`}</button>
                </div>
            </header>
            <div className={styles.products}>
                {products.map((product) => (
                    <ProductRow
                        key={product.productId}
                        product={product}
                        isSelected={selectedProducts.has(product.productId)}
                        isUpdating={isUpdatingPrices}
                        status={product.status}
                        error={product.error}
                        onCheckboxClick={onCheckboxClick}
                    />
                ))}
            </div>
            <div className={styles.footer}>
                <button
                    type="button"
                    onClick={onUpdateSelectedClick}
                    disabled={isUpdatingPrices}
                >
                    Update selected
                </button>
            </div>
        </div>
    )
}

export default PriceAutomation
