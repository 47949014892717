import axios from 'axios'
import _ from 'lodash'

import { Modal } from '../shared'

import { getActiveEnvironment } from '../utils/envaccess'
import { handleError } from './utils'

import { maxImageFileSize, maxVideoFileSize } from '../settings'

import { MediaType } from '../types/enums/mediatype.enum'

export const FILE_NAME_SUFFIX_BY_MEDIA_TYPE = {
    [MediaType.TAB_ICON]: 'icon',
    [MediaType.TAB_BACKGROUND]: 'bg',
}

async function uploadImages(files, type, onCompleteCallback, optionalData) {
    console.log('upload files:', type, files)
    if (files.length === 0) return console.log('All uploaded!')

    const data = new FormData()
    let file = files.pop()

    data.append('file', file) // attach the file to multipart
    // handle custom file name, eg include packId in for pack stuff

    let url = '/api/files/photo/' + type
    if (optionalData && optionalData.fileName)
        url += '/' + optionalData.fileName + '_' + new Date().getTime()
    console.log('uploading to', url)

    try {
        let res = await axios.post(url, data)
        console.log('Upload OK - Got new media id', res.data.uploaded.id)
        onCompleteCallback(type, file, res, optionalData)

        // This timeout allows images server to process the previous images (rescaling etc)
        // before handling next when uploading multiple images.
        setTimeout(() => {
            uploadImages(files, type, onCompleteCallback, optionalData)
        }, 5000)
    } catch (e) {
        handleError('Error uploading media', e)
    }
}
async function onSelectPhoto(
    event,
    type,
    onPhotosSelectedCallback,
    onCompleteCallback,
    optionalData
) {
    var sliced = _.toArray(event.target ? event.target.files : event.files)
    if (event.target) event.target.value = null
    // create an object for every file
    const filesList = sliced.map((image) => ({
        file: image,
        url: URL.createObjectURL(image),
        type: type,
        uploading: true,
    }))
    let f = filesList[0]

    if (f.file.size > maxImageFileSize) {
        let isConfirmed = await Modal.confirm({
            heading: 'Warning',
            text: "That's a big file you've got there! It's over 1MB 😱 Sure you want to upload it? ",
        })
        if (!isConfirmed) return
    }

    let image = new Image()
    image.onload = async function () {
        if (optionalData && optionalData.expectedSize) {
            let s = optionalData.expectedSize
            if (
                // With this type we check only height of the media
                type === MediaType.SCROLLING_SCREENSHOT &&
                this.height !== s.h
            ) {
                await Modal.alert({
                    heading: 'Computer says no',
                    text:
                        'This file has incorrect dimensions. Height should be ' +
                        s.h,
                })
                return
            } else if (
                // Most of the media types go here with a simple width and height check
                type !== MediaType.WIDE_THUMBNAIL &&
                type !== MediaType.THUMBNAIL &&
                !_.isArray(s) &&
                (this.width !== s.w || this.height !== s.h)
            ) {
                await Modal.alert({
                    heading: 'Computer says no',
                    text:
                        'This file has incorrect dimensions. Should be ' +
                        s.w +
                        '×' +
                        s.h +
                        '.',
                })
                return
            } else if (
                // We have array of possible dimentions we need to check for wide thumbnail and square thumbnail
                (type === MediaType.WIDE_THUMBNAIL ||
                    type === MediaType.THUMBNAIL) &&
                _.isArray(s) &&
                !s.filter((x) => this.width === x.w && this.height === x.h)
                    .length
            ) {
                await Modal.alert({
                    heading: 'Computer says no',
                    text: `This file has incorrect dimensions. Should be ${s.map(
                        (x) => ` ${x.w}x${x.h} `
                    )}`.replace(/,/g, '|'),
                })
                return
            }
        }
        onPhotosSelectedCallback(type, filesList, optionalData)
        uploadImages(sliced, type, onCompleteCallback, optionalData)
    }
    image.src = f.url
}

async function uploadVideo(file, onCompleteCallback) {
    console.log('upload video:', file)

    const data = new FormData()

    data.append('file', file)

    let url = '/api/files/video/'
    console.log('uploading to', url)

    try {
        let res = await axios.post(url, data)
        console.log('Upload OK - Got new media id', res.data.uploaded[0].id)
        onCompleteCallback(file, res)
    } catch (e) {
        handleError('Error uploading media', e)
    }
}

async function onSelectVideo(event, onVideoSelected, onCompleteCallback) {
    var sliced = _.toArray(event.target ? event.target.files : event.files)
    if (event.target) event.target.value = null

    // var sliced = _.toArray(event.target.files)
    // event.target.value = null

    // create an object for every file
    const filesList = sliced.map((image) => ({
        file: image,
        url: URL.createObjectURL(image),
        uploading: true,
    }))
    for (let f of filesList) {
        if (f.file.size < maxVideoFileSize) continue
        let isConfirmed = await Modal.confirm({
            heading: 'Warning',
            text: "That's a big file you've got there! It's over 40MB 😱 Sure you want to upload it? ",
        })
        if (!isConfirmed) return
    }

    onVideoSelected(filesList[0])
    uploadVideo(filesList[0].file, onCompleteCallback)
}

async function copyMedias(medias, resourceId, targetEnv, onCompleteCallback) {
    const newMedias = []
    const activeEnv = await getActiveEnvironment()
    for (const media of medias) {
        try {
            const newMedia =
                media.type === MediaType.VIDEO
                    ? await copyVideo(media, activeEnv, targetEnv)
                    : await copyImage(media, resourceId, activeEnv, targetEnv)
            newMedias.push(newMedia)
            onCompleteCallback(newMedia, true)
        } catch (e) {
            handleError('Error uploading media', e)
            onCompleteCallback(e, false)
        }
    }

    return newMedias
}

async function copyImage(image, resourceId, activeEnv, targetEnv) {
    // Get image directly from S3 bucket and not through CloudFront
    // eslint-disable-next-line no-useless-escape
    const baseurl = image.url.match(/^https?:\/\/[^#?\/]+/)[0]
    const getImageUrl = image.url
        .replace(baseurl, activeEnv.bucket)
        .replace('{0}', '@3x')

    const response = await axios.get(getImageUrl, { responseType: 'blob' })
    const blob = response.data

    const fileName = `${resourceId}_${
        FILE_NAME_SUFFIX_BY_MEDIA_TYPE[image.type] || image.type
    }`
    const file = new File([blob], `${fileName}.png`, {
        type: 'image/png',
        lastModified: Date.now(),
        lastModifiedDate: new Date(),
    })
    const data = new FormData()
    data.append('file', file)
    const postImageUrl = `${targetEnv.basepath}/files/photo/${image.type}/${fileName}`
    console.log('Uploading to', postImageUrl)

    const now = new Date().getTime()
    let res = await axios.post(postImageUrl, data)
    console.log(
        `Upload OK - Got new media id ${res.data.uploaded.id}, elapsed time: ${
            new Date().getTime() - now
        }`
    )

    return { mediaId: res.data.uploaded.id, media: image }
}

async function copyVideo(video, activeEnv, targetEnv) {
    // Get video directly from S3 bucket and not through CloudFront
    // eslint-disable-next-line no-useless-escape
    const baseurl = video.url.match(/^https?:\/\/[^#?\/]+/)[0]
    const getVideoUrl = video.url.replace(baseurl, activeEnv.bucket)

    const response = await axios.get(getVideoUrl, { responseType: 'blob' })
    const blob = response.data

    const path = getVideoUrl.split('/')
    const fileNameWithTimestamp = path[path.length - 1]
    const fileName = fileNameWithTimestamp.substring(
        fileNameWithTimestamp.indexOf('-') + 1
    )
    const file = new File([blob], fileName, {
        type: `video/${fileName.substring(fileName.indexOf('.') + 1)}`,
        lastModified: Date.now(),
        lastModifiedDate: new Date(),
    })
    const formData = new FormData()
    formData.append('file', file)
    const postVideoUrl = `${targetEnv.basepath}/files/video`

    console.log('Uploading to', postVideoUrl)

    const now = new Date().getTime()
    let res = await axios.post(postVideoUrl, formData)
    console.log(
        `Upload OK - Got new media id ${
            res.data.uploaded[0].id
        }, elapsed time: ${new Date().getTime() - now}`
    )

    return { mediaId: res.data.uploaded[0].id, media: video }
}

export { onSelectPhoto, onSelectVideo, copyMedias }
