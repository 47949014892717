import { ComponentType } from 'react'
import {
    NavigateFunction,
    Params,
    useNavigate,
    useParams,
} from 'react-router-dom'

export type WithRouterProps = {
    navigate: NavigateFunction
    params: Readonly<Params<string>>
}

function withRouter<T>(Component: ComponentType<T>) {
    function ComponentWithRouterProps(props: Omit<T, 'navigate' | 'params'>) {
        const routerProps = {
            navigate: useNavigate(),
            params: useParams(),
        }

        return <Component {...(props as T)} {...routerProps} />
    }

    return ComponentWithRouterProps
}

export default withRouter
