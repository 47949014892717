import Section from '../shared/Section.tsx'
import { ExportActiveSKUsForm } from './ExportActiveSKUsForm/ExportActiveSKUsForm.tsx'

export const ExportActiveSKUsPage = () => {
    return (
        <Section title={'Export Active SKUs'}>
            <ExportActiveSKUsForm />
        </Section>
    )
}
