import dateFormat from 'dateformat'
import moment from 'moment'

type DateProp = number | string | Date | undefined

function formatDate(date: DateProp): string {
    const d = getDate(date, 'YYYY-MM-DD HH:mm:Z')
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    } //  for formatting the date string output
    return d.toLocaleDateString('sv-SV', options)
}

function getDate(date?: DateProp, format = ''): Date {
    if (!date || date === '') {
        return moment().toDate()
    } else {
        const newDate = moment(date, format).toDate()
        return newDate
    }
}

function toJSONDate(date: DateProp): string {
    if (!date) {
        return ''
    } else {
        const formattedDate = getDate(date)
        const dateString = dateFormat(formattedDate, 'yyyy-mm-dd HH:MM:sso')
        return dateString
    }
}

function toHTMLDate(date: DateProp): string {
    if (!date) {
        return ''
    } else {
        const formattedDate = getDate(date)
        const dateString = dateFormat(formattedDate, "yyyy-mm-dd'T'HH:MM")
        return dateString
    }
}

function compareDates(a: DateProp, b: DateProp): number {
    if (!a && !b) return 0
    if (!a) return -1
    if (!b) return 1
    return (
        getDate(a, 'YYYY-MM-DD HH:mm:Z').getTime() -
        getDate(b, 'YYYY-MM-DD HH:mm:Z').getTime()
    )
}

export { formatDate, getDate, toHTMLDate, toJSONDate, compareDates }
