import { Component } from 'react'
import { ListManager } from 'react-beautiful-dnd-grid'
import ReactDOM from 'react-dom'
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd'
import _ from 'lodash'

import { formatDate } from '../utils/dates'
import { isPackInactive } from '../utils/utils'

import thumbPlaceholderImg from '../assets/thumb_placeholder.png'
import wideThumbPlaceholderImg from '../assets/widethumb_placeholder.png'

var css = {
    packId: '',
    packDragHandle: '',
    packThumb: '',
    packButtons: '',
    row: {
        width: '',
        height: '',
    },
}

const getItemStyle = (draggableStyle) => ({
    width: css.row.width,
    height: css.row.height,
    ...draggableStyle,
})

const InactiveDisclaimer = ({ activeToDate, activeFromDate }) => {
    const now = Date.now()
    const expiringOn = new Date(activeToDate)
    const inactiveUntil = new Date(activeFromDate)

    return (
        <div className="inactiveDisclaimer">
            {now > expiringOn.getTime() ? (
                <>
                    Expired on{' '}
                    <time dateTime={activeToDate}>
                        {formatDate(expiringOn)}
                    </time>
                </>
            ) : (
                <>
                    Inactive until{' '}
                    <time dateTime={activeFromDate}>
                        {formatDate(inactiveUntil)}
                    </time>
                </>
            )}
        </div>
    )
}

class DraggablePacks extends Component {
    onBeforeDragStart = () => {
        css.packThumb = window.getComputedStyle(
            ReactDOM.findDOMNode(this.packThumb)
        ).width
        css.packId = window.getComputedStyle(
            ReactDOM.findDOMNode(this.packId)
        ).width
        css.packButtons = window.getComputedStyle(
            ReactDOM.findDOMNode(this.packButtons)
        ).width
        css.packDragHandle = window.getComputedStyle(
            ReactDOM.findDOMNode(this.packDragHandle)
        ).width
        css.row.width = window.getComputedStyle(
            ReactDOM.findDOMNode(this.rowElement)
        ).width
        css.row.height = window.getComputedStyle(
            ReactDOM.findDOMNode(this.rowElement)
        ).height
    }

    getTdStyle = (isDragging, name) => ({
        width: isDragging && css[name],
        backgroundColor: isDragging && '#EFF8FB',
    })

    render() {
        const props = this.props
        const { onDragEnd, items, object, type } = props

        if (type === 'horizontal') {
            // Horizontal list with thumbs
            return (
                <ListManager
                    items={items}
                    direction="horizontal"
                    maxItems={5}
                    render={(item) => {
                        item = object ? item[object] : item
                        const isInactive = isPackInactive(item)
                        const thumb = _.find(item.medias, (m) => {
                            return m.type === props.thumbType
                        })

                        return (
                            <div
                                className={[
                                    'pack',
                                    isInactive ? 'inactive' : '',
                                ]
                                    .join(' ')
                                    .trim()}
                                style={{
                                    userSelect: 'none',
                                    margin: '0 20px 20px 0',
                                }}
                            >
                                <div className="deletable">
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={`/pack/${item.packId}`}
                                    >
                                        <img
                                            src={
                                                thumb
                                                    ? thumb.url.replace(
                                                          '{0}',
                                                          '@1x'
                                                      )
                                                    : props.thumbType ===
                                                      'thumbnail'
                                                    ? thumbPlaceholderImg
                                                    : wideThumbPlaceholderImg
                                            }
                                            alt={'id:' + item.packId}
                                        />
                                        <div className="wrap">
                                            {item.packId}
                                        </div>
                                    </a>
                                    {isInactive && (
                                        <InactiveDisclaimer
                                            activeToDate={item.activeToDate}
                                            activeFromDate={item.activeFromDate}
                                        />
                                    )}
                                    {props.onDelete && (
                                        <button
                                            className="deleteIcon"
                                            onClick={(e) => {
                                                props.onDelete(e, item)
                                            }}
                                        ></button>
                                    )}
                                </div>
                            </div>
                        )
                    }}
                    onDragEnd={(sourceIndex, destinationIndex) =>
                        onDragEnd({
                            source: { index: sourceIndex },
                            destination: { index: destinationIndex },
                        })
                    }
                />
            )
        } else {
            // Vertical list
            return (
                <table style={{ width: '100%' }}>
                    <DragDropContext
                        onBeforeDragStart={this.onBeforeDragStart}
                        onDragEnd={onDragEnd}
                    >
                        <Droppable droppableId="droppable">
                            {(provided) => (
                                <tbody ref={provided.innerRef}>
                                    {items.map((item, i) => {
                                        const isInactive = isPackInactive(item)
                                        const thumb = _.find(
                                            item.medias,
                                            (m) => {
                                                return (
                                                    m.type === props.thumbType
                                                )
                                            }
                                        )
                                        return (
                                            <Draggable
                                                key={`pack_draggable_${i}`}
                                                draggableId={item.packId}
                                                index={i}
                                            >
                                                {(
                                                    dragProvided,
                                                    dragSnapshot
                                                ) => (
                                                    <tr
                                                        key={
                                                            'tr_pack_draggablerow_id' +
                                                            item.packId
                                                        }
                                                        className={[
                                                            'draggablePack',
                                                            isInactive
                                                                ? 'inactive'
                                                                : '',
                                                        ]
                                                            .join(' ')
                                                            .trim()}
                                                        ref={(element) => {
                                                            this.rowElement =
                                                                element
                                                            dragProvided.innerRef(
                                                                element
                                                            )
                                                        }}
                                                        style={getItemStyle(
                                                            dragProvided.draggableStyle
                                                        )}
                                                        {...dragProvided.draggableProps}
                                                    >
                                                        <td
                                                            width={50}
                                                            ref={(ref) =>
                                                                (this.packDragHandle =
                                                                    ref)
                                                            }
                                                            style={this.getTdStyle(
                                                                dragSnapshot.isDragging,
                                                                'packDragHandle'
                                                            )}
                                                            className="alignLeft dragHandle"
                                                            key={
                                                                'ts_handle_' +
                                                                item.packId
                                                            }
                                                            {...dragProvided.dragHandleProps}
                                                        >
                                                            ≡
                                                        </td>
                                                        <td
                                                            width={
                                                                props.thumbType ===
                                                                'thumbnail'
                                                                    ? 50
                                                                    : 75
                                                            }
                                                            ref={(ref) =>
                                                                (this.packThumb =
                                                                    ref)
                                                            }
                                                            style={this.getTdStyle(
                                                                dragSnapshot.isDragging,
                                                                'packThumb'
                                                            )}
                                                        >
                                                            <img
                                                                alt="packThumb"
                                                                className="thumb"
                                                                src={
                                                                    thumb
                                                                        ? thumb.url.replace(
                                                                              '{0}',
                                                                              '@1x'
                                                                          )
                                                                        : props.thumbType ===
                                                                          'thumbnail'
                                                                        ? thumbPlaceholderImg
                                                                        : wideThumbPlaceholderImg
                                                                }
                                                            />
                                                        </td>
                                                        <td
                                                            ref={(ref) =>
                                                                (this.packId =
                                                                    ref)
                                                            }
                                                            className={
                                                                'alignLeft'
                                                            }
                                                            style={this.getTdStyle(
                                                                dragSnapshot.isDragging,
                                                                'packId'
                                                            )}
                                                        >
                                                            {item.packId}
                                                            {isInactive && (
                                                                <InactiveDisclaimer
                                                                    activeToDate={
                                                                        item.activeToDate
                                                                    }
                                                                    activeFromDate={
                                                                        item.activeFromDate
                                                                    }
                                                                />
                                                            )}
                                                        </td>
                                                        <td
                                                            ref={(ref) =>
                                                                (this.packButtons =
                                                                    ref)
                                                            }
                                                            className={
                                                                'alignRight'
                                                            }
                                                            style={this.getTdStyle(
                                                                dragSnapshot.isDragging,
                                                                'packButtons'
                                                            )}
                                                            key={
                                                                'td_pack_buttons_' +
                                                                item.packId
                                                            }
                                                        >
                                                            <button
                                                                style={{
                                                                    margin: '0 3px',
                                                                }}
                                                                className="danger small"
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    props.onDelete(
                                                                        e,
                                                                        item
                                                                    )
                                                                }}
                                                            >
                                                                Delete
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )}
                                            </Draggable>
                                        )
                                    })}
                                    {provided.placeholder}
                                </tbody>
                            )}
                        </Droppable>
                    </DragDropContext>
                </table>
            )
        }
    }
}

export default DraggablePacks
