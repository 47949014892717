import { PackType } from '../types/enums/packtype.enum.ts'

import { Pack } from '../types/packTypes'

const emptyPack: Pack = {
    packId: '',
    name: '',
    worth: 1,
    medias: [],
    activeFromDate: '1970-01-01T00:00:00.000Z', //'2020-01-01 00:00:00+00:00',
    activeToDate: '1970-01-01T00:00:00.000Z', //'2021-01-01 00:00:00+00:00',
    priceLevels: [],
    requiredVersion: 1,
    priority: 1,
    // new stuff:
    displayName: {},
    characters: 0,
    locations: 0,
    templates: 0,
    furniture: 0,
    surfaceDesigns: 0,
    garments: 0,
    patterns: 0,
    stickers: 0,
    outfits: 0,
    accessories: 0,
    hairStyles: 0,
    type: PackType.UNKNOWN, // multipack, pack, playset,
    childPacks: [],
    scenes: [], // if type playset; contains scence id:s,
    content: [],
    features: {},
}

const emptyPriceLevel = {
    productId: '',
    min: -1,
    max: -1,
    price: null,
    platformData: [
        {
            store: 'amazon',
            sku: '',
            active: true,
            deprecatedSkus: [],
        },
        {
            store: 'apple',
            sku: '',
            active: true,
            deprecatedSkus: [],
        },
        {
            store: 'google',
            sku: '',
            active: true,
            deprecatedSkus: [],
        },
        {
            store: 'samsung',
            sku: '',
            active: true,
            deprecatedSkus: [],
        },
    ],
}

export { emptyPack, emptyPriceLevel }
