import { Section, SpeechBubble } from '../shared'

import { onSelectPhoto } from '../utils/upload'
import { getPackDisplayName } from '../utils/utils'

import { mapDimensions } from '../settings'

import savingIcon from '../assets/saving.png'

function BackgroundPhotos(props) {
    const {
        onBackgroundSelected,
        onBackgroundUploaded,
        district,
        onDone,
        isCreating,
        packData,
        onCancel,
    } = props

    const isBackgroundChosen = Boolean(district.background?.mediaId)

    return (
        <Section
            title={getPackDisplayName(packData) + ' backgrounds'}
            style={{ textAlign: 'center', paddingBottom: 50 }}
        >
            {onCancel && (
                <div
                    style={{
                        display: 'block',
                        textAlign: 'right',
                        padding: '0 20px',
                    }}
                >
                    <button className="secondary" onClick={() => onCancel()}>
                        Cancel
                    </button>
                </div>
            )}
            {isCreating && (
                <div>
                    <h1>{getPackDisplayName(packData)}</h1>
                </div>
            )}

            <BackgroundPhotoSelector
                district={district}
                onPhotosSelected={onBackgroundSelected}
                onPhotosUploaded={onBackgroundUploaded}
            />

            {isCreating && (
                <SpeechBubble style={{ marginTop: 50, marginBottom: 20 }}>
                    {isBackgroundChosen
                        ? "Beautiful, you're are good to go!"
                        : 'Upload a background image to continue.'}
                </SpeechBubble>
            )}

            {isCreating && isBackgroundChosen && (
                <button
                    style={{ marginTop: 30 }}
                    className="positive"
                    onClick={() => onDone()}
                >
                    Create!
                </button>
            )}
        </Section>
    )
}

function BackgroundPhotoSelector(props) {
    const {
        district: { background, packId },
        onPhotosSelected,
        onPhotosUploaded,
    } = props
    const fileName = 'mapbg_' + (packId ?? '')

    return (
        <div
            style={{
                display: 'inline-block',
                margin: 20,
                position: 'relative',
            }}
        >
            <h2 style={{ marginBottom: 10 }}>Background</h2>
            <div style={{ position: 'relative' }}>
                {background.url && (
                    <img
                        alt=""
                        style={{
                            cursor: 'pointer',
                            width: 300,
                            opacity: background.uploading ? 0.2 : 1,
                        }}
                        src={background.url.replace('{0}', '@3x')}
                    />
                )}
                {background.uploading && (
                    <div className="uploading">
                        Uploading{' '}
                        <img
                            alt="loading"
                            className="loading"
                            style={{
                                marginLeft: 10,
                                marginBottom: -6,
                                width: 22,
                                opacity: 0.8,
                            }}
                            src={savingIcon}
                        ></img>
                    </div>
                )}
            </div>
            <div>
                <div style={{ marginTop: 15 }}>
                    <input
                        type="file"
                        name=""
                        id="map-background"
                        accept="Image/*"
                        onChange={(e) =>
                            onSelectPhoto(
                                e,
                                'mapbackground',
                                onPhotosSelected,
                                onPhotosUploaded,
                                {
                                    fileName,
                                    expectedSize: mapDimensions,
                                }
                            )
                        }
                    />
                    <label
                        style={{
                            marginTop: 20,
                            cursor: 'pointer',
                            marginRight: 0,
                        }}
                        className="upload"
                        htmlFor="map-background"
                    >
                        Upload
                    </label>
                </div>
            </div>
        </div>
    )
}

export default BackgroundPhotos
