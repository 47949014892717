import { useEffect, useState } from 'react'
import _ from 'lodash'

import ImportButton from './ImportButton'
import Output from './Output'
import TestItem from './TestItem'
import { Modal } from '../shared'

let ABTestConfig
try {
    ABTestConfig = JSON.parse(localStorage.getItem('ABTestConfig'))
} catch (i) {
    ABTestConfig = { clickOnBuyButton: true }
    console.log('No local configuration')
}

const ABTestTool = () => {
    const [ABConfig, setABConfig] = useState(ABTestConfig || {})

    useEffect(() => {
        save()
    }, [ABConfig])

    let keys = []
    try {
        keys = Object.keys(ABConfig)
    } catch (e) {
        console.log('No local configuration found')
    }

    function save(config) {
        console.log('Saving A/B test config')
        const s = JSON.stringify(config || ABConfig)
        localStorage.setItem('ABTestConfig', s)
    }

    const deleteItem = (name) => {
        let newABConfig = _.cloneDeep(ABConfig)
        delete newABConfig[name]
        setABConfig(newABConfig)
    }

    const addItem = async () => {
        let name = await Modal.prompt({
            heading: 'Add new item',
            text: 'What are we testing?',
            placeholder: 'eg: Buy button color',
        })
        let value = await Modal.prompt({
            heading: 'Add new item',
            text: 'What is the default value?',
            placeholder: 'eg: #00ff00',
        })
        if (value === 'true') value = true
        if (value === 'false') value = false

        // Convert to variable name eg "Click on buy button" to "clickOnBuyButton"
        let res = ''
        for (let n of name.split(' ')) {
            n = n[0].toUpperCase() + n.substring(1)
            res += n
        }
        res = res[0].toLowerCase() + res.substring(1)
        let newABConfig = _.cloneDeep(ABConfig)
        newABConfig[res] = value
        setABConfig(newABConfig)
    }

    const onChange = (name, value) => {
        let newABConfig = _.cloneDeep(ABConfig)
        newABConfig[name] = value
        setABConfig(newABConfig)
    }

    return (
        <>
            <ImportButton save={save} setConfig={setABConfig} />
            <div></div>
            {keys.map((key, i) => (
                <TestItem
                    key={`item_${i}`}
                    i={i}
                    name={key}
                    onChange={onChange}
                    value={ABConfig[key]}
                    deleteItem={deleteItem}
                />
            ))}

            <>
                <button onClick={addItem} style={{ margin: '30px 0' }}>
                    Create new test variable
                </button>

                <Output data={ABConfig} height={300} />
            </>
        </>
    )
}

export default ABTestTool
