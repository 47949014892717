import { ChangeEventHandler, useEffect, useMemo, useState } from 'react'
import { OnDragEndResponder } from '@hello-pangea/dnd'
import axios from 'axios'
import { ValidationResult } from 'joi'
import _ from 'lodash'

import { ErrorMessage } from '../shared/ErrorMessage/ErrorMessage.tsx'
import ModalV2 from '../shared/ModalV2'
import CopyPack from './CopyPack/CopyPack'
import PacksHeader from './PacksHeader'
import PacksTable from './PacksTable'
import { Loading, Section } from '../shared'

import { usePacks } from '../hooks/packs'

import { arrayMove, handleError } from '../utils/utils'
import {
    filterPacksBySearchString,
    filterPacksByStatus,
    filterPacksByType,
    removeStandalonePacks,
    SortByType,
    sortPacks,
} from './utils'

import { LanguageType } from '../types/enums/languagetype.enum.ts'

import { Pack } from '../types/packTypes'

import validate from './validate'

type PacksProps = {
    language: LanguageType
}

function Packs(props: PacksProps) {
    const { language } = props

    const {
        packs,
        fetch: fetchPacks,
        isLoading: isPacksLoading,
        error: packsError,
    } = usePacks()
    useEffect(() => {
        fetchPacks()
    }, [])
    const [displayedPacks, setDisplayedPacks] = useState<Pack[]>([])
    useEffect(() => {
        const displayedPacks = removeStandalonePacks(packs || [])
        setDisplayedPacks(displayedPacks)
    }, [packs])

    const [searchString, setSearchString] = useState(
        localStorage.getItem('packSearchString') ?? ''
    )
    const [filterByStatus, setFilterByStatus] = useState(
        localStorage.getItem('filterByStatus') ?? 'all'
    )
    const [filterByType, setFilterByType] = useState(
        localStorage.getItem('filterByType') ?? 'all'
    )
    const [isSortAscend, setIsSortAscend] = useState(false)
    const [sortBy, setSortBy] = useState<SortByType>()
    const [packIdToCopy, setPackIdToCopy] = useState<string>()

    const validationResults = useMemo(() => {
        const results: Record<
            string,
            {
                errors: ValidationResult
                warnings: ValidationResult
            }
        > = {}
        if (packs) {
            for (const pack of packs) {
                const childPacks = pack.childPacks.map((childPackId) =>
                    packs.find((pack) => pack.packId === childPackId)
                )

                if (childPacks.includes(undefined)) {
                    throw new Error(
                        `${pack.packId} contains invalid reference to childPackId`
                    )
                }

                results[pack.packId] = validate(
                    {
                        ...pack,
                        medias: pack.medias.map((m) => m.id),
                    },
                    childPacks as Pack[]
                )
            }
        }
        return results
    }, [packs])

    const onSearchStringChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        const value = e.target.value
        localStorage.setItem('packSearchString', value)
        setSearchString(value)
    }

    const onFilterChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        const { name, value } = e.target
        if (name === 'status') {
            setFilterByStatus(value)
        }
        if (name === 'type') {
            setFilterByType(value)
        }
        const itemKey =
            'filterBy' + name.charAt(0).toUpperCase() + name.substring(1)
        localStorage.setItem(itemKey, value)
    }

    const onClickSort = (newSortBy: SortByType | undefined) => {
        if (sortBy === newSortBy) {
            if (isSortAscend) {
                setSortBy(undefined)
            }
            return setIsSortAscend(!isSortAscend)
        }
        return setSortBy(newSortBy)
    }

    const saveOrder = async (packs: Pack[]) => {
        const packIds = packs.map((p) => p.packId)
        try {
            await axios.put(`/api/packs`, { packs: packIds })
        } catch (e) {
            handleError('Error updating pack order', e)
        }
    }

    const onDragEnd: OnDragEndResponder = (result) => {
        if (!result.destination) {
            return
        }

        const reorderedPacks = arrayMove(
            _.cloneDeep(displayedPacks),
            result.source.index,
            result.destination.index
        )
        saveOrder(reorderedPacks)
        setDisplayedPacks(reorderedPacks)
    }

    if (isPacksLoading) {
        return <Loading />
    }

    if (packsError) {
        return <ErrorMessage message={packsError} />
    }

    const filterPacks = _.flow([
        (packs) => filterPacksBySearchString(packs, searchString, language),
        (packs) => filterPacksByStatus(packs, filterByStatus),
        (packs) => filterPacksByType(packs, filterByType),
    ])
    const filteredPacks = filterPacks(displayedPacks)
    const filteredSortedPacks = sortPacks(filteredPacks, sortBy, isSortAscend)
    const isDragDropEnabled =
        !searchString &&
        filterByStatus === 'all' &&
        filterByType === 'all' &&
        !sortBy

    return (
        <>
            {packIdToCopy && (
                <ModalV2>
                    <CopyPack
                        packId={packIdToCopy}
                        onClose={() => setPackIdToCopy(undefined)}
                    />
                </ModalV2>
            )}
            <Section>
                <PacksHeader
                    packsCount={filteredSortedPacks.length}
                    searchString={searchString}
                    onSearchStringChange={onSearchStringChange}
                    filterValues={{
                        status: filterByStatus,
                        type: filterByType,
                    }}
                    onFilterChange={onFilterChange}
                />
                <PacksTable
                    packs={filteredSortedPacks}
                    isSortAscend={isSortAscend}
                    sortBy={sortBy}
                    onClickSort={onClickSort}
                    isDragDropEnabled={isDragDropEnabled}
                    onClickCopy={setPackIdToCopy}
                    onDragEnd={onDragEnd}
                    validationResults={validationResults}
                />
            </Section>
        </>
    )
}

export default Packs
