import { mapDimensions } from '../settings'

export default {
    default: {
        name: 'Default (responsive)',
        drawScale: 0.7,
        resolution: mapDimensions,
        cropTop: 0,
    },
    iphone5: {
        name: 'iPhone 5, 5C, 5S',
        drawScale: 640 / 1136,
        resolution: { w: 1136, h: 640 },
        mapHeight: 0.8,
        cropTop: 640 / 10,
        style: {
            border: '20px solid black',
            borderLeftWidth: 100,
            borderRightWidth: 100,
            borderRadius: 30,
        },
    },
    iphone6: {
        name: 'iPhone 6, 6S',
        drawScale: 750 / 1334,
        resolution: { w: 1334, h: 750 },
        mapHeight: 0.8,
        cropTop: 750 / 10,
        style: {
            border: '20px solid black',
            borderLeftWidth: 100,
            borderRightWidth: 100,
            borderRadius: 50,
        },
    },
    iphoneXS: {
        name: 'iPhone XS',
        drawScale: 1125 / 2436,
        resolution: { w: 2436, h: 1125 },
        mapHeight: 0.8,
        cropTop: 1125 / 10,
        style: {
            border: '25px solid black',
            borderLeftWidth: 50,
            borderRightWidth: 50,
            borderRadius: 100,
        },
    },
    ipadPro13: {
        name: 'iPad Pro 12.9"',
        drawScale: 2048 / 2731,
        resolution: { w: 2732, h: 2048 },
        mapHeight: 0.8,
        cropTop: 2048 / 10,
        style: {
            border: '30px solid black',
            borderLeftWidth: 150,
            borderRightWidth: 150,
            borderRadius: 75,
        },
    },
    galaxyTab10: {
        name: 'Galaxy Tab 10.1"',
        drawScale: 920 / 1280,
        resolution: { w: 1280, h: 920 },
        mapHeight: 0.8,
        cropTop: 920 / 10,
        style: {
            border: '30px solid black',
            borderLeftWidth: 150,
            borderRightWidth: 150,
            borderRadius: 75,
        },
    },
    amazonFire: {
        name: 'Amazon Fire 7.4"',
        drawScale: 600 / 1024,
        resolution: { w: 1024, h: 600 },
        mapHeight: 0.8,
        cropTop: 600 / 10,
        style: {
            border: '30px solid black',
            borderLeftWidth: 150,
            borderRightWidth: 150,
            borderRadius: 75,
        },
    },
    galaxyJ5: {
        name: 'Galaxy J5 5"',
        drawScale: 720 / 1280,
        resolution: { w: 1280, h: 720 },
        mapHeight: 0.8,
        cropTop: 720 / 10,
        style: {
            border: '20px solid black',
            borderLeftWidth: 100,
            borderRightWidth: 100,
            borderRadius: 30,
        },
    },
    iPhone11Xr: {
        name: 'iPhone 11/XR 6.1"',
        drawScale: 828 / 1792,
        resolution: { w: 1792, h: 828 },
        mapHeight: 0.8,
        cropTop: 828 / 10,
        style: {
            border: '30px solid black',
            borderLeftWidth: 150,
            borderRightWidth: 150,
            borderRadius: 75,
        },
    },
    iPadAir2: {
        name: 'iPad Air 2 9.7"',
        drawScale: 1536 / 2048,
        resolution: { w: 2048, h: 1536 },
        mapHeight: 0.8,
        cropTop: 1536 / 10,
        style: {
            border: '30px solid black',
            borderLeftWidth: 150,
            borderRightWidth: 150,
            borderRadius: 75,
        },
    },
}
