import Joi, { ValidationError } from 'joi'

import { displayNameLanguagesSchema } from '../utils/utils'

const img = {
    mediaId: Joi.number()
        .required()
        .error(
            () =>
                new ValidationError('Asset (background or playset) is missing')
        ),
    url: Joi.string(),
    uploading: Joi.boolean(),
    md5: Joi.string(),
}

const location = Joi.object().keys({
    x: Joi.number().min(-1).max(1).required(),
    y: Joi.number().min(-1).max(1).required(),
    page: Joi.number(),
    packId: Joi.string(),
    img: Joi.object().keys(img).required(),
    imgOwned: Joi.object().keys(img),
    pricetag: Joi.object()
        .keys({
            x: Joi.number().min(-1).max(1).required(),
            y: Joi.number().min(-1).max(1).required(),
            rotation: Joi.number().required(),
        })
        .required(),
    uploading: Joi.boolean(), // not saved, just for upload tracking
})

let displayName = Joi.object().keys(displayNameLanguagesSchema).required()

const warningItem = Joi.object().keys({
    mapId: Joi.any(), // not used by cms but passed on by server
    packId: Joi.string().allow(null), // packId can be null when not connected to a district
    background: Joi.object().keys(img).required(),
    stringId: Joi.string().allow(null, ''),
    displayName: Joi.object().when('packId', {
        is: null,
        then: displayName,
        otherwise: Joi.object(),
    }),
    locations: Joi.array().items(location),
})

const errorItem = Joi.object().keys({
    mapId: Joi.any(), // not used by cms but passed on by server
    packId: Joi.string().allow(null), // packId can be null when not connected to a district
    background: Joi.object().keys(img).required(),
    stringId: Joi.string().allow(null, ''),
    displayName: Joi.object(),
    locations: Joi.array().items(location),
})

let mapWarningSchema = Joi.object()
    .options({ abortEarly: false })
    .keys({
        mapId: Joi.any(), // not used by cms but passed on by server
        mapName: Joi.string().required(),
        active: Joi.boolean().required(),
        items: Joi.array().items(warningItem).required(),
    })

let mapErrorSchema = Joi.object()
    .options({ abortEarly: false })
    .keys({
        mapId: Joi.any(), // not used by cms but passed on by server
        mapName: Joi.string().required(),
        active: Joi.boolean().required(),
        displayName: Joi.object(),
        items: Joi.array().items(errorItem).required(),
    })

function validate(item) {
    let warnings = mapWarningSchema.validate(item)
    let errors = mapErrorSchema.validate(item)
    return { warnings, errors }
}

export default validate
