import Joi from 'joi'

import { displayNameLanguagesSchema } from '../utils/utils'

let displayName = Joi.object()
    .keys(displayNameLanguagesSchema)
    .required()
    .unknown(true)

let carouselErrorSchema = Joi.object()
    .options({ abortEarly: false })
    .keys({
        carouselId: Joi.optional(),
        carouselName: Joi.string()
            .pattern(/^[a-zA-Z0-9 _]*$/)
            .required()
            .messages({
                'string.empty': 'Category ID is not allowed to be empty',
                'string.pattern.base':
                    'Category ID can only contain alphanumeric characters, underscore and spaces',
            }),
        active: Joi.boolean().required(),
        priority: Joi.number().integer(),
        items: Joi.array().min(1).required().messages({
            'array.min': 'Please select at least 1 pack for Carousel content',
        }),
        displayName: Joi.object(),
    })

let carouselWarningSchema = Joi.object()
    .options({ abortEarly: false })
    .keys({
        carouselId: Joi.optional(),
        carouselName: Joi.string().required(),
        active: Joi.boolean().required(),
        priority: Joi.number().integer().min(0),
        items: Joi.array().required(),
        displayName,
    })

function validate(item) {
    let warnings = carouselWarningSchema.validate(item)
    let errors = carouselErrorSchema.validate(item)
    return { errors, warnings }
}

export default validate
