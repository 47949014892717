import { useState } from 'react'
import { Tooltip } from 'react-tooltip'

import { NewRow } from '../shared'

import deleteImg from '../assets/delete.png'

type PackScenesProps = {
    scenes: string[]
    addScene: (value: string) => void
    deleteScene: (index: number) => Promise<void>
}

const PackScenes = (props: PackScenesProps) => {
    const [value, setValue] = useState('')
    const { scenes, deleteScene } = props

    function addScene() {
        if (value.length === 0) return
        props.addScene(value)
        setValue('')
    }
    return (
        <>
            <h2 style={{ marginTop: 30 }}>Add scenes</h2>
            {scenes.map((p, i) => {
                return (
                    <div key={'scene' + i} className="scene">
                        <img
                            alt="delete"
                            onClick={() => {
                                deleteScene(i)
                            }}
                            src={deleteImg}
                        ></img>
                        {p}
                    </div>
                )
            })}

            <NewRow />
            <input
                data-tooltip-content="Scene names are case sensitive. Please use copy/paste."
                data-tooltip-id="scene"
                className="labelInput"
                placeholder="Scene id"
                value={value}
                onChange={(e) => {
                    setValue(e.target.value)
                }}
                onKeyPress={(e) => e.key === 'Enter' && addScene()}
            />
            <Tooltip id="scene" />
            <button style={{ marginLeft: 10 }} onClick={addScene}>
                Add
            </button>
        </>
    )
}

export default PackScenes
