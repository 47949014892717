import _ from 'lodash'

import { itemPropertiesByPackType } from '../../settings'

import { Pack } from '../../types/packTypes'

export function hasAboutChanged(packA: Pack, packB: Pack) {
    const aboutProperties = ['name', 'packId', 'displayName', 'features']
    return !_.isEqual(
        _.pick(packA, aboutProperties),
        _.pick(packB, aboutProperties)
    )
}
export function hasItemsChanged(packA: Pack, packB: Pack) {
    return !_.isEqual(
        _.pick(packA, itemPropertiesByPackType[packA.type]),
        _.pick(packB, itemPropertiesByPackType[packB.type])
    )
}
export function hasMediasChanged(packA: Pack, packB: Pack) {
    const packMediasA = packA.medias.map((media) =>
        _.pick(media, ['type', 'md5'])
    )
    const packMediasB = packB.medias.map((media) =>
        _.pick(media, ['type', 'md5'])
    )
    return !_.isEqual(packMediasA, packMediasB)
}

export function hasPackChanged(packA: Pack, packB: Pack) {
    return (
        !_.isEqual(_.omit(packA, 'medias'), _.omit(packB, 'medias')) ||
        hasMediasChanged(packA, packB)
    )
}
