import { createRoot } from 'react-dom/client'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import axios from 'axios'

import App from './app/App.tsx'

import registerServiceWorker from './registerServiceWorker'

import './index.scss'

const queryClient = new QueryClient()

const container = document.getElementById('root') as HTMLDivElement
const root = createRoot(container)
root.render(
    <QueryClientProvider client={queryClient}>
        <App />
    </QueryClientProvider>
)

// Axios send credentials cross site
axios.defaults.withCredentials = true

/* Uncomment below to turn of hot reloading */
/*if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default
    root.render(<App />)
  })
} */

window.version = 4711

registerServiceWorker()
