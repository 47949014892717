import globeImg from '../assets/globe.png'

function Loading(props) {
    const { text, style } = props
    return (
        <div
            className="loading"
            style={style || { textAlign: 'center', marginTop: 50 }}
        >
            <h1>{text ? text : 'Loading, hang on..'}</h1>
            <div>
                <img
                    alt="loading"
                    className="loading"
                    style={{ opacity: 0.8 }}
                    src={globeImg}
                ></img>
            </div>
        </div>
    )
}

export default Loading
