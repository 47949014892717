import axios from 'axios'
import _ from 'lodash'

import { Modal } from '../shared'

import { handleError } from './utils'

async function getEnvironments() {
    try {
        const res = await axios.get('/api/v2/env')
        return res.data
    } catch (e) {
        handleError('Error fetching environments', e)
        return false
    }
}

async function getActiveEnvironment() {
    const environments = await getEnvironments()
    if (!environments) {
        return false
    }
    const currentEnv = _.find(environments, (env) => {
        return env.active === true
    })
    return currentEnv
}

async function getAuthStatus(basepath) {
    try {
        await axios.get(`${basepath}/helloAuthenticated`)
        return true
    } catch (e) {
        return false
    }
}

export function signInWithGoogle(apiPath) {
    const pollingInterval = 1000
    const timeoutDelay = 5 * 60 * 1000
    const basepath = apiPath.replace('/api', '')

    return new Promise((resolve, reject) => {
        const windowFeatures = 'popup,width=650,height=800'
        const url = basepath + '/auth/google'
        const popup = window.open(url, '_blank', windowFeatures)

        if (!popup) {
            return reject(
                new Error(
                    'The popup window blocked by the browser. Please, allow this website to open new windows.'
                )
            )
        }

        const timeout = setTimeout(() => {
            clearInterval(polling)
            popup.close()

            reject(
                new Error(
                    "Couldn't authenticate within the timeout constraints"
                )
            )
        }, timeoutDelay)

        const polling = setInterval(async () => {
            const isAuthenticated = await getAuthStatus(apiPath)

            if (isAuthenticated) {
                clearInterval(polling)
                clearTimeout(timeout)
                popup.close()
                resolve()
            }
        }, pollingInterval)
    })
}

async function selectEnv() {
    // Get current active environment
    const environments = await getEnvironments()
    if (!environments) {
        return null
    }
    const currentEnv = _.find(environments, (env) => {
        return env.active === true
    }).name

    // Show target env modal
    const items = environments.map((env) => {
        return {
            label: env.name,
            value: env.name,
            disabled: env.name === currentEnv,
        }
    })
    const targetEnv = await Modal.select({
        items,
        heading: 'Choose target environment',
        okLabel: 'Continue!',
        hint: 'Choose which environment to use',
        inputName: 'env',
    })
    if (!targetEnv) {
        return null
    }
    const targetEnvDetails = _.find(environments, (env) => {
        return env.name === targetEnv
    })

    return targetEnvDetails
}

async function handleEnvAuthGoogle(targetEnvDetails) {
    try {
        await signInWithGoogle(targetEnvDetails.basepath)

        console.log(`Signed in to ${targetEnvDetails.name} with Google Auth`)

        return targetEnvDetails
    } catch (error) {
        handleError(
            "Couldn't authenticate in the target environment with Google Auth",
            error
        )
    }

    return false
}

async function handleEnvAuth() {
    const targetEnvDetails = await selectEnv()

    if (!targetEnvDetails) {
        return false
    }

    // Check auth status for target env
    const isAuthenticated = await getAuthStatus(targetEnvDetails.basepath)

    if (isAuthenticated) {
        return targetEnvDetails
    }

    return handleEnvAuthGoogle(targetEnvDetails)
}

export { getEnvironments, getActiveEnvironment, getAuthStatus, handleEnvAuth }
