import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { getRedirectToAttr } from '../utils/redirectToLogin.ts'

import { RouteTo } from '../routes.ts'

export const FallbackPage = () => {
    const navigate = useNavigate()
    const redirectPath = getRedirectToAttr() || RouteTo.Packs()

    useEffect(() => {
        navigate(redirectPath)
    }, [])

    return null
}
