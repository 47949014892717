import { useCallback } from 'react'
import { useDrop } from 'react-dnd'
import { NativeTypes } from 'react-dnd-html5-backend'

import { onSelectPhoto, onSelectVideo } from '../utils/upload'

import {
    iapImageDimensions,
    packScreenshotDimensions,
    packScrollingScreenshotDimensions,
    packTabThumbnailDimensions,
    packThumbnailDimensions,
    packWideThumbnailDimensions,
    tabBackgroundDimensions,
    tabIconDimensions,
} from '../settings'

import { MediaType } from '../types/enums/mediatype.enum'

function DragDropContainer({
    data,
    type,
    children,
    onMediasSelected,
    onMediasWasUploaded,
}) {
    const onDrop = useCallback((item, monitor) => {
        if (monitor) {
            const files = monitor.getItem().files
            // Default is pack screenshot dimensions as it's most used type
            let expectedSize = packScreenshotDimensions
            switch (type) {
                case MediaType.SCREENSHOT:
                    expectedSize = packScreenshotDimensions
                    break
                case MediaType.THUMBNAIL:
                    expectedSize = packThumbnailDimensions
                    break
                case MediaType.WIDE_THUMBNAIL:
                    expectedSize = packWideThumbnailDimensions
                    break
                case MediaType.TAB_THUMBNAIL:
                    expectedSize = packTabThumbnailDimensions
                    break
                case MediaType.SCROLLING_SCREENSHOT:
                    expectedSize = packScrollingScreenshotDimensions
                    break
                case MediaType.TAB_BACKGROUND:
                    expectedSize = tabBackgroundDimensions
                    break
                case MediaType.TAB_ICON:
                    expectedSize = tabIconDimensions
                    break
                case MediaType.IAP_IMAGE:
                    expectedSize = iapImageDimensions
                    break
            }
            if (type === MediaType.VIDEO)
                onSelectVideo({ files }, onMediasSelected, onMediasWasUploaded)
            else
                onSelectPhoto(
                    { files },
                    type,
                    onMediasSelected,
                    onMediasWasUploaded,
                    {
                        fileName: `${data.id ?? data.packId}_${type}`,
                        expectedSize,
                    }
                )
        }
    }, [])
    const [{ canDrop, isOver }, drop] = useDrop({
        accept: [NativeTypes.FILE],
        drop(item, monitor) {
            if (onDrop) {
                onDrop({ onDrop }, monitor)
            }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    })
    const isActive = canDrop && isOver

    return (
        <div ref={drop}>
            <div style={isActive ? { opacity: 0.3 } : {}}> {children}</div>
        </div>
    )
}

export default DragDropContainer
