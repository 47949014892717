import classNames from 'classnames'

import StatusLabel, { ChangeStatuses } from './StatusLabel'

import { getPackThumbnail } from '../../utils/utils'

import { Pack } from '../../types/packTypes'

import styles from './About.module.scss'

import thumbPlaceholderImg from '../../assets/thumb_placeholder.png'

type AboutProps = { pack: Pack; isExpanded: boolean; isUpdated?: boolean }

function About(props: AboutProps) {
    const { pack, isExpanded, isUpdated } = props
    const thumbnail = getPackThumbnail(pack)
    const thumbnailUrl =
        thumbnail?.url.replace('{0}', '@1x') ?? thumbPlaceholderImg
    return (
        <>
            <div className={styles.summary}>
                <img className={styles.thumbnail} src={thumbnailUrl} />
                <div className={styles.name}>
                    {isExpanded && <dt>String ID</dt>}
                    <dd
                        className={classNames(
                            styles.data,
                            isExpanded && styles.expandedData
                        )}
                    >
                        {pack.name}
                    </dd>
                </div>
                {isUpdated && <StatusLabel status={ChangeStatuses.UPDATED} />}
            </div>
            {isExpanded && (
                <dl className={styles.list}>
                    <div className={styles.row}>
                        <div>
                            <dt>Pack ID</dt>
                            <dd
                                className={classNames(
                                    styles.data,
                                    styles.expandedData
                                )}
                            >
                                {pack.packId}
                            </dd>
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div>
                            <dt>Display Name</dt>
                            <dd
                                className={classNames(
                                    styles.data,
                                    styles.expandedData
                                )}
                            >
                                {pack.displayName.en}
                            </dd>
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div>
                            <dt>Description</dt>
                            <div
                                className={classNames(
                                    styles.data,
                                    styles.expandedData
                                )}
                            >
                                {pack.features.en}
                            </div>
                        </div>
                    </div>
                </dl>
            )}
        </>
    )
}

export default About
