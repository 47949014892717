import styles from './Expand.module.scss'

import CollapseIcon from '../../assets/collapse.svg'
import ExpandIcon from '../../assets/expand.svg'

type ExpandProps = {
    isExpanded: boolean
    onClick: () => void
}

export function Expand(props: ExpandProps) {
    const { isExpanded, onClick } = props

    const icon = isExpanded
        ? {
              alt: 'Collapse row',
              src: CollapseIcon,
          }
        : {
              alt: 'Expand row',
              src: ExpandIcon,
          }
    return <img {...icon} className={styles.expand} onClick={onClick} />
}

export default Expand
