import { Tooltip } from 'react-tooltip'

import Modal from '../shared/Modal'
import { Button, ButtonKind, Footer } from '../shared/ModalV2'

import { getFileNameFromUrl } from './utils'

import { RouteTo } from '../routes'
import { mediaTypeToDisplayText } from '../settings'

import { MediaType as MediaTypeEnum } from '../types/enums/mediatype.enum'

import { Media as MediaType } from '../types/mediaTypes'
import { MediaUsages } from '../types/usageTypes'

import styles from './Media.module.scss'

import Bin from '../assets/bin.svg'
import Link from '../assets/link.svg'

type MediaProps = {
    media: MediaType
    usage: MediaUsages
    usageCount: number
    onDeleteMedia: (id: MediaType['id']) => Promise<void>
    onClose: () => void
}

function Media(props: MediaProps) {
    const { media, usage, usageCount, onDeleteMedia, onClose } = props

    const onClickDelete = async (id: MediaType['id']) => {
        const isConfirmed = await Modal.confirm({
            heading: 'Delete media',
            text: 'The media file will no longer be accessible after the deletion. Please make sure the media file is not currently being used.',
            okLabel: 'Delete',
        })
        if (!isConfirmed) return

        await onDeleteMedia(id)
    }

    return (
        <div className={styles.media}>
            <div className={styles.previewWrapper}>
                {media.type === MediaTypeEnum.VIDEO ? (
                    <video controls autoPlay className={styles.preview}>
                        <source src={media.url} />
                    </video>
                ) : (
                    <img
                        alt={`Image with id ${media.id}`}
                        className={styles.preview}
                        src={media.url.replace('{0}', '@3x')}
                    />
                )}
            </div>
            <div className={styles.metadataWrapper}>
                <div>
                    <header className={styles.header}>Media details</header>
                    <dl>
                        <dt className={styles.propertyKey}>Name</dt>
                        <dd className={styles.propertyValue}>
                            {getFileNameFromUrl(media.url)}
                        </dd>
                        <dt className={styles.propertyKey}>Category</dt>
                        <dd className={styles.propertyValue}>
                            {mediaTypeToDisplayText[media.type]}
                        </dd>
                        <dt className={styles.propertyKey}>Uploaded</dt>
                        <dd className={styles.propertyValue}>
                            {media.created}
                        </dd>
                    </dl>
                    <div className={styles.usages}>
                        <div className={styles.usagesHeader}>
                            <img alt="Link icon" src={Link} width="15px" />
                            <div>{`Media connections (${usageCount})`}</div>
                        </div>
                        <dl className={styles.usagesByEntity}>
                            {usage.packs.length > 0 && (
                                <>
                                    <dt>Packs</dt>
                                    {usage.packs.map((pack) => (
                                        <dd
                                            key={pack.packId}
                                            className={styles.usage}
                                        >
                                            <Tooltip
                                                id={`media-usage-${pack.packId}`}
                                            />
                                            <a
                                                data-tooltip-id={`media-usage-${pack.packId}`}
                                                data-tooltip-content={
                                                    pack.packId
                                                }
                                                href={RouteTo.PackEdit(
                                                    pack.packId
                                                )}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {pack.name}
                                            </a>
                                        </dd>
                                    ))}
                                </>
                            )}
                            {usage.tabs.length > 0 && (
                                <>
                                    <dt>Tabs</dt>
                                    {usage.tabs.map((tab) => (
                                        <dd
                                            key={tab.id}
                                            className={styles.usage}
                                        >
                                            <a
                                                href={RouteTo.TabEdit(
                                                    String(tab.id)
                                                )}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {tab.name}
                                            </a>
                                        </dd>
                                    ))}
                                </>
                            )}
                            {usage.maps.length > 0 && (
                                <>
                                    <dt>Maps</dt>
                                    {usage.maps.map((map) => (
                                        <dd
                                            key={map.mapId}
                                            className={styles.usage}
                                        >
                                            <a
                                                href={RouteTo.MapEdit(
                                                    map.mapName
                                                )}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {map.mapName}
                                            </a>
                                        </dd>
                                    ))}
                                </>
                            )}
                        </dl>
                    </div>
                    <p className={styles.deleteInfoParagraph}>
                        Any media connection will be removed if the file is
                        deleted from the CMS.
                    </p>
                    <Button
                        kind={ButtonKind.DANGER}
                        className={styles.deleteButton}
                        onClick={() => onClickDelete(media.id)}
                    >
                        <img alt="Delete" src={Bin} />
                        Delete media
                    </Button>
                </div>
                <Footer>
                    <Button kind={ButtonKind.SECONDARY} onClick={onClose}>
                        Close
                    </Button>
                </Footer>
            </div>
        </div>
    )
}

export default Media
