import { useState } from 'react'

import { SpeechBubble } from '../shared'

const Output = ({ data, height }) => {
    const [hasCopiedSuccessfully, setHasCopiedSuccessfully] = useState(false)

    const copy = () => {
        navigator.clipboard.writeText(JSON.stringify(data))
        setHasCopiedSuccessfully(true)
        setTimeout(() => setHasCopiedSuccessfully(false), 2000)
    }

    return (
        <div style={{ marginTop: 20 }}>
            <div style={{ display: 'inline-block' }}>
                <h1>Output</h1>
                {hasCopiedSuccessfully ? (
                    <div style={{ display: 'inline-block' }}>
                        <SpeechBubble style={{ marginLeft: 25 }}>
                            I copied it for you!
                        </SpeechBubble>
                    </div>
                ) : (
                    <button className="new withHeading" onClick={copy}>
                        Copy to clipboard
                    </button>
                )}
            </div>
            <textarea
                style={{
                    width: '100%',
                    height: height,
                    fontFamily: 'monospace',
                }}
                readOnly={true}
                value={JSON.stringify(data, null, 2)}
            />
        </div>
    )
}

export default Output
