import { useState } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'

import { handleError } from '../utils/utils.ts'

import { MediaType } from '../types/enums/mediatype.enum'

import { Media } from '../types/mediaTypes'
import { MediaUsages } from '../types/usageTypes'

export enum SortByOption {
    Type = 'type',
    Url = 'url',
    Created = 'created',
    Usages = 'usages',
}
export enum Order {
    ASC = 'asc',
    DESC = 'desc',
}

export type ListMediasParams = {
    page?: number
    limit?: number
    sortBy?: SortByOption
    order?: Order
    filterByType?: MediaType[]
    filterByDateFrom?: string
    filterByDateTo?: string
}

type MediasResponse = {
    medias: Media[]
    usagesByMediaId: Record<Media['id'], MediaUsages>
    pagination: {
        page: number
        limit: number
        totalPageCount: number
    }
}

export function useMedias(listMediasParams?: ListMediasParams) {
    async function listMedias(params?: ListMediasParams) {
        try {
            const { data } = await axios.get<MediasResponse>('/api/v2/medias', {
                params,
            })
            return data
        } catch (err: unknown) {
            handleError('List medias error', error, false)
            throw err
        }
    }

    const { isPending, error, data, refetch } = useQuery({
        queryKey: ['medias', listMediasParams],
        queryFn: () => listMedias(listMediasParams),
    })

    return {
        mediasResponse: data,
        refetch,
        isPending,
        error,
    }
}

export function useDeleteMedia() {
    const [isDeleted, setIsDeleted] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | undefined>()
    const queryClient = useQueryClient()

    async function deleteMedia(id: Media['id']) {
        setIsDeleted(false)
        setIsLoading(true)
        setError(undefined)
        try {
            await axios.delete(`/api/media/${id}`)
            queryClient.invalidateQueries({ queryKey: ['medias'] })
            setIsDeleted(true)
        } catch (err: unknown) {
            setError(handleError('Delete media error', err, false))
        }
        setIsLoading(false)
    }

    return {
        deleteMedia,
        isLoading,
        isDeleted,
        error,
    }
}
