import { MouseEvent, useEffect } from 'react'
import axios from 'axios'

import Loading from '../../shared/Loading'
import { Modal, SpeechBubble } from '../../shared'

import { usePackMembership } from '../../hooks/packs.ts'

import {
    getPackDisplayName,
    handleError,
    isPackInactive,
} from '../../utils/utils.ts'

import { Carousel } from '../../types/carouselTypes'
import { Pack } from '../../types/packTypes'
import { Tab } from '../../types/tabTypes'

import styles from './PackMembership.module.scss'

type PackMembershipProps = {
    packId: string
}

export function PackMembership(props: PackMembershipProps) {
    const { packId } = props

    const {
        membership: { packs, carousels, tabs },
        fetch: fetchPackMembership,
        isLoading,
        error,
    } = usePackMembership(packId)

    useEffect(() => {
        fetchPackMembership()
    }, [])

    const createOnRemoveFromCarouselClick =
        (carousel: Carousel, itemId: number) =>
        async (event: MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation()

            const isConfirmed = await Modal.confirm({
                heading: 'Remove pack',
                text: `Really remove this pack from "${carousel.carouselName}" carousel?`,
                okLabel: 'Yes do it!',
            })

            if (!isConfirmed) {
                return
            }

            try {
                await axios.delete(`/api/v2/carouselitem/${itemId}`)
                await fetchPackMembership()
            } catch (e) {
                handleError('Error deleting the carousel item', e)
            }
        }

    const createOnRemoveFromPackClick =
        (pack: Pack) => async (event: MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation()

            const packName = getPackDisplayName(pack)
            const isConfirmed = await Modal.confirm({
                heading: 'Remove pack',
                text: `Really remove this pack from "${packName}" bundle?`,
                okLabel: 'Yes do it!',
            })

            if (!isConfirmed) {
                return
            }

            try {
                await axios.delete(`/api/pack/${pack.packId}/child/${packId}`)
                await fetchPackMembership()
            } catch (e) {
                handleError('Error deleting pack from the bundle', e)
            }
        }

    const createOnRemoveFromTabClick =
        (tab: Tab) => async (event: MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation()

            const isConfirmed = await Modal.confirm({
                heading: 'Remove pack',
                text: `Really remove this pack from "${tab.name}" tab?`,
                okLabel: 'Yes do it!',
            })

            if (!isConfirmed) {
                return
            }

            try {
                await axios.delete(`/api/v2/shoptabs/${tab.id}/${packId}`)
                await fetchPackMembership()
            } catch (e) {
                handleError('Error deleting pack from the tab', e)
            }
        }

    if (isLoading) {
        return <Loading style={{ textAlign: 'center' }} />
    }

    if (error) {
        handleError('Error fetching pack membership data', error)
    }

    if (packs.length === 0 && carousels.length === 0 && tabs.length === 0) {
        return (
            <SpeechBubble style={{ justifyContent: 'left', margin: '10px 0' }}>
                This pack is not member of any bundles, carousels or tabs.
            </SpeechBubble>
        )
    }

    return (
        <>
            {packs.length > 0 && (
                <div className={styles.packUsageSection}>
                    <h2>Bundles</h2>
                    <ul>
                        {packs.map((p) => (
                            <li key={p.packId}>
                                <div>
                                    <a
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        href={`/pack/${p.packId}`}
                                    >
                                        {getPackDisplayName(p)}
                                    </a>{' '}
                                    {isPackInactive(p) && (
                                        <span className="inactive">
                                            (Inactive)
                                        </span>
                                    )}
                                </div>

                                <button
                                    style={{ margin: '0 3px' }}
                                    className="small danger"
                                    onClick={createOnRemoveFromPackClick(p)}
                                >
                                    Remove from this bundle
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
            {carousels.length > 0 && (
                <div className={styles.packUsageSection}>
                    <h2>Carousels</h2>
                    <ul>
                        {carousels.map((c) => {
                            const [{ carouselItemId }] = c.items

                            return (
                                <li key={c.carouselId}>
                                    <div>
                                        <a
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            href={`/carousel/${c.carouselId}`}
                                        >
                                            {c.carouselName}
                                        </a>{' '}
                                        {!c.active && (
                                            <span className="inactive">
                                                (Inactive)
                                            </span>
                                        )}
                                    </div>

                                    <button
                                        style={{ margin: '0 3px' }}
                                        className="small danger"
                                        onClick={createOnRemoveFromCarouselClick(
                                            c,
                                            carouselItemId
                                        )}
                                    >
                                        Remove from this carousel
                                    </button>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            )}
            {tabs.length > 0 && (
                <div className={styles.packUsageSection}>
                    <h2>Tabs</h2>
                    <ul>
                        {tabs.map((t) => (
                            <li key={t.id}>
                                <div>
                                    <a
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        href={`/shoptab/${t.id}`}
                                    >
                                        {t.name}
                                    </a>
                                </div>
                                <button
                                    className="small danger"
                                    onClick={createOnRemoveFromTabClick(t)}
                                >
                                    Remove from this tab
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </>
    )
}

export default PackMembership
