import Joi from 'joi'

import { displayNameLanguagesSchema } from '../../utils/utils'

import { TabType } from '../../types/enums/tabtype.enum'

import { Tab, TabCreate } from '../../types/tabTypes'

type TabValidationItem = Tab | TabCreate

const displayName = Joi.object()
    .keys(displayNameLanguagesSchema)
    .required()
    .unknown(true)

const tabCreateKeys = {
    name: Joi.string()
        .pattern(/^[a-zA-Z0-9 _]*$/)
        .required()
        .messages({
            'string.empty': 'Tab String ID is not allowed to be empty',
            'string.pattern.base':
                'Tab String ID can only contain alphanumeric characters, underscore and spaces',
        }),
    active: Joi.boolean().required(),
    priority: Joi.number().integer(),
    type: Joi.string()
        .valid(TabType.WORLD_MAP, TabType.PACKS_LIST)
        .required()
        .messages({
            'string.empty': 'Please select tab type',
            'any.only': `Type can be only ${TabType.WORLD_MAP} or ${TabType.PACKS_LIST}`,
        }),
    packIds: Joi.array().when('type', {
        is: TabType.WORLD_MAP,
        then: Joi.array().length(0).required(),
        otherwise: Joi.array().min(1).required().messages({
            'array.min': 'Please select at least 1 pack for tab content',
        }),
    }),
    displayName: Joi.object(),
    mediaIds: Joi.array().optional().allow(null),
    medias: Joi.array().optional().allow(null),
    buttonColor: Joi.string()
        // Hex color code like #7a2a1a
        .pattern(/^#([a-fA-F0-9]{6})$/)
        .max(7)
        .allow(null)
        .messages({
            'string.pattern.base': 'Color must be hex code, e.g. #7a2a1a',
        }),
}
const tabCreateErrorSchema = Joi.object()
    .options({ abortEarly: false })
    .keys(tabCreateKeys)

const tabUpdateErrorSchema = Joi.object()
    .options({ abortEarly: false })
    .keys({
        ...tabCreateKeys,
        id: Joi.number().integer().greater(0).required(),
    })

const tabWarningSchema = Joi.object()
    .options({ abortEarly: false })
    .keys({
        id: Joi.optional(),
        name: Joi.string().required(),
        active: Joi.boolean().required(),
        priority: Joi.number().integer().min(0),
        type: Joi.string()
            .valid(TabType.WORLD_MAP, TabType.PACKS_LIST)
            .required(),
        packIds: Joi.array().required(),
        displayName,
        mediaIds: Joi.array().optional().allow(null),
        medias: Joi.array().optional().allow(null),
        buttonColor: Joi.string().optional().allow(null),
    })

function validate(item: TabValidationItem, isNew: boolean) {
    const warnings = tabWarningSchema.validate(item)

    const errors = isNew
        ? tabCreateErrorSchema.validate(item)
        : tabUpdateErrorSchema.validate(item)
    return { errors, warnings }
}

export default validate
