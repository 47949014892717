import { Component } from 'react'
import { Tooltip } from 'react-tooltip'
import axios from 'axios'
import _ from 'lodash'

import ModalV2 from '../shared/ModalV2'
import Bulk from './Bulk.tsx'
import {
    Loading,
    Modal,
    NewRow,
    Section,
    SortIcon,
    SpeechBubble,
} from '../shared'

import { compareDates, formatDate, getDate, toJSONDate } from '../utils/dates'
import { handleEnvAuth } from '../utils/envaccess'
import {
    fetchDataFromPath,
    postDataForPath,
    putDataForPath,
} from '../utils/envcopy'
import { filterActive, handleError } from '../utils/utils'
import withRouter from '../utils/withRouter'

import { RouteTo } from '../routes'

import checkedImg from '../assets/checked.png'
import unCheckedImg from '../assets/unchecked.png'
import warningImg from '../assets/warning.png'

class Gifts extends Component {
    state = {
        isLoading: true,
        loadingMessage: '',
        gifts: [],
        selectedGifts: [],
        show: 'all',
        searchString: '',
        loadingGoogleSheetText: '',
        isSortAscend: false,
        sortBy: 'default',
        isBulkModalShown: false,
    }

    async componentDidMount() {
        await this.fetchGifts()
    }

    async fetchGifts() {
        this.setState({ isLoading: true })
        try {
            const { data } = await axios.get('/api/gifts')
            this.setState({ gifts: data })
        } catch (e) {
            handleError('Error loading gifts', e)
        }
        this.setState({ isLoading: false })
    }

    onFilterChange = (e) => {
        const { name, value } = e.target
        this.setState({ [name]: value })
    }

    filterByStatus = (gifts) => {
        const { show } = this.state
        if (show === 'all') {
            return gifts
        }

        return filterActive(gifts, show === 'active', 'startDate', 'endDate')
    }

    filterBySearchString = (gifts) => {
        const { searchString } = this.state
        if (!searchString) {
            return gifts
        }

        const loweredSearchString = searchString.toLowerCase()
        return gifts.filter((gift) =>
            gift.giftId.toLowerCase().includes(loweredSearchString)
        )
    }

    filter = _.flow([this.filterByStatus, this.filterBySearchString])

    sort = (gifts) => {
        const { sortBy, isSortAscend } = this.state
        const comparators = {
            default: (a, b) =>
                compareDates(a.endDate, b.endDate) ||
                compareDates(a.startDate, b.startDate),
            giftId: (a, b) =>
                (a.giftId || '').localeCompare(b.giftId || '', undefined, {
                    sensitivity: 'base',
                }),
            releaseNumber: (a, b) =>
                (a.releaseNumber || '').localeCompare(
                    b.releaseNumber || '',
                    undefined,
                    { numeric: true }
                ),
            startDate: (a, b) => compareDates(a.startDate, b.startDate),
            endDate: (a, b) => compareDates(a.endDate, b.endDate),
        }
        const sorted = gifts.slice().sort(comparators[sortBy])

        if (isSortAscend) {
            return sorted
        }
        return sorted.toReversed()
    }

    onClickSort = (newSortBy) => {
        const { isSortAscend, sortBy } = this.state
        if (sortBy === newSortBy) {
            return this.setState({ isSortAscend: !isSortAscend })
        }
        return this.setState({ sortBy: newSortBy })
    }

    onCopyGiftClick = async (e, gift) => {
        e.stopPropagation()
        this.setState({ isLoading: true, loadingMessage: 'Copying gift...' })

        const env = await handleEnvAuth()
        if (!env) {
            this.setState({
                isLoading: false,
                loadingMessage: '',
            })
            return
        }

        // Get and compare gifts
        const currentGift = await fetchDataFromPath(
            `/api/v2/gift/${gift.giftId}`
        )
        const refGift = await fetchDataFromPath(
            `${env.basepath}/v2/gift/${gift.giftId}`
        )

        // If now is inbetween dates, make the gift inactive by setting the dates to old values
        const now = getDate()
        if (
            now >= getDate(currentGift.startDate) &&
            now <= getDate(currentGift.endDate)
        ) {
            currentGift.startDate = toJSONDate('2010-01-01')
            currentGift.endDate = toJSONDate('2010-01-02')
            currentGift.active = false
        }

        if (_.isEmpty(refGift)) {
            // No gift in target environment, show confirm modal
            let isConfirmed = await Modal.confirm({
                heading: 'Copy gift',
                text: `No gift with id '${gift.giftId}' found in ${env.name}, do you want to make a copy?`,
                okLabel: 'Yes do it!',
            })
            if (!isConfirmed) {
                this.setState({
                    isLoading: false,
                    loadingMessage: '',
                })
                return
            }
        } else {
            // Gift found, show compare modal
            const isCompareConfirmed = await Modal.compare({
                heading: 'Compare gifts',
                text: 'The following differences were found',
                okLabel: 'Copy',
                compareType: 'gift',
                object1: refGift,
                object2: currentGift,
            })
            if (!isCompareConfirmed) {
                this.setState({
                    isLoading: false,
                    loadingMessage: '',
                })
                return
            }
        }

        // Copy gift to target environment
        this.setState({ loadingMessage: `Saving gift to ${env.name}...` })
        const hasCopiedSuccessfully = _.isEmpty(refGift)
            ? await postDataForPath(
                  { gift: currentGift },
                  `${env.basepath}/gift`
              )
            : await putDataForPath(
                  { gift: currentGift },
                  `${env.basepath}/gift/${currentGift.giftId}`
              )

        this.setState({ isLoading: false })

        if (hasCopiedSuccessfully)
            await Modal.alert({
                heading: 'Copying done!',
                text: `Woho, the gift with id '${currentGift.giftId}' was successfully stored in ${env.name}!`,
            })
        else
            await Modal.alert({
                heading: 'On no!',
                text: `Something went wrong when trying to copy gift with id '${currentGift.giftId}' to ${env.name}!`,
            })
    }

    isAllSelected = () => {
        const selectedIds = this.state.selectedGifts.map((g) => g.giftId)
        const allIds = this.state.gifts.map((g) => g.giftId)

        return _.isEmpty(_.xor(allIds, selectedIds))
    }

    toggleSelectAll = async () => {
        let selectedGifts = []

        if (!this.isAllSelected()) {
            let allGifts = _.cloneDeep(this.state.gifts)
            selectedGifts.push(...allGifts)
        }

        this.setState({ selectedGifts })
    }

    toggleCheckbox = async (item) => {
        let selectedGifts = _.cloneDeep(this.state.selectedGifts)
        let gift = _.find(selectedGifts, (g) => {
            return g.giftId === item.giftId
        })
        if (!gift) {
            selectedGifts.push({ ...item })
        } else {
            _.pull(selectedGifts, gift)
        }
        this.setState({ selectedGifts })
    }

    updateGiftsFromGoogleSheet = async () => {
        const selectedIds = this.state.selectedGifts.map((g) => g.giftId)
        // No gifts were selected, so nothing to update
        if (_.isEmpty(selectedIds)) {
            this.setState({
                loadingGoogleSheetText: 'Please select gifts first!',
            })
            setTimeout(() => {
                this.setState({ loadingGoogleSheetText: '' })
            }, 3000)
            return
        }

        setTimeout(() => {
            this.setState({ loadingGoogleSheetText: '' })
        }, 10000)

        this.setState({
            isLoading: true,
            loadingMessage: 'Loading data from Google sheet...',
        })

        // Load data from google sheets
        try {
            const giftsData = await axios.put(`/api/v2/importgifts`, {
                giftIds: selectedIds,
            })

            // There is nothing from google
            if (_.isEmpty(giftsData.data)) {
                this.setState({
                    isLoading: false,
                    loadingMessage: '',
                    loadingGoogleSheetText: 'No data from Google sheet',
                })
                return
            }

            this.setState({
                loadingMessage: 'Updating gifts...',
            })
            let gifts = _.cloneDeep(this.state.gifts)
            let updatedGiftIds = []

            // Go through response and update all it's gifts
            for (let i = 0; i < giftsData.data.length; i++) {
                let updatedGift = giftsData.data[i]
                updatedGift.startDate = toJSONDate(updatedGift.startDate)
                updatedGift.endDate = toJSONDate(updatedGift.endDate)
                updatedGift.requiredVersion = parseInt(
                    updatedGift.requiredVersion
                )
                // Collect gift id that was updated to show it to user later
                updatedGiftIds.push(updatedGift.giftId)

                // Save each gift with data from google
                await axios.put(`/api/gift/${updatedGift.giftId}`, {
                    gift: updatedGift,
                })

                // Update state
                gifts.map((item, index) => {
                    if (item.giftId === updatedGift.giftId) {
                        gifts[index] = updatedGift
                    }
                })
            }
            if (updatedGiftIds.length > 0) {
                this.setState({
                    gifts: gifts,
                    isLoading: false,
                    loadingMessage: '',
                    loadingGoogleSheetText: `${updatedGiftIds.join(
                        ', '
                    )} were updated!`,
                    selectedGifts: [],
                })
            } else {
                this.setState({
                    isLoading: false,
                    loadingMessage: '',
                    loadingGoogleSheetText: 'Something went wrong!',
                })
            }
        } catch (err) {
            handleError('Error uploading data from Google', err)
            this.setState({
                isLoading: false,
                loadingMessage: '',
                loadingGoogleSheetText: 'Something went wrong!',
            })
        }
    }

    render() {
        if (this.state.isLoading)
            return <Loading text={this.state.loadingMessage} />

        const {
            gifts,
            loadingGoogleSheetText,
            searchString,
            selectedGifts,
            show,
            isSortAscend,
            sortBy,
            isBulkModalShown,
        } = this.state

        const filtered = this.filter(gifts)
        const filteredSorted = this.sort(filtered)

        return (
            <Section>
                <div
                    id="controlsPanel"
                    style={{ top: 20, right: 20, position: 'absolute' }}
                >
                    <input
                        autoFocus
                        className="editInput"
                        type="text"
                        name="searchString"
                        value={searchString}
                        placeholder="Search..."
                        onChange={this.onFilterChange}
                    />
                </div>

                <div style={{ display: 'inline-block' }}>
                    <h1>Gifts</h1>
                    <button
                        className="new withHeading"
                        onClick={() => this.props.navigate(RouteTo.GiftNew())}
                    >
                        Add new
                    </button>
                </div>

                {isBulkModalShown && (
                    <ModalV2>
                        <Bulk
                            onClose={async () => {
                                await this.fetchGifts()
                                this.setState({
                                    isBulkModalShown: false,
                                })
                            }}
                        />
                    </ModalV2>
                )}
                <NewRow />

                <div className="part" onChange={this.onFilterChange}>
                    <label>
                        <input
                            type="radio"
                            name="show"
                            checked={show === 'all'}
                            value="all"
                        />
                        All
                    </label>
                    <label>
                        <input
                            type="radio"
                            name="show"
                            checked={show === 'active'}
                            value="active"
                        />
                        Active
                    </label>
                    <label>
                        <input
                            type="radio"
                            name="show"
                            checked={show === 'inactive'}
                            value="inactive"
                        />
                        Inactive
                    </label>
                </div>
                <div className="part">
                    {loadingGoogleSheetText === '' ? (
                        <>
                            <Tooltip
                                delayShow={300}
                                place="bottom"
                                id="update_button"
                            />
                            <button
                                onClick={() =>
                                    this.updateGiftsFromGoogleSheet()
                                }
                                data-tooltip-content="'World Gifts Overview 2022' sheet only"
                                data-tooltip-id="update_button"
                            >
                                Update selected gifts from Google Sheet
                            </button>
                        </>
                    ) : (
                        <SpeechBubble
                            style={{ display: 'inline-flex', height: '42px' }}
                        >
                            {loadingGoogleSheetText}
                        </SpeechBubble>
                    )}
                    <button
                        style={{ marginLeft: '8px' }}
                        onClick={() =>
                            this.setState({ isBulkModalShown: true })
                        }
                    >
                        Sync with Google Sheets
                    </button>
                </div>
                <div
                    className="part"
                    style={{
                        display: 'inline-block',
                        color: '#9e9e9e',
                        fontSize: '0.9em',
                        marginBottom: '10px',
                    }}
                >
                    <label
                        style={{
                            margin: '0',
                            display: 'block',
                            float: 'left',
                        }}
                    >
                        <img
                            alt="Cache issue"
                            style={{ height: 20, display: 'inline-block' }}
                            src={warningImg}
                        ></img>
                    </label>
                    <label
                        style={{
                            display: 'block',
                            float: 'right',
                            marginTop: '3px',
                            marginLeft: '10px',
                        }}
                    >
                        List view may not show latest information due to cache.
                        Click on the individual Gift entry to see the most
                        current information.
                    </label>
                </div>
                <table style={{ width: '100%' }}>
                    <thead>
                        <tr>
                            <th style={{ width: '40px' }}>
                                <div
                                    onClick={() => this.toggleSelectAll()}
                                    style={{
                                        marginBottom: 5,
                                        cursor: 'pointer',
                                    }}
                                >
                                    <img
                                        alt="check"
                                        style={{
                                            width: 20,
                                            marginBottom: '-5px',
                                            marginRight: '5px',
                                        }}
                                        src={
                                            this.isAllSelected()
                                                ? checkedImg
                                                : unCheckedImg
                                        }
                                    ></img>
                                </div>
                            </th>
                            <th>
                                <div
                                    className="sortable"
                                    onClick={() => this.onClickSort('giftId')}
                                >
                                    <label
                                        htmlFor="name"
                                        style={{ marginRight: 0 }}
                                    >
                                        Name
                                    </label>
                                    <SortIcon
                                        isActive={sortBy === 'giftId'}
                                        isSortAscend={isSortAscend}
                                    />
                                </div>
                            </th>
                            <th>
                                <div
                                    className="sortable"
                                    style={{ justifyContent: 'flex-end' }}
                                    onClick={() =>
                                        this.onClickSort('startDate')
                                    }
                                >
                                    <label
                                        htmlFor="start"
                                        style={{ marginRight: 0 }}
                                    >
                                        Start
                                    </label>
                                    <SortIcon
                                        isActive={sortBy === 'startDate'}
                                        isSortAscend={isSortAscend}
                                    />
                                </div>
                            </th>
                            <th style={{ textAlign: 'right' }}>
                                <div
                                    className="sortable"
                                    style={{ justifyContent: 'flex-end' }}
                                    onClick={() => this.onClickSort('endDate')}
                                >
                                    <label
                                        htmlFor="end"
                                        style={{ marginRight: 0 }}
                                    >
                                        End
                                    </label>
                                    <SortIcon
                                        isActive={sortBy === 'endDate'}
                                        isSortAscend={isSortAscend}
                                    />
                                </div>
                            </th>
                            <th style={{ textAlign: 'right' }}>
                                <div
                                    className="sortable"
                                    style={{ justifyContent: 'flex-end' }}
                                    onClick={() =>
                                        this.onClickSort('releaseNumber')
                                    }
                                >
                                    <label
                                        htmlFor="release"
                                        style={{ marginRight: 0 }}
                                    >
                                        In which release?
                                    </label>
                                    <SortIcon
                                        isActive={sortBy === 'releaseNumber'}
                                        isSortAscend={isSortAscend}
                                    />
                                </div>
                            </th>
                            <th style={{ textAlign: 'right' }}>
                                <label
                                    htmlFor="copy"
                                    style={{ marginRight: 0 }}
                                ></label>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredSorted.map((g, i) => {
                            let isChecked = false
                            isChecked = _.find(selectedGifts, g) !== undefined

                            return (
                                <tr key={'tr' + i}>
                                    <td style={{ width: '40px' }}>
                                        <div
                                            onClick={() =>
                                                this.toggleCheckbox(g)
                                            }
                                            style={{
                                                marginBottom: 5,
                                                cursor: 'pointer',
                                            }}
                                        >
                                            <img
                                                alt="check"
                                                style={{
                                                    width: 20,
                                                    marginBottom: '-5px',
                                                    marginRight: '5px',
                                                }}
                                                src={
                                                    isChecked
                                                        ? checkedImg
                                                        : unCheckedImg
                                                }
                                            ></img>
                                        </div>
                                    </td>
                                    <td
                                        style={{ textAlign: 'left' }}
                                        onClick={() => {
                                            this.props.navigate(
                                                RouteTo.GiftEdit(g.giftId)
                                            )
                                        }}
                                    >
                                        {g.giftId}
                                    </td>
                                    <td style={{ textAlign: 'right' }}>
                                        {formatDate(g.startDate)}
                                    </td>
                                    <td style={{ textAlign: 'right' }}>
                                        {formatDate(g.endDate)}
                                    </td>
                                    <td style={{ textAlign: 'right' }}>
                                        {g.releaseNumber}
                                    </td>
                                    <td style={{ textAlign: 'right' }}>
                                        <button
                                            style={{ margin: '0 3px' }}
                                            className="small"
                                            onClick={(e) =>
                                                this.onCopyGiftClick(e, g)
                                            }
                                        >
                                            Copy to...
                                        </button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </Section>
        )
    }
}

export default withRouter(Gifts)
