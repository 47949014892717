export enum LanguageType {
    English = 'en',
    Arabic = 'ar',
    Chinese = 'zh',
    ChineseTraditional = 'zh-Hant',
    Danish = 'da',
    Dutch = 'nl',
    Finnish = 'fi',
    French = 'fr',
    FrenchCanada = 'fr-CA',
    German = 'de',
    Indonesian = 'id',
    Italian = 'it',
    Japanese = 'ja',
    Korean = 'ko',
    Norwegian = 'nb',
    Polish = 'pl',
    PortugueseBrazil = 'pt-BR',
    Portuguese = 'pt',
    Russian = 'ru',
    SpanishMexico = 'es-MX',
    Spanish = 'es',
    Swedish = 'sv',
    Thai = 'th',
    Turkish = 'tr',
}

export type LanguageTypeUnion = `${LanguageType}`
