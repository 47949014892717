/**
 * Product update statuses for the Price Update Automation tool
 * The ORDER ACTUALLY MATTERS!
 */
export enum ProductUpdateStatus {
    Error,
    NotStarted,
    Scheduled,
    Updating,
    Updated,
    InvalidPrice,
}
