import { useState } from 'react'
import _ from 'lodash'

import { Loading, MediaImage, Section, SpeechBubble } from '../shared'

import { ImageFileUploading } from '../utils/upload'
import { getPackDisplayName } from '../utils/utils'

import { ImageIconType } from '../types/enums/imageicontype.enum'
import { MediaType } from '../types/enums/mediatype.enum'

import { Media } from '../types/mediaTypes'
import type { Pack } from '../types/packTypes'

type ChildPacksMediaProps = {
    childPacksData: Pack[]
    parentScreenshots: (Media | (ImageFileUploading & { id?: number }))[]
    isLoading: boolean
    toggleSelectedMedia: (mediaId: number) => void
}

function ChildPacksMedia(props: ChildPacksMediaProps) {
    const { parentScreenshots, toggleSelectedMedia } = props
    const [openSections, setOpenSections] = useState<string[]>([])

    const toggleSection = (sectionId: string) => {
        const data = _.cloneDeep(openSections)
        const sectionIndex = data.indexOf(sectionId)
        if (sectionIndex > -1) {
            _.pull(data, sectionId)
        } else {
            data.push(sectionId)
        }
        setOpenSections(data)
    }

    if (props.isLoading) return <Loading />

    return (
        <>
            <SpeechBubble
                style={{ justifyContent: 'left', margin: '0 0 20px 0' }}
            >
                Media from selected packs will appear here. To edit or remove
                media from a pack, visit the pack directly.
                <br />
                Upload bundle specific media or rearrange the order of the
                selected pack's media in the section "Arrange & Upload bundle
                media" below.
            </SpeechBubble>
            {props.childPacksData.map((pack) => {
                const packSelectedMedias = pack.medias.filter((media) =>
                    _.some(parentScreenshots, media)
                )
                const packSectionTitle =
                    getPackDisplayName(pack) +
                    (packSelectedMedias.length > 0
                        ? ' (' + packSelectedMedias.length + ' selected)'
                        : '')
                return (
                    <Section
                        key={pack.packId}
                        id={pack.packId}
                        title={packSectionTitle}
                        openSections={openSections}
                        toggleSection={toggleSection}
                        className="accordion"
                    >
                        {pack.medias.length === 0 && (
                            <div>
                                Pack doesn't have any screenshots uploaded
                            </div>
                        )}
                        {pack.medias.length > 0 && (
                            <div>
                                {pack.medias
                                    .filter(
                                        (media) =>
                                            media.type === MediaType.SCREENSHOT
                                    )
                                    .map((media) => (
                                        <MediaImage
                                            key={media.id}
                                            media={media}
                                            onClick={toggleSelectedMedia}
                                            buttonType={
                                                _.some(parentScreenshots, media)
                                                    ? ImageIconType.CHECK
                                                    : ImageIconType.PLUS
                                            }
                                        />
                                    ))}
                            </div>
                        )}
                    </Section>
                )
            })}
        </>
    )
}

export default ChildPacksMedia
