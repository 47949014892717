const List = ({ heading, items, done, okLabel }) => {
    return (
        <div>
            <h1>{heading || 'Data'}</h1>
            {items.map((item, i) =>
                item.url ? (
                    <p key={i}>
                        {item.list ? '•' : ''}{' '}
                        <a
                            href={item.url}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {item.name}
                        </a>
                    </p>
                ) : (
                    <p key={i}>
                        {item.list ? '• ' : ' '}
                        {item.name}
                    </p>
                )
            )}
            <div style={{ textAlign: 'right', marginTop: 30 }}>
                <button
                    className="primary"
                    style={{ marginLeft: 10 }}
                    onClick={() => done()}
                >
                    {okLabel || 'OK'}
                </button>
            </div>
        </div>
    )
}

export default List
