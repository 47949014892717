import { useState } from 'react'
import { Tooltip } from 'react-tooltip'

import { NewRow } from '../shared'

import deleteImg from '../assets/delete.png'

type PackExtraContentProps = {
    content: string[]
    addContent: (values: string) => void
    deleteContent: (index: number) => Promise<void>
}

const PackExtraContent = (props: PackExtraContentProps) => {
    const [value, setValue] = useState('')
    const { content, deleteContent } = props

    function addContent() {
        if (value.length === 0) return
        props.addContent(value)
        setValue('')
    }
    return (
        <>
            <h2 style={{ marginTop: 30 }}>Add content</h2>
            {content.map((p, i) => {
                return (
                    <div key={'content' + i} className="extraContent">
                        <img
                            alt="delete"
                            onClick={() => {
                                deleteContent(i)
                            }}
                            src={deleteImg}
                        ></img>
                        {p}
                    </div>
                )
            })}

            <NewRow />
            <input
                data-tooltip-content="Used only for creator tools or extra game features."
                data-tooltip-id="content"
                className="labelInput"
                placeholder="Content id"
                value={value}
                onChange={(e) => {
                    setValue(e.target.value)
                }}
                onKeyPress={(e) => e.key === 'Enter' && addContent()}
            />
            <Tooltip id="content" />
            <button style={{ marginLeft: 10 }} onClick={addContent}>
                Add
            </button>
        </>
    )
}

export default PackExtraContent
