import { ChangeEvent } from 'react'

import PackExtraContent from '../PackExtraContent.tsx'
import PackScenes from '../PackScenes.tsx'
import { LabelInput, Modal, NewRow } from '../../shared'

import { PackType } from '../../types/enums/packtype.enum.ts'

import { Pack } from '../../types/packTypes'

export enum PossibleContentFields {
    Templates = 'templates',
    Furniture = 'furniture',
    SurfaceDesigns = 'surfaceDesigns',
    Locations = 'locations',
    Characters = 'characters',
    Garments = 'garments',
    Patterns = 'patterns',
    Stickers = 'stickers',
    Outfits = 'outfits',
    Accessories = 'accessories',
    HairStyles = 'hairStyles',
}

type PackContentProps = {
    pack: Pack
    onInputChange: (e: ChangeEvent<HTMLInputElement>) => void
    onContentChange: (content: Pack['content']) => void
    onScenesChange: (content: Pack['scenes']) => void
}

export function PackContent(props: PackContentProps) {
    const { pack, onInputChange, onContentChange, onScenesChange } = props

    const addScene = (newScene: string) => {
        onScenesChange(pack.scenes.concat([newScene]))
    }

    const addContent = (newContent: string) => {
        onContentChange(pack.content.concat([newContent]))
    }

    const deleteScene = async (i: number) => {
        const isConfirmed = await Modal.confirm({
            heading: 'Delete scene',
            text: 'Really delete this scene?',
            okLabel: 'Yes do it!',
        })
        if (!isConfirmed) return

        const scenes = Array.from(pack.scenes)

        scenes.splice(i, 1)

        onScenesChange(scenes)
    }

    const deleteContent = async (i: number) => {
        const isConfirmed = await Modal.confirm({
            heading: 'Delete content',
            text: 'Really delete this content?',
            okLabel: 'Yes do it!',
        })
        if (!isConfirmed) return

        const content = Array.from(pack.content)

        content.splice(i, 1)

        onContentChange(content)
    }

    return (
        <>
            <div
                className="packs part"
                style={{
                    display:
                        pack.type === PackType.HOME_DESIGNER ? 'block' : 'none',
                }}
            >
                <div className="packType part">
                    <h2>What's in this home designer pack?</h2>
                    <LabelInput
                        size="tiny"
                        label="Templates"
                        labelClassName="alignRight"
                        icon="template"
                        name={PossibleContentFields.Templates}
                        value={pack.templates}
                        type="number"
                        onChange={onInputChange}
                    />
                    <LabelInput
                        size="tiny"
                        label="Furniture"
                        labelClassName="alignRight"
                        icon="furniture"
                        name={PossibleContentFields.Furniture}
                        value={pack.furniture}
                        type="number"
                        onChange={onInputChange}
                    />
                    <LabelInput
                        size="tiny"
                        label="Surface designs"
                        labelClassName="alignRight"
                        icon="surface"
                        name={PossibleContentFields.SurfaceDesigns}
                        value={pack.surfaceDesigns}
                        type="number"
                        onChange={onInputChange}
                    />
                    <NewRow />
                    <PackScenes
                        scenes={pack.scenes}
                        addScene={addScene}
                        deleteScene={deleteScene}
                    />
                    <PackExtraContent
                        content={pack.content}
                        addContent={addContent}
                        deleteContent={deleteContent}
                    />
                </div>
            </div>

            <div
                className="packs part"
                style={{
                    display: pack.type === PackType.PLAYSET ? 'block' : 'none',
                }}
            >
                <div className="packType part">
                    <h2>How many buildings and characters live here?</h2>
                    <LabelInput
                        size="tiny"
                        label="buildings"
                        labelClassName="alignRight"
                        icon="building"
                        name={PossibleContentFields.Locations}
                        value={pack.locations}
                        type="number"
                        onChange={onInputChange}
                    />
                    <LabelInput
                        size="tiny"
                        label="characters"
                        labelClassName="alignRight"
                        icon="character"
                        name={PossibleContentFields.Characters}
                        value={pack.characters}
                        type="number"
                        onChange={onInputChange}
                    />
                </div>
                <PackScenes
                    scenes={pack.scenes}
                    addScene={addScene}
                    deleteScene={deleteScene}
                />
                <PackExtraContent
                    content={pack.content}
                    addContent={addContent}
                    deleteContent={deleteContent}
                />
            </div>

            <div
                className="packs part"
                style={{
                    display:
                        pack.type === PackType.OUTFIT_MAKER ? 'block' : 'none',
                }}
            >
                <div className="packType part">
                    <h2>What's in this outfit maker pack?</h2>
                    <LabelInput
                        size="tiny"
                        label="Garments"
                        labelClassName="alignRight"
                        icon="garment"
                        name={PossibleContentFields.Garments}
                        value={pack.garments}
                        type="number"
                        onChange={onInputChange}
                    />
                    <LabelInput
                        size="tiny"
                        label="Patterns"
                        labelClassName="alignRight"
                        icon="pattern"
                        name={PossibleContentFields.Patterns}
                        value={pack.patterns}
                        type="number"
                        onChange={onInputChange}
                    />
                    <LabelInput
                        size="tiny"
                        label="Stickers"
                        labelClassName="alignRight"
                        icon="sticker"
                        name={PossibleContentFields.Stickers}
                        value={pack.stickers}
                        type="number"
                        onChange={onInputChange}
                    />
                    <NewRow />
                    <PackExtraContent
                        content={pack.content}
                        addContent={addContent}
                        deleteContent={deleteContent}
                    />
                </div>
            </div>
            <div
                className="packs part"
                style={{
                    display:
                        pack.type === PackType.CHARACTER_CREATOR
                            ? 'block'
                            : 'none',
                }}
            >
                <div className="packType part">
                    <h2>What's in this character creator pack?</h2>
                    <LabelInput
                        size="tiny"
                        label="Outfits"
                        labelClassName="alignRight"
                        icon="outfit"
                        name={PossibleContentFields.Outfits}
                        value={pack.outfits}
                        type="number"
                        onChange={onInputChange}
                    />
                    <LabelInput
                        size="tiny"
                        label="Accessories"
                        labelClassName="alignRight"
                        icon="accessory"
                        name={PossibleContentFields.Accessories}
                        value={pack.accessories}
                        type="number"
                        onChange={onInputChange}
                    />
                    <LabelInput
                        size="tiny"
                        label="Hairstyles + Facial details"
                        labelClassName="alignRight"
                        icon="hair"
                        name={PossibleContentFields.HairStyles}
                        value={pack.hairStyles}
                        type="number"
                        onChange={onInputChange}
                    />
                    <NewRow />
                    <PackExtraContent
                        content={pack.content}
                        addContent={addContent}
                        deleteContent={deleteContent}
                    />
                </div>
            </div>
        </>
    )
}
