import { DraggableCore } from 'react-draggable'
import classNames from 'classnames'

import { mapPricetagSize } from '../settings'

import pricetagImg from '../assets/pricetag.png'

function Pricetag(props) {
    let { x, y, pricetag } = props.data
    const { i, isActive, setActive, drawScale, width, height } = props

    function onDrag(e, { deltaX, deltaY }) {
        let data = props.data
        data.pricetag.x += deltaX / drawScale / width
        data.pricetag.y += deltaY / drawScale / height
        props.updatePlayset(data, props.i, 'pricetag')
    }

    let style = {
        //outlineWidth: 5 / scale,
        transform: 'rotate(' + pricetag.rotation + 'deg)',
    }

    style.left = (x + pricetag.x) * width
    style.top = (y + pricetag.y) * height

    style.width = mapPricetagSize

    return (
        <DraggableCore key={'pricetag' + i} onDrag={onDrag}>
            <img
                draggable="false"
                src={pricetagImg}
                className={classNames('pricetag', isActive ? 'active' : '')}
                onClick={(e) => setActive(e, i, 'pricetag')}
                style={style}
                alt=""
            />
        </DraggableCore>
    )
}

export default Pricetag
