import { createRoot } from 'react-dom/client'

import Alert from './Alert'
import ColorPicker from './ColorPicker'
import Compare from './Compare'
import Confirm from './Confirm'
import List from './List'
import Prompt from './Prompt'
import Select from './Select'
import SignIn from './SignIn'

function Modal(props) {
    if (!props.show) {
        return null
    }

    const type = props.type

    return (
        <div className="modalBackdrop backdrop">
            <div className="modal">
                {type === 'confirm' && <Confirm {...props} />}
                {type === 'prompt' && <Prompt {...props} />}
                {type === 'select' && <Select {...props} />}
                {type === 'alert' && <Alert {...props} />}
                {type === 'compare' && <Compare {...props} />}
                {type === 'signin' && <SignIn {...props} />}
                {type === 'colorpicker' && <ColorPicker {...props} />}
                {type === 'list' && <List {...props} />}
            </div>
        </div>
    )
}

function createElement(properties) {
    let container = document.getElementById('custom-modal')
    if (!container) {
        container = document.createElement('div')
        container.id = 'custom-modal'
        document.body.appendChild(container)
    }
    const root = createRoot(container)
    root.render(<Modal show={true} {...properties} />)

    let elements = document.getElementsByClassName('blurrable')
    for (let e of elements) {
        e.classList.add('blur')
    }

    return root
}

function removeElement(root) {
    root.unmount()
    const target = document.getElementById('custom-modal')
    target.parentNode.removeChild(target)
    let elements = document.getElementsByClassName('blurrable')
    for (let e of elements) {
        e.classList.remove('blur')
    }
}

function modal(properties = {}) {
    return new Promise((resolve) => {
        let root
        properties.done = (val) => {
            removeElement(root)
            resolve(val || true)
        }
        properties.cancel = () => {
            removeElement(root)
            resolve(false)
        }
        root = createElement(properties)
    })
}

function confirm(properties = {}) {
    properties.type = 'confirm'
    return modal(properties)
}

function prompt(properties = {}) {
    properties.type = 'prompt'
    return modal(properties)
}

function select(properties = {}) {
    properties.type = 'select'
    return modal(properties)
}

function alert(properties = {}) {
    properties.type = 'alert'
    return modal(properties)
}

function compare(properties = {}) {
    properties.type = 'compare'
    return modal(properties)
}

function signin(properties = {}) {
    properties.type = 'signin'
    return modal(properties)
}

function colorpicker(properties = {}) {
    properties.type = 'colorpicker'
    return modal(properties)
}

function list(properties = {}) {
    properties.type = 'list'
    return modal(properties)
}

export default {
    confirm,
    prompt,
    select,
    alert,
    compare,
    signin,
    colorpicker,
    list,
}
