import { MouseEvent, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import axios from 'axios'

import Accounts from '../accounts/Accounts'
import Carousel from '../carousels/Carousel'
import Carousels from '../carousels/Carousels'
import Gift from '../gifts/Gift'
import Gifts from '../gifts/Gifts'
import { ApplePriceAutomationPage } from '../iapPriceAutomation/ApplePriceAutomationPage'
import { ExportActiveSKUsPage } from '../iapPriceAutomation/ExportActiveSKUsPage.tsx'
import { GooglePriceAutomationPage } from '../iapPriceAutomation/GooglePriceAutomationPage'
import InAppEvent from '../inappevents/InAppEvent'
import InAppEvents from '../inappevents/InAppEvents'
import Map from '../maps/Map'
import Maps from '../maps/Maps'
import Medias from '../media/Medias.tsx'
import Pack from '../packs/Pack'
import Packs from '../packs/Packs'
import LanguageSelector from '../shared/LanguageSelector'
import Loading from '../shared/Loading'
import ShopTab from '../shopTabs/ShopTab/ShopTab'
import ShopTabs from '../shopTabs/ShopTabs'
import Tools from '../tools/Tools'
import { User } from '../user/User'
import Versions from '../versions/Versions'
import { FallbackPage } from './FallbackPage.tsx'
import Login from './Login/Login'
import Nav, { getTabByRoute, NavigationTab } from './Nav/Nav'

import { Features, isFeatureEnabled } from '../utils/features.ts'
import { getUserProfileData } from '../utils/getUserProfileData.ts'
import { redirectToLogin } from '../utils/redirectToLogin.ts'

import { getRouteByUrl, RoutePath } from '../routes.ts'

import { LanguageType } from '../types/enums/languagetype.enum.ts'

import type { UserProfilePublic } from '../user/userTypes'

function App() {
    const [isLoading, setIsLoading] = useState(true)
    const [isInAppEventsEnabled, setIsInAppEventsEnabled] = useState(false)
    const [tab, setTab] = useState<NavigationTab | undefined>(undefined)
    const [openSections, setOpenSections] = useState<string[]>(['packOverview'])
    const storageLang = localStorage.getItem('language') as LanguageType
    const [language, setLanguage] = useState<LanguageType>(
        storageLang ?? LanguageType.English
    )
    const [user, setUser] = useState<UserProfilePublic | null>(null)
    const isAuthenticated = Boolean(user)

    async function fetchInAppEventsFeature() {
        const isInAppEventsEnabled = await isFeatureEnabled(
            Features.in_app_events
        )
        setIsInAppEventsEnabled(isInAppEventsEnabled)
    }
    async function fetchUserData() {
        const user = await getUserProfileData()
        setUser(user)
    }

    useEffect(() => {
        fetchUserData()
        fetchInAppEventsFeature()
        setIsLoading(false)

        localStorage.setItem('hasUnsavedChanges', String(false))
    }, [])

    const signout = async (e: MouseEvent<HTMLElement>) => {
        if (!onCheckUnsaved(e)) return

        await axios.get('/auth/logout')

        redirectToLogin()
    }

    const toggleSection = (name: string) => {
        // todo It should be context
        if (openSections.includes(name)) {
            setOpenSections(openSections.filter((section) => section !== name))
        } else {
            setOpenSections([...openSections, name])
        }
    }

    const onCheckUnsaved = (e: MouseEvent<HTMLElement>) => {
        const hasUnsaved = localStorage.getItem('hasUnsavedChanges') === 'true'
        if (!hasUnsaved) return true
        if (!window.confirm('Really leave? You have unsaved changes.')) {
            e.preventDefault()
            return false
        }
        return true
    }

    const onTabChange = (
        e: MouseEvent<HTMLAnchorElement>,
        tab: NavigationTab
    ) => {
        if (onCheckUnsaved(e)) {
            setTab(tab)
            localStorage.setItem('hasUnsavedChanges', String(false))
        }
    }

    const getActiveTab = () => {
        if (tab) {
            return tab
        }

        const activeRouteName = getRouteByUrl(window.location.pathname)

        if (activeRouteName) {
            return getTabByRoute(activeRouteName)
        }

        return undefined
    }

    const onLanguageChange = (e: MouseEvent<HTMLSelectElement>) => {
        const language = (e.target as HTMLSelectElement).value as LanguageType

        setLanguage(language)
    }

    if (isLoading) return <Loading />

    if (!isAuthenticated) return <Login />

    const allowedOrigins = [
        'http://localhost:*',
        'https://lifeserver-admin.tocaboca.com',
        'https://lifeserver-admin-staging.tocaboca.com',
        'https://lifeserver-admin-dev-growth.tocaboca.com',
        'https://lifeserver-admin-dev-id.tocaboca.com',
        'https://lifeserver-admin-dev-os.tocaboca.com',
        'https://s3.amazonaws.com',
        'https://s3-eu-west-1.amazonaws.com',
    ]

    const contentSecurityPolicy = `connect-src ${allowedOrigins.join(' ')}`

    return (
        <>
            <Helmet>
                <meta
                    httpEquiv="Content-Security-Policy"
                    content={contentSecurityPolicy}
                />
            </Helmet>
            <Router>
                <div data-env="local">
                    <Nav
                        tab={getActiveTab()}
                        isInAppEventsEnabled={isInAppEventsEnabled}
                        onTabChange={onTabChange}
                    >
                        <LanguageSelector
                            onChange={onLanguageChange}
                            language={language}
                            value={language}
                        />

                        {user && <User user={user} signout={signout} />}
                    </Nav>
                    <div className="blurrable">
                        <Routes>
                            <Route
                                path={RoutePath.Packs}
                                element={<Packs language={language} />}
                            />
                            <Route
                                path={RoutePath.PackEdit}
                                element={
                                    <Pack
                                        language={language}
                                        openSections={openSections}
                                        toggleSection={toggleSection}
                                    />
                                }
                            />
                            <Route path={RoutePath.Gifts} element={<Gifts />} />
                            <Route
                                path={RoutePath.GiftEdit}
                                element={<Gift />}
                            />
                            <Route path={RoutePath.Maps} element={<Maps />} />
                            <Route
                                path={RoutePath.MapEdit}
                                element={<Map language={language} />}
                            />
                            <Route
                                path={RoutePath.GiftNew}
                                element={<Gift />}
                            />
                            <Route
                                path={RoutePath.PackNew}
                                element={
                                    <Pack
                                        language={language}
                                        openSections={openSections}
                                        toggleSection={toggleSection}
                                    />
                                }
                            />
                            <Route
                                path={RoutePath.Carousels}
                                element={<Carousels />}
                            />
                            <Route
                                path={RoutePath.Tabs}
                                element={<ShopTabs language={language} />}
                            />
                            <Route
                                path={RoutePath.TabNew}
                                element={
                                    <ShopTab
                                        language={language}
                                        openSections={openSections}
                                        toggleSection={toggleSection}
                                    />
                                }
                            />
                            <Route
                                path={RoutePath.TabEdit}
                                element={
                                    <ShopTab
                                        language={language}
                                        openSections={openSections}
                                        toggleSection={toggleSection}
                                    />
                                }
                            />
                            <Route
                                path={RoutePath.Accounts}
                                element={<Accounts />}
                            />
                            <Route
                                path={RoutePath.Versions}
                                element={<Versions />}
                            />
                            <Route
                                path={RoutePath.CarouselEdit}
                                element={
                                    <Carousel
                                        language={language}
                                        openSections={openSections}
                                        toggleSection={toggleSection}
                                    />
                                }
                            />
                            <Route
                                path={RoutePath.CarouselNew}
                                element={
                                    <Carousel
                                        language={language}
                                        openSections={openSections}
                                        toggleSection={toggleSection}
                                    />
                                }
                            />
                            <Route
                                path={RoutePath.Medias}
                                element={<Medias />}
                            />
                            <Route
                                path={RoutePath.PriceAutomationApple}
                                element={<ApplePriceAutomationPage />}
                            />
                            <Route
                                path={RoutePath.PriceAutomationGoogle}
                                element={<GooglePriceAutomationPage />}
                            />
                            <Route
                                path={RoutePath.ExportSKUs}
                                element={<ExportActiveSKUsPage />}
                            />
                            <Route path={RoutePath.Tools} element={<Tools />} />
                            {isInAppEventsEnabled && (
                                <>
                                    <Route
                                        path={RoutePath.InAppEvents}
                                        element={
                                            <InAppEvents language={language} />
                                        }
                                    />
                                    <Route
                                        path={RoutePath.InAppEventsEdit}
                                        element={
                                            <InAppEvent language={language} />
                                        }
                                    />
                                    <Route
                                        path={RoutePath.InAppEventsNew}
                                        element={
                                            <InAppEvent language={language} />
                                        }
                                    />
                                </>
                            )}
                            <Route path="*" element={<FallbackPage />} />
                        </Routes>
                    </div>
                </div>
            </Router>
        </>
    )
}

export default App
