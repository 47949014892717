import Section from '../shared/Section'

const Instructions = () => {
    return (
        <Section title="Key shortcuts" className="instructions">
            <div>
                <span role="img" aria-label="" style={{ fontSize: '1.3em' }}>
                    ⬆️ ➡️ ⬇️ ➡️{' '}
                </span>
                Fine tune position. Hold shift for{' '}
                <span
                    role="img"
                    style={{ fontSize: 18, minWidth: 0 }}
                    aria-label=""
                >
                    🧙✨
                </span>
            </div>
            <div>
                <span role="img" aria-label="" style={{ fontSize: '1.3em' }}>
                    ALT + ⬆️ ⬇️{' '}
                </span>
                Move layer up/down
            </div>
            <div>
                <span role="img" aria-label="" style={{ fontSize: '1.3em' }}>
                    1️⃣ ... 9️⃣
                </span>
                Adjust opacity for active playset (not saved).{' '}
                <span
                    role="img"
                    style={{ fontSize: 18, minWidth: 0 }}
                    aria-label=""
                >
                    0️⃣
                </span>{' '}
                to reset
            </div>
            <div>
                <span role="img" aria-label="" style={{ fontSize: 18 }}>
                    R
                </span>
                <span>Rotate price tag</span>
            </div>
            <div>
                <span role="img" aria-label="" style={{ fontSize: 18 }}>
                    Space
                </span>
                <span>Toggle playset/price tag</span>
            </div>
        </Section>
    )
}

export default Instructions
