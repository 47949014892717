const SortIcon = (props) => {
    const { isSortAscend, isActive } = props
    return (
        <div
            style={{
                color: isActive ? 'inherit' : '#eee',
                fontSize: '0.8em',
                marginLeft: '0.2em',
            }}
        >
            {isSortAscend ? '▲' : '▼'}
        </div>
    )
}

export default SortIcon
