import Section from '../shared/Section.tsx'
import PriceAutomation from './PriceAutomationPage/PriceAutomation.tsx'

import { PriceAutomationStore } from '../types/enums/store.enum.ts'

export const ApplePriceAutomationPage = () => {
    return (
        <Section title={'Apple Connect Price Automation'}>
            <PriceAutomation store={PriceAutomationStore.APPLE} />
        </Section>
    )
}
